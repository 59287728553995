import React, {useEffect} from "react";

/* MUI */
import { Grid } from "@mui/material";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Common Components */
import Wizard, {useWizard} from "@acromove/components/wizard";
import SoftcaseInspection from "components/content/acrotransfer/health-check-steps/softcase-inspection-form";
import OpenSoftcase from "components/content/acrotransfer/health-check-steps/open-softcase-form";
import DeviceInspection from "components/content/acrotransfer/health-check-steps/device-inspection-form";
import SecurityStickers from "components/content/acrotransfer/health-check-steps/security-stickers-form";
import LeftContent from "components/content/acrotransfer/health-check-steps/shared/left";

/* Utils */
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/* Assets */
import softcase from "assets/softcase.png"
import openSoftCase from "assets/opensoftcase.png"
import device from "assets/device.png";
import stickers from "assets/stickers.png";


const HealthCheckContainer = () => {
	const navigate = useNavigate();
	const { t } = useTranslation("acrotransfer_health_check");

	const wizard = useWizard({
		name: "healthCheckWizard",
		onFinish: () => {
			navigate(-1)
		},
		onSaveAsDraft: (data) => {
			localStorage.setItem(data.name, JSON.stringify(data))
			navigate(-1)
		},
		last: "security_stickers",
		initial: "softcase_inspection",
	})

	useEffect(() => {
		const draft = localStorage.getItem("healthCheckWizard");
		if(draft) {
			wizard.actions.loadWizard(JSON.parse(draft))
			localStorage.removeItem("healthCheckWizard")
		}
	}, [])

	return (
		<Container>
			<Container.Header back={-1} title={t("back")} />
			<Wizard wizard={wizard}>
			<Wizard.Step slug="softcase_inspection" label={t("steps.softcase_inspection.label")}  pos={0}>
					<Grid container>
						<Grid item md={4}>
						<LeftContent image={softcase} />
						</Grid>
						<Grid item md={4}>
							<Wizard.Bar>
								<SoftcaseInspection />
							</Wizard.Bar>
						</Grid>
					</Grid>
				</Wizard.Step>
				<Wizard.Step slug="open_softcase" label={t("steps.open_softcase.label")} pos={1}>
					<Grid container>
						<Grid item md={4}>
							<LeftContent image={openSoftCase} />
						</Grid>
						<Grid item md={4}>
							<Wizard.Bar>
								<OpenSoftcase />
							</Wizard.Bar>
						</Grid>
					</Grid>
				</Wizard.Step>
				<Wizard.Step slug="device_inspection" label={t("steps.device_inspection.label")} pos={2}>
					<Grid container>
						<Grid item md={4}>
							<LeftContent image={device} />
						</Grid>
						<Grid item md={4}>
							<Wizard.Bar>
								<DeviceInspection />
							</Wizard.Bar>
						</Grid>
					</Grid>
				</Wizard.Step>
				<Wizard.Step slug="security_stickers" label={t("steps.security_stickers.label")} pos={3}>
					<Grid container>
						<Grid item md={4}>
							<LeftContent image={stickers} />
						</Grid>
						<Grid item md={4}>
							<Wizard.Bar>
								<SecurityStickers />
							</Wizard.Bar>
						</Grid>
					</Grid>
				</Wizard.Step>
			</Wizard>
		</Container>
	);
};

export default HealthCheckContainer;
