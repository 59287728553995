import React, {useEffect} from "react";

/* MUI */
import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";

/* Components */
import Wizard, { useStep } from "@acromove/components/wizard";
import Input from "@acromove/components/form/input";
import LeftContent from "components/content/acrotransfer/wizard-steps/shared/left-icon";

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import serverImg from "assets/create_job.svg";

const WhatKindOfJob = () => {
	const wizard = useStep();
	const { t } = useTranslation("acrotransfer_create_job", { keyPrefix: "steps.what_kind_of_job" });

	const defValues = wizard.state.stepsData?.what_kind_of_job || {
		name: "",
		description: "",
	};

	const { control, handleSubmit, reset } = useForm({
		defaultValues: defValues,
	});

	const onSubmit = (data) => {
		wizard.actions.setStepData("what_kind_of_job", data);
	};

	useEffect(() => {
		reset(wizard.state.stepsData?.what_kind_of_job)
	}, [wizard.state.stepsData?.what_kind_of_job])

	const handleNext = () => {
		handleSubmit(onSubmit)();
		return true;
	};

	return (
		<Wizard.Step slug="what_kind_of_job" label={t("label")} pos={1}>
			<Grid container>
				<Grid item md={4}>
					<LeftContent image={serverImg}/>
				</Grid>
				<Grid item md={4}>
					<Wizard.Bar>
						<Box
							component="form"
							onSubmit={handleSubmit(onSubmit)}
							display="flex"
							flexDirection="column"
							sx={{
								gap: 4,
								width: "100%",
							}}
						>
							<Typography fontWeight={600} fontSize={20}>
								{t("desc")}
							</Typography>
							<Input
								sx={{ width: "100%" }}
								size="medium"
								control={control}
								name="name"
								label={t("form.job_name.label")}
								helperText={t("form.job_name.description")}
							/>
							<Input
								sx={{ width: "100%" }}
								control={control}
								name="description"
								type="textarea"
								label={t("form.job_description.label")}
								placeholder={t("form.job_description.placeholder")}
								helperText={t("form.job_description.description")}
							/>
						</Box>
						<Wizard.Tools beforeNext={handleNext} />
					</Wizard.Bar>
				</Grid>
			</Grid>
		</Wizard.Step>
	);
};

export default WhatKindOfJob;
