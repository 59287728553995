import React, {useEffect} from "react";

/* MUI */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

/* Components */
import Wizard, { useStep } from "@acromove/components/wizard";
import TextField from "@mui/material/TextField";
import LeftContent from "components/content/acrotransfer/wizard-steps/shared/left-calendar";

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CheckAvailability = () => {
	const wizard = useStep();
	const { t } = useTranslation("acrotransfer_create_job", { keyPrefix: "steps.check_availability" });

	const countDays = () => {
		let dataSize = wizard.state.stepsData?.data_volume?.data_size || 100;
		const { amount = "tb" } = wizard.state.stepsData?.data_volume || {};
		let days = 2;
		if (amount === "pb") {
			dataSize *= 10;
		}

		if (dataSize <= 200) days = 2;
		else days = Math.ceil(dataSize / 100);
		return days;
	}

	const { handleSubmit, reset } = useForm();

	const onSubmit = (data) => {
		wizard.actions.setStepData("check_availability", data);
	};

	useEffect(() => {
		reset(wizard.state.stepsData?.check_availability)
	}, [wizard.state.stepsData?.check_availability])

	const handleNext = () => {
		handleSubmit(onSubmit)();
		return true;
	};

	return (
		<Wizard.Step slug="check_availability" label={t("label")} pos={12}>
			<Grid container>
				<Grid item md={4}>
					<LeftContent />
				</Grid>
				<Grid item md={4}>
					<Wizard.Bar>
						<Box
							component="form"
							onSubmit={handleSubmit(onSubmit)}
							display="flex"
							flexDirection="column"
							sx={{
								gap: 4,
								width: "100%",
							}}
						>
							<Typography fontWeight={600} fontSize={20}>
								{t("check")}
							</Typography>
							<Typography fontWeight={500} color="text.secondary" fontSize={20}>
								{t("check_ext")}
							</Typography>
							<Grid item md={6}>
								<TextField
									disabled
									sx={{ width: "100%" }}
									type="number"
									size="medium"
									value={countDays()}
									name="number"
									label={t("form.number.label")}
								/>
							</Grid>
						</Box>
						<Wizard.Tools beforeNext={handleNext} />
					</Wizard.Bar>
				</Grid>
			</Grid>
		</Wizard.Step>
	);
};

export default CheckAvailability;
