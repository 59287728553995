import React, { useState } from "react";

/* Content Components */
import { jobsTemplates } from "components/table-columns/manager/templates";
import DataGridTable from "@acromove/components/data-grid";
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";
import Dialog from "@acromove/components/dialog";

/* Utils */
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import { useTranslation } from "react-i18next";

/* Redux */
import { useFindTemplatesQuery, useRemoveTemplateMutation } from "redux/jobs/jobs.api";

const TemplatesContainer = () => {
	const [open, setOpen] = useState(false);
	const [id, setId] = useState(null)
	const { t } = useTranslation("common");
	const itemsTranslator = useItemsTranslation("acrotransfer_jobs");
	const [removeTemplate, {isLoading}] = useRemoveTemplateMutation({id})

	const handleOpen = () => {
		setOpen(true)
		setId(id)
	}

	const handleClose = (isAgree) => {
		if(isAgree) {
			removeTemplate({id})
			setOpen(false)
		}
		else setOpen(false)
	}

	return (
		<>
		<Dialog
		loading={isLoading}
		maxWidth="sm"
		labelCancel={t("cancel")}
		labelOk={t("confirm")}
		fullWidth
		open={open}
		onClose={handleClose}
		title={t("template_remove")}
		>
			{t("template_remove_text")}
		</Dialog>
		<Container>
			<DataGridProvider useHook={useFindTemplatesQuery} initSort="name">
				<DataGridTable
					collapsible
					search
					title={t("titles.templates_list")}
					columns={itemsTranslator(jobsTemplates({setOpen: handleOpen, setId}), "template_table", "field", "headerName")}
				/>
			</DataGridProvider>
		</Container>
		</>
	);
};

export default TemplatesContainer;
