import React from "react";

/* MUI */
import { Typography, Box } from "@mui/material";

/* Components */
import TransferItem from "./generic/transfer-item";

/* Assets */
import device1 from "assets/device1.png";
import device2 from "assets/device2.png";
import soft from "assets/software.png";
import team from "assets/team.png";

/* Utils */
import { useTranslation } from "react-i18next";
import styles from "./styles"

const HomeAcroTransfer = () => {
	const { t } = useTranslation("home_page", { keyPrefix: "acrotransfer" });
	const items = [
		{
			title: t("sp35.title"),
			text: (
				<Box sx={{ display: "block" }}>
					<Typography sx={[styles.regularText, { color: "primary.main", width: "auto", display: "inline" }]} fontWeight={700}>
						{t("sp35.spec_text")}
					</Typography>
					<Typography sx={[styles.regularText, { display: "inline", ml: "4px" }]}>{t("sp35.text")}</Typography>
				</Box>
			),
			img: device1,
			order: "normal",
		},
		{
			title: t("set.title"),
			text: <Typography sx={[styles.regularText, { display: "inline", ml: "4px" }]}>{t("set.text")}</Typography>,
			img: device2,
			order: "reverse",
		},
		{
			title: t("soft.title"),
			text: (
				<Box sx={{ display: "block" }}>
					<Typography sx={[styles.regularText,{ display: "inline" }]}>{t("soft.text_start")}</Typography>
					<Typography sx={[styles.regularText, { color: "primary.main", width: "auto", display: "inline", mx: "4px" }]} fontWeight={700}>
						{t("soft.spec_text")}
					</Typography>
					<Typography sx={[styles.regularText, { display: "inline" }] }>{t("soft.text_end")}</Typography>
				</Box>
			),
			img: soft,
			order: "normal",
		},
		{
			title: t("team.title"),
			text: <Typography sx={[styles.regularText, { display: "inline", ml: "4px" }]}>{t("team.text")}</Typography>,
			img: team,
			order: "reverse",
		},
	];
	return (
		<Box
			sx={{
				width: "100%",
				py: "80px",
				px: "80px",
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
			}}
		>
			<Typography
				color="text.main"
				sx={styles.titles}
			>
				{t("title")}
			</Typography>
			<Box sx={{ display: "flex", flexDirection: "column" }}>
				{items.map((item) => (
					<TransferItem order={item.order} text={item.text} img={item.img} title={item.title} key={item.title} />
				))}
			</Box>
		</Box>
	);
};

export default HomeAcroTransfer;
