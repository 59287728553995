import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";

/* Common Components */
import Switcher from "../switcher";
import Chart from "../chart";

/* Utils */
import sh from "../../utils/styles-helpers";

/**
 * Information Statistic component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} different Information Statistic component deppending on type.
 */
const InfoStat = (props) => {
	const { value, label, type, icon, total, chartData, sublabel } = props;
	const isMultiply = Array.isArray(value);

	return (
		<Box
			sx={{
				width: "100%",
				p: 3,
				...sh.flex("row", "flex-start", "center"),
				gap: 1.5,
				border: "1px solid",
				borderColor: "background.gray",
				borderRadius: "8px",
				height: "100%",
			}}
		>
			<Box sx={{ ...sh.flex("column", "flex-start", "flex-start"), width: type !== "chart" ? "100%" : "50%" }}>
				<Typography align="left" color="text.secondary" fontWeight="500" fontSize={14} sx={{ opacity: 1 }}>
					{label}
				</Typography>
				{!isMultiply ? (
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<Typography align="left" color="text.active" variant="h4">
							{value}
						</Typography>
						{total && (
							<Typography align="left" color="text.active" variant="h4">
								/{total}
							</Typography>
						)}
					</Box>
				) : (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
							alignItems: "center",
							gap: "20px",
						}}
					>
						{value.map((v) => (
							<Box
								key={`icon-stat-item-${v.title}`}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "flex-end",
								}}
							>
								<Typography align="left" color="text.active" variant="h4">
									{v.title}
								</Typography>
								{v.subtitle && (
									<Typography
										sx={{ pl: "5px", pb: "2px" }}
										align="left"
										color="text.secondary"
										variant="p"
									>
										{v.subtitle}
									</Typography>
								)}
							</Box>
						))}
					</Box>
				)}
			</Box>

			<Box sx={{ ...sh.flex("column", "center", "center"), width: "100%" }}>
				<Switcher value={type}>
					<Switcher.Item name="chart">
						<Chart.Provider
							xAxisCategories={type === "chart" && chartData.xaxis.dataPoints}
							series={type === "chart" && chartData.series}
							boxSx={{ p: 0 }}
						>
							<Chart
								type="area"
								width="100%"
								height={70}
								options={{
									xaxis: {
										show: false,
										labels: {
											show: false,
										},
										axisBorder: {
											show: false,
										},
										axisTicks: {
											show: false,
										},
									},
									yaxis: {
										show: false,
										labels: {
											show: false,
										},
										axisBorder: {
											show: false,
										},
										axisTicks: {
											show: false,
										},
										crosshairs: {
											show: false,
										},
										tooltip: {
											enabled: false,
										},
									},
									fill: {
										gradient: {
											opacityFrom: 0,
											opacityTo: 0,
											shadeIntensity: 1,
											stops: [0, 100],
											type: "vertical",
										},
										type: "gradient",
									},
								}}
							/>
						</Chart.Provider>
					</Switcher.Item>

					<Switcher.Item name="switch">
						<Box sx={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-end" }}>
							<Switch color="success" checked />
							{sublabel && <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>{sublabel}</Typography>}
						</Box>
					</Switcher.Item>

					<Switcher.Item name="icon">
						<Box sx={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-end", mr: 4 }}>
							<img src={icon} alt={label} />
						</Box>
					</Switcher.Item>
				</Switcher>
			</Box>
		</Box>
	);
};

/**
 * InfoStat Properties.
 *
 * @typedef {object} Props
 * @property {"chart" | "switch" | "icon"} type - Type of Component. 
 * "chart" - used for displaying information by using chart, chartData property is required when used.
 * "switch" - used for displaying information with Switch, sublabel property is optional.
 * "icon" - used to define information with icon, icon and label properties are required.
 * @property {string | string[]} value - used to provide value / title&subtitle.
 * @property {string} value.title - title provided through value.
 * @property {string} value.subtitle - subtitle provided through value.
 * @property {string} icon - icon used only with "icon" type of component.
 * @property {string} [label = ""] - Information label.
 * @property {string} [sublabel = ""] - Information sublabel.
 * @property {object} [chartData = {}] - Used only with "chart" type of component. See {@link  https://apexcharts.com/docs/series/} for more information.
 * @property {object[]} chartData.series - one of ApexChart Options. See {@link  https://apexcharts.com/docs/series/} for more information.
 * @property {object} chartData.xaxis - one of ApexChart Options. See {@link  https://apexcharts.com/docs/series/} for more information.
 * @property {string[] | number[]} chartData.xaxis.dataPoints - one of ApexChart Options. See {@link  https://apexcharts.com/docs/series/} for more information.
 */

InfoStat.propTypes = {
	type: PropTypes.oneOf(["chart", "switch", "icon"]).isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		subtitle: PropTypes.string
	}))]),
	label: PropTypes.string,
	icon: PropTypes.string,
	total: PropTypes.string,
	sublabel: PropTypes.string,
	chartData: PropTypes.shape({
		series: PropTypes.arrayOf(PropTypes.shape({})),
		xaxis: PropTypes.shape({
			dataPoints: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
		}),
	}),
};

InfoStat.defaultProps = {
	value: "",
	label: "",
	icon: "",
	total: "",
	sublabel: "",
	chartData: {},
};

export default InfoStat;
