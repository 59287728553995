import React from "react";

/* Layout Components */
import ResetForm from "../../components/content/login/forgot_password/reset";

/* Redux */
import { useResetMutation } from "@acromove/redux/auth/api"
import { useSearchParams, useNavigate } from "react-router-dom";

const ResetContainer = () => {
    const [searchParams] = useSearchParams()
    const token = searchParams.get("token")
    const navigate = useNavigate()
    
	const [reset, {isSuccess}] = useResetMutation({resetToken: token});

	const onResetSubmit = async (values) => {
        const {password} = values
		await reset({password, resetToken: token});
        if(isSuccess){
            navigate("/")
        }
	};

	return <ResetForm submit={onResetSubmit}/>;
};

export default ResetContainer;
