import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import TemplateContainer from "containers/acrotransfer/manager/templates";

const AcroTransferTemplatePage = () => (
	<RoleSwitcher>
		<RoleSwitcher.Role value={["user", "user:view_only"]}>
			<TemplateContainer />
		</RoleSwitcher.Role>
	</RoleSwitcher>
);

export default AcroTransferTemplatePage;
