import React from "react";

/* MUI */
import { Button, Box, Toolbar } from "@mui/material";
import { useTheme } from "@mui/system";

/* Utils */
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeHeader = () => {
	const navigate = useNavigate();
    const theme = useTheme()
	const { t } = useTranslation("home_page", {keyPrefix: "header"});

	return (
		<Box position="fixed" sx={{ bgcolor: "sideMenu.background", width: "100%", zIndex: 1000 }}>
			<Toolbar sx={{ minHeight: { md: "52px" } , display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
				<Box sx={{cursor: "pointer"}} onClick={() => navigate("/")}>
					<img style={{ margin: "0 auto" }} height="36" src={theme.assets.logo} alt="logo" />
				</Box>
				<Box sx={{ gap: 1 }} display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
					<Button onClick={() => navigate("/login")}>{t("login")}</Button>
					<Button variant="contained" onClick={() => navigate("/sign_up")}>{t("sign_up")}</Button>
				</Box>
			</Toolbar>
		</Box>
	);
};

export default HomeHeader;
