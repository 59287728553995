import React from "react";
import PropTypes from "prop-types";

/* MUI */
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";

/* Components */
import HeaderCell from "./header-cell";

/**
 * Table Header component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Table Header.
 */
const Header = (props) => {
    const {
        headerGroups,
        sortItem,
        sortDer
    } = props;
	return (
		<TableHead sx={{ bgcolor: "transparent"}}>
			{headerGroups.map((headerGroup) => (
				<TableRow {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map((column) => (
                        <HeaderCell
                            key={`${column.id}_${column.Header}`} 
                            column={column} 
                            isActive={sortItem === column.id} 
                            deriction={sortDer}
                            />
                    ))}
				</TableRow>
			))}
		</TableHead>
	);
};

/**
 * Header Properties.
 *
 * @typedef {object} Props
 * @property {object[]} headerGroups.
 * @property {string} [sortItem = "id"] - Item that the table can be sorted by.
 * @property {"desc" | "asc"} [sortDer = "desc"] - Direction of sorting.
 */

Header.propTypes = {
    headerGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    sortItem: PropTypes.string.isRequired,
	sortDer: PropTypes.oneOf(["desc", "asc"]).isRequired,
}


export default Header;
