/* eslint-disable */
import React from "react";

/* Layout Components */
import InviteForm from "../../components/content/login/invite";

/* Utils */
import { useNavigate } from "react-router-dom";

/* Redux */
import { useRegisterMutation } from "@acromove/redux/auth/api"

const InviteContainer = () => {
    const navigate = useNavigate();
    const [register] = useRegisterMutation()
    
    const onRegisterSubmit = (values) => {
		register({ ...values});
        navigate("/login")
	};

	return (
        <InviteForm/>
	);
};

export default InviteContainer;