import React from "react";

/* Utils. */
import useAuth from "@acromove/authentication/hooks/use-auth";
import { Navigate } from "react-router-dom";
import HomeContainer from "containers/home";

const HomePage = () => {
	const auth = useAuth();
	return auth.isAuthenticated ? <Navigate to="/acrotransfer" /> : <HomeContainer />;
};
export default HomePage;
