import React from "react";

/* Content Components */
import DataTransferServices from "components/content/acrotransfer/data-transfer-services";
import { jobList } from "components/table-columns/admin/jobs";
import DataGridTable from "@acromove/components/data-grid";
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";
import Section from "components/layouts/dashboard/ui/section";

/* Assets */
import chip from "assets/icons/chip.png";
import database from "assets/icons/database.png";

/* Utils */
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import { useTranslation } from "react-i18next";

/* Redux */
import { useFindAllJobsQuery } from "redux/jobs/jobs.api";
import { useFindPartnerStatisticsQuery } from "redux/statistics/statistics.api";


const DashboardContainer = () => {
	const {t} = useTranslation("acrotransfer_admin")
	const itemsTranslator = useItemsTranslation("acrotransfer_jobs");

	const {data, isLoading} = useFindPartnerStatisticsQuery()
	
	const dataTransferItems = [
		{ label: t("data_transfer.devices"), stat: data?.devices?.used, icon: chip },
		{ label: t("data_transfer.yearly_jobs"), stat: data?.transfer_jobs?.yearly, icon: database },
		{ label: t("data_transfer.transfer_jobs"), stat: `${data?.transfer_jobs?.active}/${data?.transfer_jobs?.total}`, icon: database },
	];
	const totalTransferItems = [
		{ label: t("data_transfer.total"), stat: data?.data?.total, icon: database },
		{ label: t("data_transfer.yearly"), stat: data?.data?.yearly, icon: database },
		{ label: t("data_transfer.current"), stat: data?.data?.current, icon: database },
	];


	return (
		<Container loading={isLoading}>
			<Section title={t("data_transfer.title")} collapsible>
				<DataTransferServices items={dataTransferItems} />
				<DataTransferServices items={totalTransferItems} />
			</Section>

			<DataGridProvider useHook={useFindAllJobsQuery} initSort="name">
				<DataGridTable
					collapsible
					search
					title={t("job_list")}
					columns={itemsTranslator(jobList, "job_table", "field", "headerName")}
				/>
			</DataGridProvider>
		</Container>
	);
};

export default DashboardContainer;
