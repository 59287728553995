import React, {useState} from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import IconButton from "@mui/material/IconButton";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import CircularProgress from '@mui/material/CircularProgress';

/* Utils */
import { useNavigate } from "react-router-dom";
import sh from "@acromove/utils/styles-helpers";
import {useDeleteNotificationMutation, useMarkNotificationAsReadMutation} from "redux/notifications/notification.api"

import { useTranslation } from "react-i18next";

const ICONS = {
	info: <CheckCircleIcon color="success" />,
	warning: <ErrorIcon sx={{ color: "#EEBD75" }} />,
	alert: <WarningIcon sx={{ color: "#E26F6F" }} />,
};

const NotificationItem = (props) => {
	const { itemId, severity, template, actions, timestamp } = props;
	const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
	const [deleteNotification, {isLoading: deleteLoading}] = useDeleteNotificationMutation()
	const [markAsRead, {isLoading: markLoading}] = useMarkNotificationAsReadMutation()
	const { t } = useTranslation("notifications");
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
	return (
		<ListItem sx={{ px: 4 }} alignItems="flex-start">
			<ListItemAvatar sx={{ width: "24px", height: "24px", minWidth: "unset" }}>
				{deleteLoading || markLoading ? <CircularProgress sx={{width: "25px !important", height: "25px !important"}} color="primary" /> : ICONS[severity]}
			</ListItemAvatar>
			<ListItemText
				sx={{ px: 2 }}
				primary={
					<Box sx={sh.flex("row", "space-between", "flex-start")}>
						<Typography color="text.primary" sx={{ pr: 2 }}>
							{t(`messages.${template}.msg`)}
						</Typography>
						<IconButton sx={{mt: -1}} onClick={handleClick}>
							<MoreVertIcon />
						</IconButton>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
						>
                            <Box sx={sh.flex("column")}>
							    <Button 
									disabled={deleteLoading || markLoading}
									onClick={() => markAsRead({id: itemId})} 
									sx={{color:"text.primary", justifyContent: "flex-start"}}>
										{t("main.mark_as_read_one")}
								</Button>
							    <Button
									disabled={deleteLoading || markLoading} 
									onClick={() => deleteNotification({id: itemId})} 
									sx={{width: "100%", justifyContent: "flex-start"}} 
									color="error">
										{t("main.delete_one")}
								</Button>
                            </Box>
						</Popover>
					</Box>
				}
				secondary={
					<Box sx={[sh.flex("row", "space-between", "center"), { mt: 1 }]}>
						<Typography>{timestamp}</Typography>
						<Button
							sx={{ ml: "auto", mr: 0, px: 2, py: 0.5 }}
							onClick={actions.type === "link" ? () => navigate(actions.to) : actions.func}
							size="small"
							variant="outlined"
							color="primary"
						>
							{t(`messages.${template}.action_label`)}
						</Button>
					</Box>
				}
			/>
		</ListItem>
	);
};

NotificationItem.propTypes = {
	itemId: PropTypes.number.isRequired,
	severity: PropTypes.string.isRequired,
	template: PropTypes.string.isRequired,
	actions: PropTypes.func.isRequired,
	timestamp: PropTypes.string.isRequired,
};

export default NotificationItem;
