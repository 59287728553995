import React, {useEffect, useState} from "react";

/* MUI */
import Grid from "@mui/material/Grid";

/* Components */
import Wizard, { useStep } from "@acromove/components/wizard";

import LeftContent from "components/content/acrotransfer/wizard-steps/shared/left-map";
import TransferForm from "components/content/acrotransfer/wizard-steps/generic-forms/transfer";

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const DestinationTransfer = () => {
	const wizard = useStep();
	const [location, setLocation] = useState([42, -71]);
	const { t } = useTranslation("acrotransfer_create_job", { keyPrefix: "steps.destination_transfer" });

	const defValues = wizard.state.stepsData?.destination_transfer || {
		fullname: "",
		phone: "",
		email: "",
		mobile: "",
	};

	const { control, handleSubmit, reset } = useForm({
		defaultValues: defValues,
	});

	const onSubmit = (data) => {
		wizard.actions.setStepData("destination_transfer", data);
	};

	useEffect(() => {
		if (wizard.state.stepsData?.destination_shipping?.address?.lat !== undefined) {
			setLocation([
				wizard.state.stepsData?.destination_shipping?.address?.lat,
				wizard.state.stepsData?.destination_shipping?.address?.lon,
			]);
		}
	}, [wizard.state.stepsData?.destination_shipping?.address]);

	useEffect(() => {
		reset(wizard.state.stepsData?.destination_transfer)
	}, [wizard.state.stepsData?.destination_transfer])

	const handleNext = () => {
		handleSubmit(onSubmit)();
		return true;
	};

	return (
		<Wizard.Step slug="destination_transfer" label={t("label")} pos={9}>
			<Grid container>
				<Grid item md={4}>
					<LeftContent location={location} />
				</Grid>
				<Grid item md={4}>
					<Wizard.Bar>
						<TransferForm onSubmit={handleSubmit(onSubmit)} keyPrefix ="steps.destination_transfer" control={control}/>
						<Wizard.Tools beforeNext={handleNext} />
					</Wizard.Bar>
				</Grid>
			</Grid>
		</Wizard.Step>
	);
};

export default DestinationTransfer;
