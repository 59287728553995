import React, { useState, useEffect } from "react";

/* MUI */
import { Typography, Grid, Divider, Box } from "@mui/material";

/* Components */
import Wizard, { useStep } from "@acromove/components/wizard";
import Radio from "@acromove/components/form/radio";
import LeftContent from "components/content/acrotransfer/wizard-steps/shared/left-icon";
import AsyncAutocomplete from "@acromove/components/form/autocomplete";

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

/* Redux */
import { transformFindOneJob } from "redux/jobs/transform-responses";
import { useLazyFindTemplatesQuery } from "redux/jobs/jobs.api";

/* Assets */
import serverImg from "assets/create_job.svg";

const TemplateSelect = () => {
	const wizard = useStep();
	const [data, setData] = useState({});

	const { t } = useTranslation("acrotransfer_create_job", { keyPrefix: "steps.template_select" });

	const schema = yup.object({
		type: yup.string(),
	});

	const formatData = (rawData) => ({
		what_kind_of_job: {
			name: rawData?.name,
			description: rawData?.description
		},
		data_volume: {
			data_size: rawData?.data_size,
			amount: rawData?.data_size_format,
			type: rawData?.data_kind
		},
		source_shipping: {
			address: rawData?.source_info?.shipping_info?.address,
			attention_to: rawData?.source_info?.shipping_info?.attention_to,
			phone: rawData?.source_info?.shipping_info?.phone_number,
			notes: rawData?.source_info?.shipping_info?.address_notes,
			mobile: rawData?.source_info?.shipping_info?.mobile_number,
			email: rawData?.source_info?.shipping_info?.email,
		},
		source_transfer: {
			fullname: rawData?.source_info?.operator_info?.full_name,
			phone: rawData?.source_info?.operator_info?.phone_number,
			mobile: rawData?.source_info?.operator_info?.mobile_number,
			email: rawData?.source_info?.operator_info?.email,
		},
		source_connection: {
			connection: rawData?.source_info?.connection_info?.connection_type,
			ports: rawData?.source_info?.connection_info?.network_port,
			network: rawData?.source_info?.connection_info?.network_type,
			connect_cables: rawData?.source_info?.connection_info?.network_equipment,
		},
		source_cabling: {
			transceivers_cables: rawData?.source_info?.connection_info?.transceivers,
			fiber_cables: rawData?.source_info?.connection_info?.optic_fibre,
			cabling: rawData?.source_info?.connection_info?.cabling,
		},
		destination_shipping: {
			address: rawData?.destination_info?.shipping_info?.address,
			attention_to: rawData?.destination_info?.shipping_info?.attention_to,
			phone: rawData?.destination_info?.shipping_info?.phone_number,
			notes: rawData?.destination_info?.shipping_info?.address_notes,
			mobile: rawData?.destination_info?.shipping_info?.mobile_number,
			email: rawData?.destination_info?.shipping_info?.email,
		},
		destination_transfer: {
			fullname: rawData?.destination_info?.operator_info?.full_name,
			phone: rawData?.destination_info?.operator_info?.phone_number,
			mobile: rawData?.destination_info?.operator_info?.mobile_number,
			email: rawData?.destination_info?.operator_info?.email,
		},
		destination_connection: {
			connection: rawData?.destination_info?.connection_info?.connection_type,
			ports: rawData?.destination_info?.connection_info?.network_port,
			network: rawData?.destination_info?.connection_info?.network_type,
			connect_cables: rawData?.destination_info?.connection_info?.network_equipment,
		},
		destination_cabling: {
			transceivers_cables: rawData?.destination_info?.connection_info?.transceivers,
			fiber_cables: rawData?.destination_info?.connection_info?.optic_fibre,
			cabling: rawData?.destination_info?.connection_info?.cabling,
		}
	})

	const { control, handleSubmit, watch, reset } = useForm({
		resolver: yupResolver(schema),
		defaultValues: wizard.state.stepsData?.template_select || {
			type: "new",
			template: "",
		},
	});

	useEffect(() => {
		reset(wizard.state.stepsData?.template_select)
	}, [wizard.state.stepsData?.template_select])

	const wizardType = watch("type");
	const template = watch("template");

	const onSubmit = (values) => {
		if (wizardType === "template" && data) {
			wizard.actions.setStepsData(formatData(data));
			wizard.actions.setStepData("template_select", values);
		}
	};

	const handleNext = () => {
		handleSubmit(onSubmit)();
		return true;
	};

	useEffect(() => {
		if (wizardType === "template" && template) {
			const found = transformFindOneJob({
				payload: template.data
			})
			if (found) {
				setData(found);
			}
		}
	}, [template, wizardType]);

	return (
		<Wizard.Step slug="template_select" label={t("label")} pos={0}>
			<Grid container>
				<Grid item md={4}>
					<LeftContent image={serverImg} />
				</Grid>
				<Grid item md={4}>
					<Wizard.Bar>
						<Box
							component="form"
							onSubmit={handleSubmit(onSubmit)}
							display="flex"
							flexDirection="column"
							sx={{
								gap: 4,
								width: "100%",
							}}
						>
							<Typography fontWeight={600} fontSize={20}>
								{t("title")}
							</Typography>
							<Typography fontWeight={500} fontSize={18} color="text.secondary" sx={{ my: -4 }}>
								{t("desc")}
							</Typography>
							<Radio
								sx={{ width: "100%", display: "flex", flexDirection: "column" }}
								sxRadioGroup={{ display: "flex", flexDirection: "column" }}
								control={control}
								name="type"
								options={[
									{ label: t("use_temp"), value: "template" },
									{ label: t("use_job"), value: "new" },
								]}
							/>

							{wizardType === "template" && (
								<>
									<Typography fontWeight={600} fontSize={20} sx={{ my: -2 }}>
										{t("select_temp.label")}
									</Typography>
						
									<AsyncAutocomplete control={control} useLazyLoad={useLazyFindTemplatesQuery} name="template" />

									<Grid container gap={1} sx={{ display: "flex", flexDirection: "column" }}>
										<Typography fontWeight={600} fontSize={20}>
											{t("temp_info")}:
										</Typography>

										<Grid gap={1} item md={12} sx={{ display: "flex", flexDirection: "row" }}>
											<Grid
												container
												gap={1}
												sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
											>
												<Grid item md={4}>
													<Typography fontWeight={500} fontSize={14}>
														{t("description")}:
													</Typography>
												</Grid>
												<Grid item md={8}>
													<Typography textAlign="right" fontWeight={400} fontSize={14}>
														{data?.description || "-/-"}
													</Typography>
												</Grid>
											</Grid>
										</Grid>

										<Divider />

										<Grid gap={1} item md={12} sx={{ display: "flex", flexDirection: "row" }}>
											<Grid
												container
												gap={1}
												sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
											>
												<Grid item md={4}>
													<Typography fontWeight={500} fontSize={14}>
														{t("data_size")}:
													</Typography>
												</Grid>
												<Grid item md={8}>
													<Typography textAlign="right" fontWeight={400} fontSize={14}>
													{data?.data_size + data?.data_size_format?.toUpperCase() || "-/-" //eslint-disable-line
													}
													</Typography>
												</Grid>
											</Grid>
										</Grid>

										<Divider />

										<Grid gap={1} item md={12} sx={{ display: "flex", flexDirection: "row" }}>
											<Grid
												container
												gap={1}
												sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
											>
												<Grid item md={4}>
													<Typography fontWeight={500} fontSize={14}>
														{t("data_type")}:
													</Typography>
												</Grid>
												<Grid item md={8}>
													<Typography textAlign="right" fontWeight={400} fontSize={14}>
													{data?.data_kind || "-/-"}
													</Typography>
												</Grid>
											</Grid>
										</Grid>

										<Divider />

										<Grid gap={1} item md={12} sx={{ display: "flex", flexDirection: "row" }}>
											<Grid
												container
												gap={1}
												sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
											>
												<Grid item md={4}>
													<Typography fontWeight={500} fontSize={14}>
														{t("data_source")}:
													</Typography>
												</Grid>

												<Grid item md={8}>
													<Typography textAlign="right" fontWeight={400} fontSize={14}>
														{data?.source_info?.shipping_info?.address?.name || "-/-"}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</>
							)}
						</Box>
						<Wizard.Tools beforeNext={handleNext} />
					</Wizard.Bar>
				</Grid>
			</Grid>
		</Wizard.Step>
	);
};

export default TemplateSelect;
