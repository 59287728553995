import React from "react";
import PropTypes from "prop-types"

/* MUI */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

/* Utils */
import sh from "@acromove/utils/styles-helpers";
import { useTranslation } from "react-i18next";

/* Assets */
import copper1 from "assets/copper1.png";
import copper2 from "assets/copper2.png";
import optic1 from "assets/optic1.png";
import optic2 from "assets/optic2.png";

const LeftCabling = (props) => {
	const {selected} = props
	const { t } = useTranslation("acrotransfer_create_job");

	return (
		<Box sx={{ flexDirection: "column", display: "flex", width: "calc(100% - 16px)", mr: 2 }}>
			<Typography fontWeight={600} fontSize={20}>
				{t("main_desc")}
			</Typography>
			<Typography fontWeight={500} fontSize={18} sx={{ my: 2 }} color="text.secondary">
				{t("main_desc_ext")}
			</Typography>
			<Grid container gap={1} sx={[{ height: "500px" }, sh.flex("column", "center", "center")]}>
				<Grid
					item
					sx={{
						filter: selected === "fibre" ? "unset" : "grayscale(1)",
						opacity: selected === "fibre" ? "1" : ".5",
					}}
				>
					<Box
						sx={{
							backgroundColor: "background.paper",
							display: "flex",
							flexDirection: "row",
							flexWrap: "nowrap",
							padding: 2,
						}}
					>
						<img width="50%" src={optic1} alt="optic" />
						<img width="50%" src={optic2} alt="optic" />
					</Box>
				</Grid>
				<Grid
					item
					sx={{
						filter: selected === "direct_copper" ? "unset" : "grayscale(1)",
						opacity: selected === "direct_copper" ? "1" : ".5",
					}}
				>
					<Box
						sx={{
							backgroundColor: "background.paper",
							display: "flex",
							flexDirection: "row",
							flexWrap: "nowrap",
							padding: 2,
						}}
					>
						<img width="50%" src={copper1} alt="copper" />
						<img width="50%" src={copper2} alt="copper" />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

LeftCabling.propTypes = {
    selected: PropTypes.oneOf(["direct_copper", "fibre"]).isRequired
}

export default LeftCabling;
