import yup from "@acromove/utils/validators";

export default (t) => ({
    full_name: yup.string().required(t("acrotransfer_jobs.source_info.operator_info.full_name.errors.required")),
    phone_number: yup
        .string()
        .phoneNumber(t("acrotransfer_jobs.source_info.operator_info.phone_number.errors.matches"))
        .required(t("acrotransfer_jobs.source_info.operator_info.phone_number.errors.required")),
    mobile_number: yup
        .string()
        .phoneNumber(t("acrotransfer_jobs.source_info.operator_info.mobile_number.errors.matches"))
        .required(t("acrotransfer_jobs.source_info.operator_info.mobile_number.errors.required")),
    email: yup.string().email().required(t("acrotransfer_jobs.source_info.operator_info.email.errors.required")),
})