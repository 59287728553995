import { configureStore, combineReducers } from "@reduxjs/toolkit";
import app from "./app.slice";
import auth from "@acromove/redux/auth/slice";
import authAPI from "@acromove/redux/auth/api";
import uploadsAPI from "@acromove/redux/uploads/api";
import notificationsAPI from "./notifications/notification.api";
import geolocationApi from "./geolocation/geolocation.api"
import jobsApi from "./jobs/jobs.api"
import clientsApi from "./clients/clients.api"
import statisticsApi from "./statistics/statistics.api"
import themeAPI from "./theme/theme.api";
import mw from "@acromove/utils/redux/mw"
import i18n from "../i18n";

const rootReducer = combineReducers({
    app,
    auth,
    [authAPI.reducerPath]: authAPI.reducer,
    [uploadsAPI.reducerPath]: uploadsAPI.reducer,
    [notificationsAPI.reducerPath]: notificationsAPI.reducer,
    [geolocationApi.reducerPath]: geolocationApi.reducer,
    [jobsApi.reducerPath]: jobsApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [statisticsApi.reducerPath]: statisticsApi.reducer,
    [themeAPI.reducerPath]: themeAPI.reducer
})

const MWs = [
    mw.rtkQueryErrorLogger(i18n, "api_responses", "error.validation"),
    mw.rtkQuerySuccessLogger(i18n, "api_responses"),
    notificationsAPI.middleware,
    geolocationApi.middleware,
    jobsApi.middleware,
    clientsApi.middleware,
    statisticsApi.middleware,
    themeAPI.middleware
]

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(MWs)
})

window.store = store

export default store;