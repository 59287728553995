import yup from "@acromove/utils/validators";
import point from "./point";

export default (t) => ({
    name: yup.string().required(t("acrotransfer_jobs.name.errors.required")),
    data_kind: yup.string().required(t("acrotransfer_jobs.data_kind.errors.required")),
    data_size: yup.string().required(t("acrotransfer_jobs.data_size.errors.required")),
    data_size_format: yup
        .string()
        .oneOf(["gb", "tb", "pb"])
        .required(t("acrotransfer_jobs.data_size_format.errors.required")),
    start_date: yup.date().required(t("acrotransfer_jobs.start_date.errors.required")),
    source_info: yup.object(point(t)),
    destination_info: yup.object(point(t))
})