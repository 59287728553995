import React, { useState, useEffect } from "react";

/* Content Components */
import OverviewForm from "components/content/acrotransfer/overview";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";
import Section from "components/layouts/dashboard/ui/section";

import Form from "@acromove/components/form";
import { useForm } from "react-hook-form";

/* Utils */
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import helpers from "@acromove/utils/helpers";

/* Assets */
import pcDevice from "assets/as-js-string/pc_device";
import { useTheme } from "@mui/system";

/* Redux */
import { useFindOneJobQuery } from "redux/jobs/jobs.api";
import ResourcesMap from "components/content/acrotransfer/resources-map";

const JobsViewContainer = () => {
	const { jobId } = useParams();
	const numericId = parseInt(jobId.split("-")[0], 10);
	const { t } = useTranslation("acrotransfer_jobs");
	const theme = useTheme();

	const { data, isLoading } = useFindOneJobQuery({ id: numericId });

	const form = useForm({
		defaultValues: data,
	});
	const watchSourceLocation = form.watch("source_info.shipping_info.address");
	const watchDestinationLocation = form.watch("destination_info.shipping_info.address");

	useEffect(() => {
		if (data) {
			form.reset(data);
		}
	}, [data]);

	const mapGroups = {
		slug: "devices",
		label: "Devices",
		icon: pcDevice(theme.palette.text.active),
		points: [
			[watchSourceLocation?.lat || 0, watchSourceLocation?.lon || 0],
			[watchDestinationLocation?.lat || 0, watchDestinationLocation?.lon || 0],
		],
	};

	const [center, setCenter] = useState(helpers.findAverageLocation(mapGroups.points));

	useEffect(() => {
		setCenter(helpers.findAverageLocation(mapGroups.points));
	}, [watchSourceLocation, watchDestinationLocation]);

	return (
		<Container loading={isLoading} skeleton={["section:7"]}>
			<Container.Header title={t("back")} back={-1} />
			<Section title={t("job_information.titles.map")} collapsible>
				<ResourcesMap groups={[mapGroups]} center={center} polyline zoom={3} />
			</Section>
			<Form
				name="acrotransfer_jobs"
				i18n="acrotransfer_job_overview"
				mode="findOne"
				form={form}
				sx={{ width: "100%" }}
			>
				<OverviewForm form={form} mode="findOne" />
			</Form>
		</Container>
	);
};

export default JobsViewContainer;
