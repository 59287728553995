import moment from "moment";
import i18n from "../../i18n"

export const transformFindOneJob = ({payload: data}) => ({
    	name: data?.name || "",
		description: data?.description || "",
		data_kind: data?.data_kind || "",
		data_size: data?.data_size || "",
		data_size_format: data?.data_size_format || "",
		start_date: data?.start_date || "",
		source_info: {
			shipping_info: {
				location: data?.source_info?.shipping_info?.location || [0, 0],
				address: {
					lat: data?.source_info?.shipping_info?.location[0],
					lon: data?.source_info?.shipping_info?.location[1],
					id: 0,
					name: data?.source_info?.shipping_info?.address
				},
				address_notes: data?.source_info?.shipping_info?.address_notes || "",
				attention_to: data?.source_info?.shipping_info?.attention_to || "",
				phone_number: data?.source_info?.shipping_info?.phone_number || "",
				mobile_number: data?.source_info?.shipping_info?.mobile_number || "",
				email: data?.source_info?.shipping_info?.email || "",
			},
			operator_info: {
				full_name: data?.source_info?.operator_info?.full_name || "",
				phone_number: data?.source_info?.operator_info?.phone_number || "",
				mobile_number: data?.source_info?.operator_info?.mobile_number || "",
				email: data?.source_info?.operator_info?.email || "",
			},
			connection_info: {
				connection_type: data?.source_info?.connection_info?.connection_type || "",
				network_port: data?.source_info?.connection_info?.network_port || "",
				cabling: data?.source_info?.connection_info?.cabling || "",
				network_type: data?.source_info?.connection_info?.network_type || "",
				network_equipment: data?.source_info?.connection_info?.network_equipment || [],
				transceivers: data?.source_info?.connection_info?.transceivers || [],
				optic_fibre: data?.source_info?.connection_info?.optic_fibre || [],
			},
		},
		destination_info: {
			shipping_info: {
				location: data?.destination_info?.shipping_info?.location || [0, 0],
				address: {
					lat: data?.destination_info?.shipping_info?.location[0],
					lon: data?.destination_info?.shipping_info?.location[1],
					id: 0,
					name: data?.destination_info?.shipping_info?.address
				},
				address_notes: data?.destination_info?.shipping_info?.address_notes || "",
				attention_to: data?.destination_info?.shipping_info?.attention_to || "",
				phone_number: data?.destination_info?.shipping_info?.phone_number || "",
				mobile_number: data?.destination_info?.shipping_info?.mobile_number || "",
				email: data?.destination_info?.shipping_info?.email || "",
			},
			operator_info: {
				full_name: data?.destination_info?.operator_info?.full_name || "",
				phone_number: data?.destination_info?.operator_info?.phone_number || "",
				mobile_number: data?.destination_info?.operator_info?.mobile_number || "",
				email: data?.destination_info?.operator_info?.email || "",
			},
			connection_info: {
				connection_type: data?.destination_info?.connection_info?.connection_type || "",
				network_port: data?.destination_info?.connection_info?.network_port || "",
				cabling: data?.destination_info?.connection_info?.cabling || "",
				network_type: data?.destination_info?.connection_info?.network_type || "",
				network_equipment: data?.destination_info?.connection_info?.network_equipment || [],
				transceivers: data?.destination_info?.connection_info?.transceivers || [],
				optic_fibre: data?.destination_info?.connection_info?.optic_fibre || [],
			},
		},
})


const jobStatuses = {
	pending: 10
}

export const transformFindJob = ({payload}) => ({
	items: payload.items.map(item => ({
		id: item.id,
		name: item.name,
		description: item?.description,
		start_date: moment(item.start_date).format("DD.MM.YYYY"),
		source: item.source_info.shipping_info.address,
		destination: item.destination_info.shipping_info.address,
		client: item?.group_info?.name,
		url: `/jobs/view/${item.id}-${item.name.replace(/ /g, "_")}`,
		active_step: {
			label: i18n.t(`acrotransfer_jobs:job_statuses.${item.active_step}`),
			value: jobStatuses[item.active_step]
		}
	})),
	total: payload.total
})

export const transformFindHistory = ({payload}) => ({
	items: payload.items.map(item => ({
		id: item.id,
		name: item.name,
		description: item?.description,
		start_date: moment(item.start_date).format("DD.MM.YYYY"),
		end_date: moment(item.end_date).format("DD.MM.YY"),
		data_kind: i18n.t(`acrotransfer_job_overview:acrotransfer_jobs.data_kind.options.${item?.data_kind}`),
		data_size: `${item?.data_size}${item?.data_size_format.toUpperCase()}`,
		source: item.source_info.shipping_info.address,
		destination: item.destination_info.shipping_info.address,
		client: item?.group_info?.name,
		url: `/jobs/view/${item.id}-${item.name.replace(/ /g, "_")}`,
	})),
	total: payload.total
})

export const transformFindTemplate = ({payload}) => ({
	items: payload.items.map(item => ({
		data: {...item.data},
		id: item?.id,
		name: item?.name,
		description: item?.data?.description,
		data_kind: i18n?.t(`acrotransfer_job_overview:acrotransfer_jobs.data_kind.options.${item?.data?.data_kind}`)|| "-/-",
		data_size: `${item?.data?.data_size}${item?.data?.data_size_format.toUpperCase()}` || "-/-",
		source: item?.data?.source_info?.shipping_info?.address || "-/-",
		destination: item?.data?.destination_info?.shipping_info?.address || "-/-",
		url: `/jobs_templates/view/${item.id}-${item.name.replace(/ /g, "_")}`,
	
	})),
	total: payload.total
})

export const transformFindOneTemplate = ({payload: {data}}) => ({
	name: data?.name || "",
	description: data?.description || "",
	data_kind: data?.data_kind || "",
	data_size: data?.data_size || "",
	data_size_format: data?.data_size_format || "",
	start_date: data?.start_date || "",
	source_info: {
		shipping_info: {
			location: data?.source_info?.shipping_info?.location || [0, 0],
			address: {
				lat: data?.source_info?.shipping_info?.location[0],
				lon: data?.source_info?.shipping_info?.location[1],
				id: 0,
				name: data?.source_info?.shipping_info?.address
			},
			address_notes: data?.source_info?.shipping_info?.address_notes || "",
			attention_to: data?.source_info?.shipping_info?.attention_to || "",
			phone_number: data?.source_info?.shipping_info?.phone_number || "",
			mobile_number: data?.source_info?.shipping_info?.mobile_number || "",
			email: data?.source_info?.shipping_info?.email || "",
		},
		operator_info: {
			full_name: data?.source_info?.operator_info?.full_name || "",
			phone_number: data?.source_info?.operator_info?.phone_number || "",
			mobile_number: data?.source_info?.operator_info?.mobile_number || "",
			email: data?.source_info?.operator_info?.email || "",
		},
		connection_info: {
			connection_type: data?.source_info?.connection_info?.connection_type || "",
			network_port: data?.source_info?.connection_info?.network_port || "",
			cabling: data?.source_info?.connection_info?.cabling || "",
			network_type: data?.source_info?.connection_info?.network_type || "",
			network_equipment: data?.source_info?.connection_info?.network_equipment || [],
			transceivers: data?.source_info?.connection_info?.transceivers || [],
			optic_fibre: data?.source_info?.connection_info?.optic_fibre || [],
		},
	},
	destination_info: {
		shipping_info: {
			location: data?.destination_info?.shipping_info?.location || [0, 0],
			address: {
				lat: data?.destination_info?.shipping_info?.location[0],
				lon: data?.destination_info?.shipping_info?.location[1],
				id: 0,
				name: data?.destination_info?.shipping_info?.address
			},
			address_notes: data?.destination_info?.shipping_info?.address_notes || "",
			attention_to: data?.destination_info?.shipping_info?.attention_to || "",
			phone_number: data?.destination_info?.shipping_info?.phone_number || "",
			mobile_number: data?.destination_info?.shipping_info?.mobile_number || "",
			email: data?.destination_info?.shipping_info?.email || "",
		},
		operator_info: {
			full_name: data?.destination_info?.operator_info?.full_name || "",
			phone_number: data?.destination_info?.operator_info?.phone_number || "",
			mobile_number: data?.destination_info?.operator_info?.mobile_number || "",
			email: data?.destination_info?.operator_info?.email || "",
		},
		connection_info: {
			connection_type: data?.destination_info?.connection_info?.connection_type || "",
			network_port: data?.destination_info?.connection_info?.network_port || "",
			cabling: data?.destination_info?.connection_info?.cabling || "",
			network_type: data?.destination_info?.connection_info?.network_type || "",
			network_equipment: data?.destination_info?.connection_info?.network_equipment || [],
			transceivers: data?.destination_info?.connection_info?.transceivers || [],
			optic_fibre: data?.destination_info?.connection_info?.optic_fibre || [],
		},
	},
})



export default {transformFindOneJob, transformFindJob, transformFindHistory, transformFindTemplate, transformFindOneTemplate};