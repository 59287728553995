import React, { useState } from "react";
import PropTypes from "prop-types";

/* MUI */
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Grid from "@mui/material/Grid";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";

/* Layout Components */
import Section from "components/layouts/dashboard/ui/section";

/* Common Components */
import Input from "@acromove/components/form/input";
import Select from "@acromove/components/form/select";
import AvatarUploader from "@acromove/components/form/avatar-uploader";
import Form from "@acromove/components/form";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

/* Redux */
import { useEditUserMutation } from "redux/clients/clients.api";

const TenantDetails = (props) => {
	const { data, userKey } = props;
	const { t } = useTranslation("clients_users_info");

	const [editUser, { isLoading }] = useEditUserMutation({ key: userKey });
	const [editMode, setEditMode] = useState(false);

	const downMd = useMediaQuery((theme) => `${theme.breakpoints.down("md")}`);
	const schema = yup.object({});
	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			organization: data?.organization,
			username: data?.username,
			group: data?.group_value,
			domain: data?.domain,
			avatar: data?.avatar,
		},
	});

	const { errors } = form.formState;

	const onSubmit = async (value) => {
		await editUser({ value, userKey });
	};

	const handleCancelEdit = () => {
		if (!editMode) {
			setEditMode(true);
		} else if (editMode) {
			form.reset(data);
			setEditMode(false);
		}
	};

	const handleSaveEdit = () => {
		if (editMode) {
			form.handleSubmit(onSubmit)();
			if (!Object.keys(errors).length) {
				setEditMode(!editMode);
			}
			return;
		}
		setEditMode(!editMode);
	};

	return (
			<Section title={t("admin_form.title")}
			collapsible
			action={
				<Button onClick={handleCancelEdit} startIcon={editMode ? <CancelIcon /> : <BorderColorIcon />}>
					{editMode ? t("cancel_btn") : t("edit_btn")}
				</Button>
			}
			>
				<Form
					loading={isLoading}
					name="users"
					i18n="clients_users_info"
					form={form}
					disabled={!editMode}
					sx={{ display: "flex", flexDirection: "row", mt: 6 }}
				>
					<Grid container sx={{ display: "flex", flexDirection: "row", flexWrap: downMd ? "wrap" : "nowrap" }}>
						<Grid
							item
							xs={12}
							sm={12}
							md={8}
							gap={4}
							sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}
						>
							<Form.Field name="organization">
								<Grid item xs={12} sm={12} md={12}>
									<Input size="medium" sx={{ width: "100%" }} />
								</Grid>
							</Form.Field>

							<Form.Field name="username">
								<Grid item xs={12} sm={12} md={12}>
									<Input size="medium" sx={{ width: "100%" }} />
								</Grid>
							</Form.Field>

							<Form.Field name="domain">
								<Grid item xs={12} sm={12} md={12}>
									<Input size="medium" sx={{ width: "100%" }} />
								</Grid>
							</Form.Field>

							<Grid container>
								<Form.Field name="group">
									<Grid item xs={12} sm={12} md={6}>
										<Select
											size="medium"
											sx={{ width: "100%" }}
											options={[
												{ label: "Group 1", value: 1 },
												{ label: "Group 2", value: 2 },
												{ label: "Group 3", value: 3 },
											]}
										/>
									</Grid>
								</Form.Field>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={4} sx={{ pt: downMd && 2 }}>
							<Grid container>
								<Form.Field name="avatar">
									<Grid item sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
										<AvatarUploader
											direction="column"
											editText={t("users.avatar.edit_txt")}
											startIcon={<BorderColorIcon />}
											size={218}
											fontSize={24}
											alt=""
										/>
									</Grid>
								</Form.Field>
							</Grid>
						</Grid>
					</Grid>
				</Form>
				{editMode && (
				<Button
					startIcon={<SaveAsIcon />}
					onClick={handleSaveEdit}
					variant="contained"
					sx={{ marginLeft: "auto", mt: 1, display: "flex", alignSelf: "flex-end" }}
				>
					{t("save_btn")}
				</Button>
			)}
			</Section>
	);
}

TenantDetails.propTypes = {
	data: PropTypes.shape({
		organization: PropTypes.string,
		group_value: PropTypes.number,
		domain: PropTypes.string,
		username: PropTypes.string,
		avatar: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.shape({}), PropTypes.oneOf([null])]),
	}),
	userKey: PropTypes.string.isRequired,
};

TenantDetails.defaultProps = {
	data: {
		organization: "",
		group_value: 1,
		domain: "",
		username: "",
		avatar: "",
	}
};

export default TenantDetails;
