import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CircularProgress from "@mui/material/CircularProgress";
import StatusStat from "@acromove/components/statistics/StatusStat";
import { tableStatusMap } from "@acromove/components/statistics/status-map";

/* Utils */
import sh from "@acromove/utils/styles-helpers";
import useLocalUserSettings from "@acromove/hooks/use-local-user-settings"

const Section = (props) => {
	const { title, subtitle, action, children, collapsible, simple, loading, line, status, statusText, sxCard, sxCardContent } = props;
	const [open, setOpen] = useLocalUserSettings(title, true);

	return (
		<Card sx={{ mt: 1, mb: 1, pb: 1, width: "100%", position: "relative", ...sxCard }}>
			{line && (
				<Box
					sx={{
						position: "absolute",
						left: 0,
						top: "50%",
						transform: "translateY(-50%)",
						borderRadius: "0px 2px 2px 0px",
						width: "5px",
						height: "80%",
						backgroundColor: line === "review" ? tableStatusMap[line].backgroundColor : tableStatusMap[line].color,
					}}
				/>
			)}
			<CardHeader
				action={
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
							gap: 1,
						}}
					>
						{action}
						{collapsible ? (
							<IconButton size="small" onClick={() => setOpen(!open)}>
								{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							</IconButton>
						) : null}
						{status && <StatusStat type="table" status={status} statusText={statusText} />}
					</Box>
				}
				title={
					<Box sx={[sh.flex("row", "flex-start", "center"), { gap: 1 }]}>
						{loading && <CircularProgress sx={{ width: "20px !important", height: "20px !important" }} />}
						{title}
					</Box>
				}
				subheader={subtitle}
				sx={{
					display: (!title || title === "") && simple ? "none" : "flex",
					bgcolor: simple ? "transparent" : "#F4F6F9",
					mt: 0,
					mb: 0,
					ml: 1,
					mr: 1,
					marginTop: simple ? "10px" : "16px",
					color: (theme) => (simple ? theme.palette.text.secondary : theme.palette.text.primary),
					padding: simple ? "6px 16px" : "8px 16px",
					borderRadius: "6px",
				}}
			/>
			<Collapse in={open}>
				<CardContent
					sx={{
						p: "unset",
						pb: "0px !important",
						margin: "0px 11px",
						mt: 2,
						mb: 1,
						position: "relative",
						...sxCardContent
					}}
				>
					{children}
				</CardContent>
			</Collapse>
		</Card>
	);
};

Section.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	children: PropTypes.node,
	action: PropTypes.node,
	collapsible: PropTypes.bool,
	simple: PropTypes.bool,
	loading: PropTypes.bool,
	line: PropTypes.oneOf(["error", "success", "warning", "review", false]),
	statusText: PropTypes.string,
	sxCard: PropTypes.shape({}),
	sxCardContent: PropTypes.shape({}),
	status: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Section.defaultProps = {
	title: "",
	children: "",
	subtitle: "",
	action: "",
	collapsible: false,
	simple: false,
	loading: false,
	line: false,
	status: false,
	sxCard: {},
	sxCardContent: {},
	statusText: "",
};

export default Section;
