import React, { useEffect, useState } from "react";

/* MUI */
import Button from "@mui/material/Button";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Content Components */
import GroupDetails from "components/content/clients/group-details";
import GroupFormDialog from "components/content/clients/group-details-components/group-dialog";
import UserFormDialog from "components/content/clients/group-details-components/user-dialog";
import ExistUserTableDialog from "components/content/clients/group-details-components/exist-user-dialog";

/* Common Components */
import DataGridTable from "@acromove/components/data-grid"
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";
import { groupChildUsers, groupChildGroups } from "components/table-columns/admin/clients/groups";
import ProtectedComponent from "@acromove/authentication/components/protected/component";

/* Utils */
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";

/* Assets */
import { useParams } from "react-router-dom";

/* Redux */
import {
	useFindChildGroupsQuery,
	useFindChildUsersQuery,
	useFindOneGroupQuery,
	useCreateChildGroupMutation,
	useCreateChildUserMutation,
	useEditGroupMutation
} from "redux/clients/clients.api";


const ClientGroupsInfoContainer = () => {
	const [openGroup, setOpenGroup] = useState(false);
	const [openUser, setOpenUser] = useState(false);
	const [openExistUser, setOpenExistUser] = useState(false);
	const { groupId } = useParams();
	const Id = parseInt(groupId.split("-")[0], 10);
	const { t } = useTranslation("clients_groups_info");
	const itemsTranslator = useItemsTranslation("clients_groups_info");
	const itemsTranslatorGroups = useItemsTranslation("clients_groups");

	const { data, isLoading, refetch } = useFindOneGroupQuery({ key: Id });
	const [editGroup, { isLoading: editLoading, isSuccess: editSuccess }] = useEditGroupMutation({ key: Id });
	const [createChildGroup, { data: childGroupData, isSuccess: successGroup, isLoading: loadingGroup }] =
		useCreateChildGroupMutation();
	const [createChildUser, { data: childUserData, isSuccess: successUser, isLoading: loadingUser }] =
		useCreateChildUserMutation();

	const onGroupSubmit = (values) => {
		createChildGroup({ ...values, Id });
	};

	const onUserSubmit = (values) => {
		createChildUser({ ...values, Id });
	};

	const onGroupEditSubmit = async (value) => {
		console.log("value", value)
		await editGroup({ value: {
			...value,
			type_id: value.type_id.id,
			colors: JSON.stringify(value.colors)
		}, Id })
	};

	useEffect(() => {
		refetch();
	}, [groupId, data]);
	
	return (
		<Container loading={isLoading}>
			<Container.Header title={t("back")} back={-1} skeleton={["form:2", "section:4"]} />
			
			<GroupFormDialog
				mode="add"
				submit={onGroupSubmit}
				open={openGroup}
				onClose={() => setOpenGroup(false)}
				data={childGroupData}
				isLoading={loadingGroup}
				isSuccess={successGroup}
			/>

			<UserFormDialog
				submit={onUserSubmit}
				open={openUser}
				onClose={() => setOpenUser(false)}
				data={childUserData}
				isLoading={loadingUser}
				isSuccess={successUser}
			/>

			<ExistUserTableDialog hookId={Id} open={openExistUser} onClose={() => setOpenExistUser(false)} groupKey={data?._key} //eslint-disable-line
			/> 

			<ProtectedComponent slug="groups:findOne">
				<GroupDetails
					submit={onGroupEditSubmit}
					data={data} 
					groupKey={Id} 
					isLoading={editLoading}
					isSuccess={editSuccess}
					saveButton
					/>
			</ProtectedComponent>

			<DataGridProvider useHook={useFindChildGroupsQuery} hookProps={{ key: Id }} initSort="name">
				<DataGridTable
					collapsible
					search
					action={
						<ProtectedComponent slug="groups:add">
							<Button variant="contained" onClick={() => setOpenGroup(true)}>
								{t("group_table.add_new")}
							</Button>
						</ProtectedComponent>
					}
					title={t("group_table.title")}
					columns={itemsTranslatorGroups(groupChildGroups, "table", "field", "headerName")}
				/>
			</DataGridProvider>

			<DataGridProvider useHook={useFindChildUsersQuery} hookProps={{ key: Id }} initSort="username">
				<DataGridTable
					collapsible
					search
					sx={{ mt: "20px" }}
					title={t("user_table.title")}
					columns={itemsTranslator(groupChildUsers, "user_table", "field", "headerName")}
					action={
						<>
							<ProtectedComponent slug="users:add">
								<Button variant="contained" onClick={() => setOpenUser(true)}>
									{t("user_table.add_new")}
								</Button>
							</ProtectedComponent>
							<ProtectedComponent slug={["users:assign-to-group", "users:unassign-from-group"]}>
								<Button variant="contained" onClick={() => setOpenExistUser(true)}>
									{t("user_table.add_exist")}
								</Button>
							</ProtectedComponent>

						</>
					}
				/>
			</DataGridProvider>
		</Container>
	);
};

export default ClientGroupsInfoContainer;
