import React from "react";
import PropTypes from "prop-types";

/* MUI */
import { Typography, Grid, Box, Button } from "@mui/material";

/* Components */
import Input from "@acromove/components/form/input";
import AuthFormWrapper from "../wrapper";
import Form from "@acromove/components/form";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SignUpForm = (props) => {
	const { submit } = props;
	const { t } = useTranslation("home_page", { keyPrefix: "register" });
	const navigate = useNavigate();

	const schema = yup.object({
		firstName: yup.string().required(t("firstName.required")),
		lastName: yup.string().required(t("lastName.required")),
		password: yup.string().required(t("password.required")),
		username: yup.string().required(t("username.required")),
		organizationName: yup.string().required(t("organizationName.required")),
		organizationDesc: yup.string(),
		confirmPassword: yup
			.string()
			.equalTo(yup.ref("password"), t("confirmPassword.equalTo"))
			.required(t("confirmPassword.required")),
	});

	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			username: "",
			password: "",
			confirmPassword: "",
		},
	});

	const handleRegister = () => {
		form.handleSubmit(submit)();
	};

	return (
		<AuthFormWrapper>
			<Form mode="anonymous" form={form} name="register" i18n="home_page">
				<Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
					<Typography fontWeight={500} fontSize={36}>
						{t("sign_up_title")}
					</Typography>
					<Typography fontWeight={300} fontSize={14} sx={{ color: "text.secondary" }}>
						{t("start_acc")}
					</Typography>
				</Box>
				<Grid container gap={4} sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}>
					<Grid item md={12}>
						<Grid container gap={2} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
							<Grid item md={6} sm={6}>
								<Form.Field name="firstName">
									<Input sx={{ width: "100%", mb: 1 }} size="medium" />
								</Form.Field>
							</Grid>
							<Grid item md={6} sm={6}>
								<Form.Field name="lastName">
									<Input sx={{ width: "100%", mb: 1 }} size="medium" />
								</Form.Field>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={12}>
						<Form.Field name="username">
							<Input sx={{ width: "100%", mb: 1 }} size="medium" />
						</Form.Field>
					</Grid>

					<Grid item md={12}>
						<Grid container gap={2} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
							<Grid item md={6} sm={6}>
								<Form.Field name="password">
									<Input sx={{ width: "100%", mb: 1 }} size="medium" type="password" />
								</Form.Field>
							</Grid>
							<Grid item md={6} sm={6}>
								<Form.Field name="confirmPassword">
									<Input sx={{ width: "100%", mb: 1 }} size="medium" type="password" />
								</Form.Field>
							</Grid>
						</Grid>
					</Grid>

					<Grid item md={12}>
						<Form.Field name="organizationName">
							<Input sx={{ width: "100%", mb: 1 }} size="medium" />
						</Form.Field>
					</Grid>

					<Grid item md={12}>
						<Form.Field name="organizationDesc">
							<Input sx={{ width: "100%", mb: 1 }} size="medium" type="textarea" />
						</Form.Field>
					</Grid>
					<Button variant="contained" size="medium" onClick={() => handleRegister()}>
						{t("sign_up")}
					</Button>
				</Grid>
				<Box sx={{ display: "flex", alignSelf: "flex-end", alignItems: "center", my: 1 }}>
					<Typography fontSize={14}>{t("have_acc")}</Typography>
					<Button variant="text" onClick={() => navigate("/login")}>
						{t("login")}
					</Button>
				</Box>
			</Form>
		</AuthFormWrapper>
	);
};

SignUpForm.propTypes = {
	submit: PropTypes.func.isRequired,
};

export default SignUpForm;
