import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import InfoStat from "@acromove/components/statistics/InfoStat";

const DataTransferServices = ({items}) => (
    <Grid container sx={{mt: 1}} spacing={1}>
        {items.map(item => (
            <Grid item key={`${item.label}_${item.stat}`} xs={12} sm={6} md={4} lg={4}>
                   <InfoStat
                    type="icon"
                    label={item.label}
                    value={item?.stat || 0}
                    total={item.total}
                    icon={item.icon}
                />
            </Grid>
        ))}
    </Grid>
);

DataTransferServices.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        icon: PropTypes.string
    }))
}

DataTransferServices.defaultProps = {
    items: []
}

export default DataTransferServices;
