/* eslint-disable */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/* MUI */
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CancelIcon from "@mui/icons-material/Cancel";
import Button from "@mui/material/Button";

/* Layout Components */
import Section from "components/layouts/dashboard/ui/section";

/* Common Components */
import GroupForm from "components/content/clients/group-form";
import ProtectedComponent from "@acromove/authentication/components/protected/component";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "@acromove/utils/validators";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MAX_FILE_SIZE = 2048; // in KB

const GroupDetails = (props) => {
	const { saveButton, data, isLoading, isSuccess, submit, detailsButton, mode } = props;
	const { t } = useTranslation("clients_groups_info");
	const [editMode, setEditMode] = useState(false);
	const navigate = useNavigate();

	const defValues = {
		name: data?.name,
		description: data?.description,
		type_id: data?.settings?.type,
		colors: {
			accentColor: data?.settings?.colors?.accentColor,
			navigationBg: data?.settings?.colors?.navigationBg,
			navigationFontColor: data?.settings?.colors?.navigationFontColor,
			navigationDivider: data?.settings?.colors?.navigationDivider,
		},
		logo: data?.settings?.logo,
	};

	const schema = yup.object({
		name: yup.string().required(t("groups.name.required")),
		type_id: yup.object().shape({
			id: yup.number().required(t("groups.type.required")),
		}),
		colors: yup.object().shape({
			accentColor: yup
				.string()
				.hex(t("groups.colors.accentColor.hex"))
				.required(t("groups.colors.accentColor.required")),
			navigationBg: yup
				.string()
				.hex(t("groups.colors.navigationBg.hex"))
				.required(t("groups.colors.navigationBg.required")),
			navigationFontColor: yup
				.string()
				.hex(t("groups.colors.navigationFontColor.hex"))
				.required(t("groups.colors.navigationFontColor.required")),
			navigationDivider: yup
				.string()
				.hex(t("groups.colors.navigationDivider.hex"))
				.required(t("groups.colors.navigationDivider.required")),
		}),
		logo: yup.lazy((value) => {
			if(value instanceof File) {
				return yup.mixed()
						.file([".jpg", ".png"], t("groups.logo.file"))
						.fileSize(MAX_FILE_SIZE, t("groups.logo.size"))
						// .resolution({ width: 210, height: 37 }, t("groups.logo.resolution"));
			}
				return yup.object().nullable();
			}
		),
	});

	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: defValues,
	});

	useEffect(() => {
		form.reset(defValues);
	}, [data]);

	const handleCancelEdit = () => {
		if (!editMode) {
			setEditMode(true);
		} else if (editMode) {
			form.reset({
				defValues,
				colors: {
					accentColor: data?.settings?.colors?.accentColor,
					navigationBg: data?.settings?.colors?.navigationBg,
					navigationFontColor: data?.settings?.colors?.navigationFontColor,
					navigationDivider: data?.settings?.colors?.navigationDivider,
				},
			});
			setEditMode(false);
		}
	};

	const handleSaveEdit = async() => {
		const result = await form.trigger()
		if (result) {
			form.handleSubmit(submit)();
			setEditMode(!editMode)
		}
	};

	return (
		<Section
			collapsible
			title={t("groups.title.label")}
			loading={isLoading}
			action={
				<>
					{saveButton && (
						<ProtectedComponent slug="groups:edit">
							<Button onClick={handleCancelEdit} startIcon={editMode ? <CancelIcon /> : <BorderColorIcon />}>
								{editMode ? t("cancel") : t("edit")}
							</Button>
						</ProtectedComponent>
					)}
					{detailsButton && (
						<Button onClick={() => navigate(`view/${data._id.split("/")[1]}-${data.name.replace(/ /g, "_")}`)}>
							{" "}
							{t("details")}{" "}
						</Button>
					)}
				</>
			}
		>
			<GroupForm
				submit={handleSaveEdit}
				editMode={editMode}
				isLoading={isLoading}
				isSuccess={isSuccess}
				mode={mode}
				form={form}
			/>
		</Section>
	);
};

GroupDetails.propTypes = {
	saveButton: PropTypes.bool,
	detailsButton: PropTypes.bool,
	submit: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	isSuccess: PropTypes.bool,
	data: PropTypes.shape({
		name: PropTypes.string,
		description: PropTypes.string,
		type: PropTypes.string,
		_key: PropTypes.string,
		_id: PropTypes.string,
		settings: PropTypes.shape({
			type_id: PropTypes.number.isRequired,
		}),
	}),
	mode: PropTypes.string,
};

GroupDetails.defaultProps = {
	saveButton: false,
	detailsButton: false,
	data: {
		name: "",
		description: "",
		type: "tenant",
		_key: "",
		_id: ""
	},
	isSuccess: false,
	isLoading: false,
	mode: "edit",
};

export default GroupDetails;
