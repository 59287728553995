import React from "react";
import { createSvgIcon } from '@mui/material/utils';

const PcDevice = createSvgIcon(
    <svg viewBox="0 -8 24 24" fill="#36B32F" xmlns="http://www.w3.org/2000/svg">
        <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M9.75 4.95L12.4583 4.9555C12.7563 4.9555 13 5.1975 13 5.5V10.45C13 10.7525 12.7563 11 12.4583 11H9.75C9.45208 11 9.20833 10.7525 9.20833 10.45V5.5C9.20833 5.1975 9.45208 4.95 9.75 4.95ZM9.75 9.9H12.4583V6.05H9.75V9.9ZM1.08333 0H10.8333C11.4292 0 11.9167 0.4895 11.9167 1.1V3.85H10.8333V1.1H1.08333V7.7H8.125V8.8H7.04167V9.9H8.125V11H3.79167V9.9H4.875V8.8H1.08333C0.482083 8.8 0 8.305 0 7.7V1.1C0 0.4895 0.482083 0 1.08333 0Z" fill="#D1D5DB"/>
    </svg>
, 'PcDevice');

export default PcDevice;
