import React from "react";
import PropTypes from "prop-types";


/* Content Components */
import JobInfo from "components/content/acrotransfer/overview/forms/job-info";
import ShippingInfo from "components/content/acrotransfer/overview/forms/shipping-info";
import TransferInfo from "components/content/acrotransfer/overview/forms/transfer-info";
import EquipmentInfo from "components/content/acrotransfer/overview/forms/equipment-info";

/* Layout Components */
import Section from "components/layouts/dashboard/ui/section";

/* Utils */
import { useTranslation } from "react-i18next";

const OverviewForm = (props) => {
	const { form, mode } = props;
	const { t } = useTranslation("acrotransfer_job_overview");

	const sourceConnection = form.watch("source_info.connection_info.connection_type");
	const sourceCabling = form.watch("source_info.connection_info.cabling");
	const destinationConnection = form.watch("destination_info.connection_info.connection_type");
	const destinationCabling = form.watch("destination_info.connection_info.cabling");


	return (
		<>
			<Section title={t("acrotransfer_jobs.title")} collapsible sx={{ gap: 4, width: "100%" }}>
				<JobInfo />
			</Section>

			<Section title={t("acrotransfer_jobs.source_info.shipping_info.title")} collapsible>
				<ShippingInfo prefix="source_info" />
			</Section>

			<Section title={t("acrotransfer_jobs.source_info.operator_info.title")} collapsible>
				<TransferInfo prefix="source_info" />
			</Section>

			<Section title={t("acrotransfer_jobs.source_info.connection_info.title")} collapsible>
				<EquipmentInfo
					cabling={sourceCabling}
					connection={sourceConnection}
					prefix="source_info"
					form={form.control}
					mode={mode}
				/>
			</Section>

			<Section title={t("acrotransfer_jobs.destination_info.shipping_info.title")} collapsible>
				<ShippingInfo prefix="destination_info" />
			</Section>

			<Section title={t("acrotransfer_jobs.destination_info.operator_info.title")} collapsible>
				<TransferInfo prefix="destination_info" />
			</Section>

			<Section title={t("acrotransfer_jobs.destination_info.connection_info.title")} collapsible>
				<EquipmentInfo
					cabling={destinationCabling}
					connection={destinationConnection}
					prefix="destination_info"
					form={form.control}
					mode={mode}
				/>
			</Section>
		</>
	);
};

OverviewForm.propTypes = {
	form: PropTypes.shape({
		watch: PropTypes.func,
		control: PropTypes.shape({})
	}).isRequired,
	mode: PropTypes.string.isRequired
};

export default OverviewForm;
