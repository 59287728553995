import yup from "@acromove/utils/validators";

export default (t) => ({
    address: yup.mixed().required(t("acrotransfer_jobs.source_info.shipping_info.address.errors.required")),
    attention_to: yup
        .string()
        .required(t("acrotransfer_jobs.source_info.shipping_info.attention_to.errors.required")),
    phone_number: yup
        .string()
        .phoneNumber(t("acrotransfer_jobs.source_info.shipping_info.phone_number.errors.matches"))
        .required(t("acrotransfer_jobs.source_info.shipping_info.phone_number.errors.required")),
    mobile_number: yup
        .string()
        .phoneNumber(t("acrotransfer_jobs.source_info.shipping_info.mobile_number.errors.matches"))
        .required(t("acrotransfer_jobs.source_info.shipping_info.mobile_number.errors.required")),
    email: yup.string().email().required(t("acrotransfer_jobs.source_info.shipping_info.email.errors.required")),
})