import {useState} from "react";

const useSelectableRows = (props) => {
    const {getInitialSelected} = props;
    const [changed, setChanged] = useState([])
	const [selected, setSelected] = useState([])

    const onSelect = (item) => {
		let toChanged = [...changed];
		const isExist = changed.find(({id}) => id === item.id)
		if(item.action === "add"){
			setSelected([...selected, item.id])
		} else if(item.action === "remove"){
			setSelected(selected.filter(id => id !== item.id))
		} 

		if(isExist) {
			toChanged = [...toChanged.filter(({id}) => id !== item.id), item]
		} else {
			toChanged.push(item)
		}
		setChanged(toChanged)
	}

    const onUpdate = (items) => {
		const shouldBeSelected = getInitialSelected(items)
		const result = []
		for (const item of items) {
			const found = shouldBeSelected.find(s => s === item.id)
			const foundInChanged = changed.find(s => s.id === item.id)
			if(foundInChanged && foundInChanged.action === "remove") continue;
			else if(foundInChanged && foundInChanged.action === "add") result.push(item.id);
			else if(found){
				result.push(item.id)
			}
		}
		setSelected(result) 
	}

    const clear = () => {
        setChanged([])
        setSelected([])
    }

    return {selected, changed, onUpdate, onSelect, clear}
}


export default useSelectableRows;