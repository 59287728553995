import React, { useEffect, useState } from "react";

/* MUI */
import { Button } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CancelIcon from "@mui/icons-material/Cancel";

/* Content Components */
import OverviewForm from "components/content/acrotransfer/overview";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";
import Form from "@acromove/components/form";

/* Utils */
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import _ from "lodash";

/* Redux */
import { useFindOneTemplateQuery, useEditTemplateMutation } from "redux/jobs/jobs.api";

const TemplateViewContainer = () => {
	const { t } = useTranslation("acrotransfer_create_job");
	const [editMode, setEditMode] = useState(false);

	const { templateId } = useParams();
	const numericId = parseInt(templateId.split("-")[0], 10);

	const { data, isLoading } = useFindOneTemplateQuery({ id: numericId });
	const [editTemplate] = useEditTemplateMutation({ id: numericId });

	const form = useForm({
		defaultValues: data,
	});

	useEffect(() => {
		if (data) {
			form.reset(data);
		}
	}, [data]);

	const watchSourceLocation = form.watch("source_info.shipping_info.address");
	const watchDestinationLocation = form.watch("destination_info.shipping_info.address");

	const onTemplateEditSubmit = async (value) => {
		const cloned = _.cloneDeep(value);
		_.set(cloned, "source_info.shipping_info.address", _.get(cloned, "source_info.shipping_info.address.name"));
		_.set(
			cloned,
			"destination_info.shipping_info.address",
			_.get(cloned, "destination_info.shipping_info.address.name")
		);
		await editTemplate({...cloned, id: numericId})
	};

	const handleCancelEdit = () => {
		if (!editMode) {
			setEditMode(true);
		} else if (editMode) {
			form.reset(data);
			setEditMode(false);
		}
	};

	const handleSaveEdit = () => {
		form.handleSubmit(onTemplateEditSubmit)();
		setEditMode(!editMode);
	};

	
	useEffect(() => {
		form.setValue("source_info.shipping_info.location", [watchSourceLocation?.lat || 0, watchSourceLocation?.lon || 0])
		form.setValue("destination_info.shipping_info.location", [watchDestinationLocation?.lat || 0, watchDestinationLocation?.lon || 0])
	}, [watchSourceLocation, watchDestinationLocation]);

	return (
		<Container loading={isLoading} skeleton={["section:7"]}>
			<Container.Header title={t("back")} back={-1}>
					<Button onClick={handleCancelEdit} startIcon={editMode ? <CancelIcon /> : <BorderColorIcon />}>
						{editMode ? t("cancel") : t("edit")}
					</Button>
			</Container.Header>

			<Form
				name="acrotransfer_job_templates"
				i18n="acrotransfer_job_overview"
				localeName="acrotransfer_jobs"
				mode={editMode ? "edit" : "findOne"}
				form={form}
				sx={{ width: "100%" }}
			>
				<OverviewForm form={form} mode={editMode ? "edit" : "findOne"} />
			</Form>

			{editMode && (
				<Button onClick={handleSaveEdit} variant="contained" sx={{ display: "flex", marginLeft: "auto", mt: 1 }}>
					{t("save_template")}
				</Button>
			)}
		</Container>
	);
};

export default TemplateViewContainer;
