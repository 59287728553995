/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/* Common Components */
import Dialog from "@acromove/components/dialog";
import Box from "@mui/material/Box";

/* Common Components */
import DataGridTable from "@acromove/components/data-grid";
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";
import useSelectableRows from "@acromove/components/data-grid/use-selectable-rows";
import { groupChildUsers } from "components/table-columns/admin/clients/groups";

/* Utils */
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import { useFindUsersQuery, useAssignManyUsersMutation, useUnassignManyUsersMutation } from "redux/clients/clients.api";

const ExistUserTableDialog = (props) => {
	const { isLoading, open, onClose, hookId, groupKey } = props;
	const { t } = useTranslation("clients_groups_info");
	const itemsTranslator = useItemsTranslation("clients_groups_info");

	const [assignManyUsers] = useAssignManyUsersMutation();
	const [unassignManyUsers] = useUnassignManyUsersMutation();

	const { selected, changed, onUpdate, onSelect, clear } = useSelectableRows({
		getInitialSelected: (items) =>
			items.filter((item) => item.groups.map((gr) => parseInt(gr._key, 10)).includes(hookId)).map((item) => item.id),
	});

	const onUsersSubmit = (groupKey) => {
		const removedUsers = changed.filter((item) => item.action === "remove").map((item) => item.id);
		const addedUsers = changed.filter((item) => item.action === "add").map((item) => item.id);

		if (addedUsers.length) {
			assignManyUsers({ userKeys: addedUsers, groupKey });
		}
		if (removedUsers.length) {
			unassignManyUsers({ userKeys: removedUsers, groupKey });
		}
	};

	const handleClose = (value) => {
		if (value) {
			onUsersSubmit(groupKey);
			onClose();
		} else onClose();
		clear();
	};

	return (
		<Dialog
			labelCancel={t("discard")}
			labelOk={t("submit")}
			loading={isLoading}
			maxWidth="md"
			fullWidth
			open={open}
			onClose={handleClose}
			title={t("user_table.title")}
		>
			<Box sx={{ height: 400, width: "100%" }}>
				<DataGridProvider useHook={useFindUsersQuery}>
					<DataGridTable
						search
						checkboxSelection
						selected={selected}
						onUpdate={onUpdate}
						onSelect={onSelect}
						title={t("user_table.title")}
						columns={itemsTranslator(groupChildUsers, "user_table", "field", "headerName")}
					/>
				</DataGridProvider>
			</Box>
		</Dialog>
	);
};

ExistUserTableDialog.propTypes = {
	submit: PropTypes.func,
	isLoading: PropTypes.bool,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	isSuccess: PropTypes.bool,
};

ExistUserTableDialog.defaultProps = {
	isLoading: false,
	submit: () => null,
	onClose: () => null,
	open: false,
	isSuccess: false,
};

export default ExistUserTableDialog;
