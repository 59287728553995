import React from "react";
import PropTypes from "prop-types";

/* MUI */
import { Typography, Box } from "@mui/material";

import Select from "@acromove/components/form/select";
import CableBrandModelField from "components/content/acrotransfer/wizard-steps/shared/cable-brand-model-field";

/* Utils */
import { useTranslation } from "react-i18next";

const CablingForm = (props) => {
    const {onSubmit, keyPrefix, control, watchCabling} = props

    const { t } = useTranslation("acrotransfer_create_job", { keyPrefix });

    return ( 
        <Box
        component="form"
        onSubmit={onSubmit}
        display="flex"
        flexDirection="column"
        sx={{
            gap: 4,
            width: "100%",
        }}
    >
        <Typography fontWeight={600} fontSize={20}>
            {t("cables")}
        </Typography>

        <Typography fontWeight={500} fontSize={18} color="text.secondary" sx={{ my: -1 }}>
            {t("desc_ext")}
        </Typography>

        <Select
            sx={{ width: "100%" }}
            control={control}
            label={t("form.cabling.label")}
            name="cabling"
            options={[
                { label: t("form.cabling.types.optical"), value: "fibre" },
                { label: t("form.cabling.types.copper"), value: "direct_copper" },
            ]}
        />

        {watchCabling === "fibre" && (
            <>
                <CableBrandModelField
                    localePath="steps.source_cabling.form"
                    name="transceivers_cables"
                    control={control}
                />
                <CableBrandModelField
                    localePath="steps.source_cabling.form"
                    name="fiber_cables"
                    control={control}
                />
            </>
        )}
    </Box>
    )
}

CablingForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    keyPrefix: PropTypes.string.isRequired,
    watchCabling: PropTypes.string.isRequired,
    control: PropTypes.shape({}).isRequired
}

export default CablingForm