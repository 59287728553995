import React from "react";
import PropTypes from "prop-types";

/* MUI */
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl } from "@mui/material";
import DateRangeIcon from '@mui/icons-material/DateRange';

/* Utils */
import useField from "./hooks/use-filed";

/**
 * DatePicker component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Form Controlled DatePicker Component.
 */
const DatePicker = (props) => {
    const { label, name: fieldName, control, disabled, ...rest} = props;

    const field = useField({
        fromProps: {
            label,
            name: fieldName,
            disabled,
			control,
        }
    })

    const { onBlur, ref, onChange, value } = field.controller.field
    
    const styles = {
        input: {
           marginLeft: "18px"
        }
    }

	return (
		<FormControl disabled={field.disabled} {...rest}>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<MUIDatePicker
                 components={{OpenPickerIcon: DateRangeIcon}}
                 InputProps={{sx: {flexDirection: "row-reverse", paddingRight: "0" , ...styles}}}
                 label={field.label}
                 onBlur={onBlur}
                 inputRef={ref} 
                 value={value} 
                 onChange={onChange}
                 disabled={field.disabled}
                 renderInput={(params) => <TextField fullWidth {...params} />} />
			</LocalizationProvider>
		</FormControl>
	);
};

/**
 * DatePicker Properties.
 *
 * @typedef {object} Props
 * @property {string | true | false} [label = "false"]  - Datepicker Label.
 * @property {object} control - Form control options.
 * @property {string} name - Form Datepicker Name.
 * @property {string | number | null} [defaultValue = "null"] - Default Value for DatePicker.
 */

DatePicker.propTypes = {
    control: PropTypes.shape({}),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    name: PropTypes.string,
    defaultValue: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(null)]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func
}

DatePicker.defaultProps = {
    defaultValue: null,
    label: false,
    disabled: false,
    control: {},
    name: "",
    onChange: () => {}
}


export default DatePicker;
