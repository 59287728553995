import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";

/* Components */
import Input from "@acromove/components/form/input";
import Form from "@acromove/components/form";
import PhoneInput from "@acromove/components/form/phone-input"


const TransferInfo = (props) => {
	const { prefix } = props;

	return (
		<Grid container gap={2} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
			<Grid item md={8}>
				<Grid container gap={2}>
					<Grid item md={12}>
						<Form.Field name={`${prefix}.operator_info.full_name`}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Form.Field>
					</Grid>
					<Grid item md={12}>
						<Grid container gap={2} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
							<Grid item md={6}>
								<Form.Field name={`${prefix}.operator_info.phone_number`}>
									<PhoneInput size="medium" type="tel" sx={{ width: "100%" }} />
								</Form.Field>
							</Grid>
							<Grid item md={6}>
								<Form.Field name={`${prefix}.operator_info.mobile_number`}>
									<PhoneInput size="medium" type="tel" sx={{ width: "100%" }} />
								</Form.Field>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={12}>
						<Form.Field name={`${prefix}.operator_info.email`}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Form.Field>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

TransferInfo.propTypes = {
	prefix: PropTypes.string.isRequired,
};

export default TransferInfo;
