import React, {createContext, useContext, useEffect} from "react";
import PropTypes from "prop-types";

import Store from "./reducer"

const wizardContext = createContext(Store.initialState)

/**
 * Wizard Provider.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Wizard Provider.
 */
const WizardProvider = (props) => {
    const {children, activeStep, wizard, overview: Overview} = props;

    useEffect(() => {
       wizard.actions.goTo(activeStep)
    }, [activeStep])

    return (
        <wizardContext.Provider value={wizard}>
            {(Overview && wizard.state.showOverview) ? <Overview stepsData={wizard.state.stepsData}/> : children}
        </wizardContext.Provider>
    )
}

/**
 * Wizard Provider Properties.
 *
 * @typedef {object} Props
 * @property {React.ReactNode} children.
 * @property {string} [activeStep = ""] - Currently Active step of the wizard.
 * @property {React.ReactNode | true | false | function} [overview = "false"] - Property passed for wizard that has final overview component of all steps.
 * @property {object} wizard
 * @property {object} wizard.state
 * @property {object} wizard.state.stepsData
 * @property {true | false} wizard.state.showOverview - Property defines whether to display Overview or not.
 * @property {functions} wizard.dispatch
 * @property {object} wizard.events
 * @property {function} wizard.events.onFinish - Function executed when wizard is finished.
 * @property {object} wizard.actions
 * @property {function} wizard.actions.goTo - Function for redirecting.
 * @property {object} wizard.privateActions
 * @property {function} wizard.privateActions.addToOrdering
 */

WizardProvider.propTypes = {
    children: PropTypes.node.isRequired,
    activeStep: PropTypes.string,
    overview: PropTypes.oneOfType([PropTypes.node, PropTypes.bool, PropTypes.func]),
    wizard: PropTypes.shape({
        state: PropTypes.shape({
            stepsData: PropTypes.shape({}),
            showOverview: PropTypes.bool
        }),
        dispatch: PropTypes.func,
        events: PropTypes.shape({
            onFinish: PropTypes.func
        }),
        actions: PropTypes.shape({
            goTo: PropTypes.func
        }),
        privateActions: PropTypes.shape({
            addToOrdering: PropTypes.func
        })
    }).isRequired
}

WizardProvider.defaultProps = {
    overview: false,
    activeStep: ""
}

export default {
    ctx: wizardContext,
    WizardProvider,
    useWizard: () => useContext(wizardContext)
}

