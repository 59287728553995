import React from "react";

/* MUI */
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

/* Components */
import Radio from "@acromove/components/form/radio";
import Input from "@acromove/components/form/input";
import Wizard, { useStep } from "@acromove/components/wizard"

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";


const DeviceInspectionForm = () => {
	const { t } = useTranslation("acrotransfer_health_check", { keyPrefix: "steps.security_stickers" }); //eslint-disable-line
	const wizard = useStep();

	const defValues = wizard.state.stepsData?.security_stickers || {
		damaged: "no",
		file: "",
	};

	const { control, handleSubmit } = useForm({
		defaultValues: defValues,
	});

	const onSubmit = (data) => {
		wizard.actions.setStepData("security_stickers", data)
	};

	const handleNext = () => {
		handleSubmit(onSubmit)();
		return true
	};

	return (
		<>
			<Box
				component="form"
				onSubmit={handleSubmit(onSubmit)}
				display="flex"
				flexDirection="column"
				sx={{
					gap: 4,
					width: "100%",
				}}
			>
				<Typography fontWeight={600} fontSize={20}>
					{t("main")}
				</Typography>
				<Typography color="text.secondary" fontWeight={500} fontSize={18}>
					{t("desc")}
				</Typography>
				<Radio
					sx={{ width: "100%", display: "flex", flexDirection: "column" }}
					sxRadioGroup={{ display: "flex", flexWrap: "wrap", flexDirection: "column", width: "50%", my: -2 }}
					control={control}
					name="damaged"
					options={[
						{ label: t("yes"), value: "yes" },
						{ label: t("no"), value: "no" },
					]}
				/>
				<Typography fontWeight={600} fontSize={20}>
					{t("upload")}
				</Typography>
				<Typography color="text.secondary" fontWeight={500} fontSize={18}>
					{t("upload_ext")}
				</Typography>
				<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", mb: 1 }}>
					<Input
						sx={{ width: "100%" }}
						size="medium"
						control={control}
						name="file"
						label={t("file")}
						placeholder={t("file_placeholder")}
					/>
					<Button sx={{ ml: 2 }} variant="contained" component="label">
						{t("upload_btn")}
						<input hidden accept="image/*" multiple type="file" />
					</Button>
					<IconButton
						color="primary"
						onClick={() => {}}
					>
						<AddIcon size="large" />
					</IconButton>
				</Box>
			</Box>
			<Wizard.Tools
				beforeNext={handleNext}
			/>
		</>
	);
};


export default DeviceInspectionForm;
