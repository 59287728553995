import SmartRouter from "@acromove/utils/smart-router";

/* Layouts */
import PublicLayout from "components/layouts/public";
import DashboardLayout from "components/layouts/dashboard";

/* Pages */
import LoginPage from "pages/login/login";
import UserProfilePage from "pages/user/profile";
import SignUpPage from "pages/login/sign_up"
import InvitePage from "pages/login/invite"
import ForgotPage from "pages/login/forgot"
import ResetPage from "pages/login/reset"

const router = new SmartRouter();

router.addRoute("/login", {
    title: "login",
    Container: LoginPage, 
    Layout: PublicLayout, 
    ignoreOnNav: true,
})

router.addRedirect("/user", "/user/profile")

router.addRoute("/user/profile", {
    title: "profile",
    Container: UserProfilePage, 
    Layout: DashboardLayout, 
    ignoreOnNav: true,
})

router.addRoute("/sign_up", {
    title: "sign_up",
    Container: SignUpPage,
    Layout: PublicLayout,
    ignoreOnNav: true
})

router.addRoute("/invite", {
    title: "invite",
    Container: InvitePage,
    Layout: PublicLayout,
    ignoreOnNav: true
})

router.addRoute("/forgot", {
    title: "forgot",
    Container: ForgotPage,
    Layout: PublicLayout,
    ignoreOnNav: true
})

router.addRoute("/reset", {
    title: "reset",
    Container: ResetPage,
    Layout: PublicLayout,
    ignoreOnNav: true
})


export default router;