import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import { LinearProgress, Typography } from "@mui/material";

/**
 * Data Grid Icon Link component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Data Grid Icon Link Component.
 */
const DataGridProgress = (props) => {
	const { row, field } = props
	return (
    <Box sx={{width: "100%"}}>
        <Typography sx={{mb: 1}} fontSize={14} fontWeight={400}>
            {row[field].label}
        </Typography>
        <LinearProgress value={row[field].value} variant="determinate" />
    </Box>
)}

/**
 * Data Grid Icon Link Properties.
 *
 * @typedef {object} Props
 * @property {object} params - Data Grid Value.
 */

 DataGridProgress.propTypes = {
   row: PropTypes.shape({}).isRequired,
   field: PropTypes.string.isRequired
}


export default DataGridProgress