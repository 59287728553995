import React from "react";

import DataGridIconLink from "@acromove/components/data-grid/cells/datagrid-icon-link";

/* Assets */
import { Switch } from "@mui/material";

export const groupMain = [
	{
		headerName: "id",
		field: "id",
		minWidth: 100,
		maxWidth: 100,
		sortable: false
	},
	{
		headerName: "Group Name",
		field: "name",
		minWidth: 150,
	},
	// {
	// 	Header: "Group Tenant",
	// 	accessor: "group_tenant",
	// },
	// {
	// 	Header: "Group Admin",
	// 	accessor: "group_admin",
	// },
	{
		headerName: "Type",
		field: "type",
		minWidth: 100,
	},
	// {
	// 	Header: "Spent",
	// 	accessor: "spent",
	// },
	// {
	// 	Header: "Quota",
	// 	accessor: "quota",
	// },
	{
		headerName: "url",
		field: "url",
		sortable: false,
		flex: 0.5,
		align: "right",
		renderCell: DataGridIconLink,
	},
];

export const groupChildUsers = [
	{
		headerName: "username",
		field: "username",
		minWidth: 300,
		sort: "desc"
	},
	{
		headerName: "firstName",
		field: "firstName",
		minWidth: 150,
		sortable: false,
	},
	{
		headerName: "lastName",
		field: "lastName",
		minWidth: 150,
		sortable: false,
	},
	{
		headerName: "role",
		field: "role",
		minWidth: 150,
		sortable: false
	},
	{
		headerName: "disabled",
		field: "disabled",
		minWidth: 150,
		sortable: false,
		renderCell: (params) => {
			const { row } = params;
			return <Switch size="small" checked={row.disabled} />;
		},
	},
	{
		headerName: "url",
		field: "url",
		sortable: false,
		flex: 0.5,
		align: "right",
		renderCell: DataGridIconLink,
	},
];

export const groupChildGroups = [
	{
		headerName: "id",
		field: "id",
		maxWidth: 200,
		sortable: false
	},
	{
		headerName: "name",
		field: "name",
		minWidth: 250,
	},
	// {
	// 	Header: "Group Tenant",
	// 	accessor: "group_tenant",
	// },
	// {
	// 	Header: "Group Admin",
	// 	accessor: "group_admin",
	// },
	{
		headerName: "type",
		field: "type",
		minWidth: 150,
		sortable: false
	},
	// {
	// 	Header: "Spent",
	// 	accessor: "spent",
	// },
	// {
	// 	Header: "Quota",
	// 	accessor: "quota",
	// },
	{
		headerName: "url",
		field: "url",
		flex: 0.5,
		align: "right",
		sortable: false,
		renderCell: DataGridIconLink,
	},
];

export const userLogs = [
	{
		Header: "username",
		accessor: "username"
	},
	{
		Header: "fullname",
		accessor: "fullname"
	},
	{
		Header: "role",
		accessor: "role"
	},
	{
		Header: "entity",
		accessor: "entity"
	},
	{
		Header: "action",
		accessor: "action"
	},
	{
		Header: "timestamp",
		accessor: "timestamp"
	}
]

export default {
	groupMain,
	groupChildUsers,
	groupChildGroups,
};
