import SmartRouter from "@acromove/utils/smart-router";

import PublicLayout from "components/layouts/public";

import HomePage from "pages";
// import HomeContainer from "containers/home";

const router = new SmartRouter();

router.addRoute("/", {
    title: "home",
    Container: HomePage,
    Layout: PublicLayout,
    index: true,
    ignoreOnNav: true,
})


export default router;