import React, { useState } from "react";
import PropTypes from "prop-types";

/* MUI */
import { Grid, Typography, IconButton} from "@mui/material"
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/* Components */
import Input from "@acromove/components/form/input";

/* Utils */
import { useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";


const CableBrandModelField = (props) => {
	const [showHelper, setShowHelper] = useState(false);
	const { control, name, localePath, locale, mode } = props;
	const { t } = useTranslation(locale, { keyPrefix: localePath });
	const { fields, append, remove } = useFieldArray({ control, name });

	return (
		<Grid container gap={1}>
			<Typography fontWeight={600} fontSize={20}>
				{t(`${name}.label`)}
				<IconButton
					sx={{
						ml: 1,
					}}
					color="primary"
					onClick={() => setShowHelper(!showHelper)}
				>
					<InfoOutlinedIcon />
				</IconButton>
			</Typography>
			{fields.map((field, index) => (
				<Grid
					key={field.id}
					container
					gap={1}
					sx={{
						display: "flex",
						flexWrap: "nowrap",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "flex-start",
					}}
				>
					<Grid item md={5}>
						<Input
							sx={{ width: "100%" }}
							size="medium"
							control={control}
							disabled={mode === "findOne"}
							name={`${name}.[${index}].brand`}
							label={t(`${name}.brand.label`)}
							placeholder={t(`${name}.brand.placeholder`)}
						/>
					</Grid>
					<Grid item md={5}>
						<Input
							sx={{ width: "100%" }}
							size="medium"
							control={control}
							disabled={mode === "findOne"}
							name={`${name}.[${index}].model`}
							label={t(`${name}.model.label`)}
							placeholder={t(`${name}.model.placeholder`)}
						/>
					</Grid>
					{mode === "add" && (
						<Grid item md={2} sx={{ display: "flex", pt: 0.9, alignItems: "center" }}>
							{index === 0 ? (
								<IconButton
									disabled={!(fields.length < 3)}
									color="primary"
									onClick={() => {
										if (fields.length < 3) {
											append({ model: "", brand: "" });
										}
									}}
								>
									<AddIcon size="large" />
								</IconButton>
							) : (
								<IconButton color="primary" onClick={() => remove(index)}>
									<DeleteOutlineIcon size="large" />
								</IconButton>
							)}
						</Grid>
					)}
				</Grid>
			))}
			{showHelper && (
				<Grid
					container
					gap={1}
					sx={{
						display: "flex",
						flexWrap: "nowrap",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "flex-start",
					}}
				>
					<Grid item md={5}>
						<Typography fontSize={12} color="text.secondary">
							{t(`${name}.brand.description`)}
						</Typography>
					</Grid>
					<Grid item md={5}>
						<Typography fontSize={12} color="text.secondary">
							{t(`${name}.model.description`)}
						</Typography>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

CableBrandModelField.propTypes = {
	localePath: PropTypes.string.isRequired,
	control: PropTypes.shape({}).isRequired,
	name: PropTypes.string.isRequired,
	locale: PropTypes.string,
	mode: PropTypes.string,
};

CableBrandModelField.defaultProps = {
	locale: "acrotransfer_create_job",
	mode: "add",
};

export default CableBrandModelField;
