import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import AcroTransferManagerJobsCreateContainer from "containers/acrotransfer/manager/jobs/create";

const AcroTransferCreatePage = () => (
	<RoleSwitcher>
		<RoleSwitcher.Role value={["user:view_only","user"]}>
			<AcroTransferManagerJobsCreateContainer />
		</RoleSwitcher.Role>
	</RoleSwitcher>
);

export default AcroTransferCreatePage;
