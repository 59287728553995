import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {transformFindOneJob, transformFindJob, transformFindHistory, transformFindTemplate, transformFindOneTemplate} from "./transform-responses" //eslint-disable-line
import helpers from "@acromove/utils/helpers";

const jobsAPI = createApi({
    reducerPath: "acrotransfer/jobs",
    baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("jobs")),
    keepUnusedDataFor: 30,
    endpoints: build => ({
        findJobs: build.query({
            query: ({limit, offset, sortItem, sortDir, search}) => ({
                url: "find",
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: transformFindJob,
            providesTags: ["JobsFind"]
        }),

        createJob: build.mutation({
            query: (data) => ({
               url: "create",
               method: "POST",
               body: data
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["JobsFind"]
        }),

        findOneJob: build.query({
            query: ({id}) => ({
                url: `find/${id}`
            }),
            transformResponse: transformFindOneJob
        }),

        findAllJobs: build.query({
            query: ({limit, offset, sortItem, sortDir, search}) => ({
                url: "all/find",
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: transformFindJob,
            providesTags: ["JobsFind"]
        }),

        findHistory: build.query({
            query: ({limit, offset, sortItem, sortDir, search}) => ({
                url: "history/find",
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: transformFindHistory,
            providesTags: ["JobsHistoryFind"]
        }),

        findAllHistory: build.query({
            query: ({limit, offset, sortItem, sortDir, search}) => ({
                url: "all/history/find",
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: transformFindHistory,
            providesTags: ["JobsHistoryFind"]
        }),

        createTemplate: build.mutation({
            query: (data) => ({
               url: "templates/create",
               method: "POST",
               body: data
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["TemplatesFind"]
        }),

        editTemplate: build.mutation({
            query: (data) => ({
                url: `templates/edit/${data.id}`,
                method: "PATCH",
                body: data
            }),
            transformResponse: (response) => response.payload,
            invalidatesTags: ["TemplatesFind", "FindOneTemplate"]
        }),

        removeTemplate: build.mutation({
            query: ({id}) => ({
                url: `templates/remove/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["TemplatesFind", "FindOneTemplate"]
        }),

        findTemplates: build.query({
            query: ({limit, offset, sortItem, sortDir, search}) => ({
                url: "templates/find",
                params: {
                    limit,
                    offset,
                    sortItem,
                    sortDir,
                    search
                }
            }),
            transformResponse: transformFindTemplate,
            providesTags: ["TemplatesFind"]
        }),

        findOneTemplate: build.query({
            query: ({id}) => ({
                url: `templates/find/${id}`
            }),
            transformResponse: transformFindOneTemplate,
            providesTags: ["FindOneTemplate"]
        }),
    })
})


export const {
   useCreateJobMutation,
   useFindJobsQuery,
   useLazyFindJobsQuery,
   useFindOneJobQuery,
   useLazyFindOneJobQuery,
   useFindAllJobsQuery,
   useLazyFindAllJobsQuery,
   useFindHistoryQuery,
   useLazyFindHistoryQuery,
   useFindAllHistoryQuery,
   useLazyFindAllHistoryQuery,
   useCreateTemplateMutation,
   useEditTemplateMutation,
   useRemoveTemplateMutation,
   useFindTemplatesQuery,
   useLazyFindTemplatesQuery,
   useFindOneTemplateQuery,
   useLazyFindOneTemplateQuery
} = jobsAPI

export default jobsAPI