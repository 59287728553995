import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import InventoryAdminContainer from "containers/acrotransfer/admin/inventory";

const AcroTransferInventoryPage = () => (
	<RoleSwitcher>
		<RoleSwitcher.Role value={["admin","admin:owner", "acromove_admin", "acromove_admin:owner"]}>
			<InventoryAdminContainer />
		</RoleSwitcher.Role>
	</RoleSwitcher>
);

export default AcroTransferInventoryPage;
