import React, { useEffect } from "react";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Content Components */
import GroupDetails from "components/content/clients/group-details";

/* Utils */
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/* Redux */
import { useCreateGroupMutation } from "redux/clients/clients.api"

const ClientGroupCreateContainer = () => {
	const { t } = useTranslation("clients_groups_info");
	const [createGroup, {isLoading, data, isSuccess}] = useCreateGroupMutation()
	const navigate = useNavigate()

	const onSubmit = (values) => {
		createGroup(values)
	}


	useEffect(() => {
		if(isSuccess){
			setTimeout(() => navigate("/clients/groups"), 1000)
		}
	}, [isSuccess]);

	return (
		<Container>
			<Container.Header title={t("create")} skeleton={["form:2", "section:4"]} />
			<GroupDetails submit={onSubmit} isLoading={isLoading} data={data} isSuccess={isSuccess} mode="add"/>
		</Container>
	);
};

export default ClientGroupCreateContainer;
