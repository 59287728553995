import { useReducer, useEffect } from "react";

import Store from "./reducer"

/**
 * 
 * @param {object} props 
 * @param {string} props.initial
 * @param {string} props.last 
 * @param {string} props.name
 * @param {function} props.onFinish 
 * @param {function} props.onSaveAsDraft  
 * @returns 
 */
const useWizard = (props) => {
    const { initial, last, name, onFinish, onSaveAsDraft} = props;
    const [state, dispatch] = useReducer(Store.reducer, Store.initialState);

    useEffect(() => {
        dispatch(Store.actions.setName(name))
    }, [])

    useEffect(() => {
        dispatch(Store.actions.goTo(initial))
    }, [initial])

    useEffect(() => {
        dispatch(Store.actions.setLast(last))
    }, [last])

    const handleFinish = () => {
        dispatch(Store.actions.toggleOverview(true))
        onFinish()
    }

    return {
        state, 
        dispatch,
        events: {
            onFinish: handleFinish,
            onSaveAsDraft
        },
        actions: {
            goTo: (slug) => {
                dispatch(Store.actions.goTo(slug))
            },
            setStepsData: (data) => {
                dispatch(Store.actions.setStepsData(data))
            },
            loadWizard: (data) => {
                dispatch(Store.actions.loadWizard(data))
            },
            setStepData: (slug, data) => {
                dispatch(Store.actions.setStepData(slug, data))
            }
        },
        privateActions: {
            addToOrdering: (slug, pos) => {
                dispatch(Store.actions.addToOrdering(slug, pos))
            },
            addToLabels: (slug, label) => {
                dispatch(Store.actions.addLabel(slug, label))
            }
        }
    }
}

export default useWizard;