import React from "react";

/* MUI */
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

/* Content Components */
import DataTransferServices from "components/content/acrotransfer/data-transfer-services";
import DataGridTable from "@acromove/components/data-grid";
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";
import Section from "components/layouts/dashboard/ui/section";
import { jobList } from "components/table-columns/manager/jobs";

/* Assets */
import chip from "assets/icons/chip.png";
import database from "assets/icons/database.png";

/* Utils */
import { useTranslation } from "react-i18next";
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import { useNavigate } from "react-router-dom";

/* Redux */
import { useFindJobsQuery } from "redux/jobs/jobs.api";
import { useFindOrganizationStatisticsQuery } from "redux/statistics/statistics.api";
import NoData from "components/common/no-data";
import WorkIcon from '@mui/icons-material/Work';

const DashboardContainer = () => {
	const { t } = useTranslation("acrotransfer_jobs");
	const itemsTranslator = useItemsTranslation("acrotransfer_jobs");
	const navigate = useNavigate();
	const { data, isLoading } = useFindOrganizationStatisticsQuery();

	const dataTransferItems = [
		{ label: t("statistics.devices"), stat: data?.devices?.used, icon: chip },
		{ label: t("statistics.yearly_jobs"), stat: data?.transfer_jobs?.yearly, icon: database },
		{
			label: t("statistics.transfer_jobs"),
			stat: `${data?.transfer_jobs?.active}/${data?.transfer_jobs?.total}`,
			icon: database,
		},
	];

	return (
		<Container loading={isLoading}>
			{data?.transfer_jobs.total === 0 ? (
				<NoData
					icon={<WorkIcon sx={{color: "text.secondary"}} />}
					onClick={() => navigate("/jobs/create")}
					text="No Jobs Found"
					butText={t("table.add_btn")}
				/>
			) : (
				<>
					<Section title={t("main.data_transfer")} collapsible>
						<DataTransferServices items={dataTransferItems} />
					</Section>

					<DataGridProvider useHook={useFindJobsQuery} initSort="name">
						<DataGridTable
							action={
								<Button
									variant="contained"
									size="small"
									onClick={() => navigate("/jobs/create")}
									startIcon={<AddIcon />}
								>
									{t("table.add_btn")}
								</Button>
							}
							collapsible
							search
							title={t("title")}
							columns={itemsTranslator(jobList, "job_table", "field", "headerName")}
						/>
					</DataGridProvider>
				</>
			)}
		</Container>
	);
};

export default DashboardContainer;
