import React from "react";
import PropTypes from "prop-types";

/* MUI */
import { Typography, Box, Grid, alpha } from "@mui/material";
import { useTheme } from "@mui/system";
import styles from "../styles"

const UxItem = (props) => {
	const { icon, text, title } = props;
	const theme = useTheme()
	return (
		<Grid
			item
			xl={3}
			lg={3}
			md={12}
			sx={{
				backgroundColor: "background.gray",
				minHeight: "400px",
				overflow: "hidden",
				display: "flex",
                flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
                px: 4,
                mt: 4,
				width: "100%",
			}}
		>
			<Box
				sx={{
					borderRadius: "50%",
					backgroundColor: alpha(theme.palette.primary.light , 0.3),
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
                    minHeight: "80px",
                    minWidth: "80px"
				}}
			>
				{icon}
			</Box>
			<Typography fontSize={24} fontWeight={500} sx={[styles.subTitles, {textAlign: "center", my: 1}]}>
				{title}
			</Typography>
			<Typography fontSize={16} color="text.grayDark" sx={[styles.regularText, {textAlign: "center"}]}>
				{text}
			</Typography>
		</Grid>
	);
};

UxItem.propTypes = {
	text: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	icon: PropTypes.node.isRequired,
};


export default UxItem;
