import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

/* Leaflet */
import { MapContainer, TileLayer, Marker } from "react-leaflet"; 
import "leaflet/dist/leaflet.css";
import L from "leaflet";

/* Components */
import Section from "components/layouts/dashboard/ui/section";

/* Utils */
import { useTranslation } from "react-i18next";

/* Assets */
import gpsPointIcon from "assets/icons/gps_point.png";

const icon = new L.Icon({
	iconAnchor: [20, 40],
	popupAnchor: [0, -35],
	iconSize: [35, 35],
	iconUrl: gpsPointIcon,
	iconRetinaUrl: gpsPointIcon,
});

const LeftMap = (props) => {
	const { location } = props;

	const { t } = useTranslation("acrotransfer_create_job");
	return (
		<Box sx={{ flexDirection: "column", display: "flex", width: "calc(100% - 16px)", mr: 2 }}>
			<Typography fontWeight={600} fontSize={20}>
				{t("main_desc")}
			</Typography>
			<Typography fontWeight={500} fontSize={18} sx={{ my: 2 }} color="text.secondary">
				{t("main_desc_ext")}
			</Typography>
			<Section title="Map" sxCard={{ mt: 0 }}>
				<MapContainer
					key={location}
					scrollWheelZoom={false}
					center={location}
					zoom={9}
					style={{
						height: `518px`,
						borderRadius: "8px",
					}}
				>
					<TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
					<Marker icon={icon} position={location} />
				</MapContainer>
			</Section>
		</Box>
	);
};

LeftMap.propTypes = {
	location: PropTypes.arrayOf(PropTypes.number),
};

LeftMap.defaultProps = {
	location: [42, -71],
};

export default LeftMap;
