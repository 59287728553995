/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Box from "@mui/material/Box";
import statusMap from "../../statistics/status-map";

/**
 * Table Status Cell component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Table Status Cell Component.
 */
const TableStatusCell = (props) => {
	const { status, icon } = props;

	if (status)
		return (
			<Box
				sx={{
					position: "absolute",
					left: 0,
					ml: -1,
					top: "50%",
					transform: "translateY(-50%)",
					borderRadius: "0px 2px 2px 0px",
					width: "5px",
					height: "40px",
					backgroundColor:
						status === "review"
							? statusMap.tableStatusMap[status].backgroundColor
							: statusMap.tableStatusMap[status].color,
				}}
			/>
		);
	else if (icon) return <Box sx={{display: "flex", justifyContent: "center"}}>{statusMap.tableIconStatusMap[icon].icon}</Box>;
};

/**
 * Table Status Cell Properties.
 *
 * @typedef {object} Props
 * @property {"info" | "warning" | "error" | false} [icon = "false"] - Icon status passed when status is represented with Icon.
 * @property {"success" | "warning" | "error" | "review" | false} [status = "false"] - Status passed when status is represented with line.
 */

TableStatusCell.propTypes = {
	status: PropTypes.oneOf(["success", "warning", "error", "review", false]),
	icon: PropTypes.oneOf(["info", "warning", "error", false]),
};

TableStatusCell.defaultProps = {
	status: false,
	icon: false,
};


export default TableStatusCell;
