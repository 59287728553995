import React from "react";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Common Components */
import { jobHistoryList } from "components/table-columns/manager/jobs";
import DataGridTable from "@acromove/components/data-grid";
import DataGridProvider from "@acromove/components/data-grid/data-grid-provider";

/* Utils */
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import { useTranslation } from "react-i18next";

/* Redux */
import { useFindHistoryQuery } from "redux/jobs/jobs.api";

const JobsContainer = () => {
	const { t } = useTranslation("common");
	const itemsTranslator = useItemsTranslation("acrotransfer_jobs");

	return (
		<Container>
			<DataGridProvider useHook={useFindHistoryQuery} initSort="name">
				<DataGridTable
					collapsible
					search
					title={t("titles.job_history")}
					columns={itemsTranslator(jobHistoryList, "job_table", "field", "headerName")}
				/>
			</DataGridProvider>
		</Container>
	);
};

export default JobsContainer;
