import React, { useState } from "react";
import PropTypes from "prop-types";

/* MUI */
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import SearchIcon from "@mui/icons-material/Search";

/* Content Components */
import NotificationsButton from "components/content/notifications/notification-button";

/* Components */
import DrawerContent from "./ui/drawer-content";
import Breadcrumbs from "./ui/breadcrumbs";
import UserMiniProfile from "./ui/user-mini-profile";

/* Utils */
import { Helmet } from "react-helmet";
import useOriginPath from "@acromove/hooks/use-origin-path";
import routes from "router/router";
import { useTranslation } from "react-i18next";
import useMediaQuery from '@mui/material/useMediaQuery';
// import logo from "assets/images/ac_logo.svg";


const drawerWidth = 280;

const DashboardLayout = (props) => {
	const { window, children } = props;
	const [mobileOpen, setMobileOpen] = useState(false);

	const { t } = useTranslation("router");

	const isDownMd = useMediaQuery((theme) => `${theme.breakpoints.down('lg')}`);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const {originPath, params} = useOriginPath();

	const container = window !== undefined ? () => window().document.body : undefined;


	return (
		<Box sx={{ display: "flex", maxWidth: "100%" }}>
			<Helmet>
				<title>{t(originPath.replace(/:/g, "__"), params)}</title>
			</Helmet>

			<AppBar
				position="fixed"
				sx={{ bgcolor: "background.paper", width: { lg: `calc(100% - ${drawerWidth}px)` }, ml: { sm: `${drawerWidth}px` } }}
			>
				<Toolbar sx={{ minHeight: { md: "52px" } }}>
					<IconButton
						aria-label="open drawer"
						edge="start"
						size="large"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: isDownMd ? "flex" : "none" }}
					>
						<MenuIcon />
					</IconButton>
					<Box
						sx={{ width: "100%", gap: 1 }}
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Box>
							<Breadcrumbs />
						</Box>

						{/* {isDownMd && <img style={{margin: "0 auto", boxShadow: "1 1 1 1"}} height="36" src={logo} alt="logo" />} */}

						<Box
							sx={{ gap: 1 }}
							display="flex"
							flexDirection="row"
							justifyContent="flex-end"
							alignItems="center"
						>
							<IconButton size="small">
								<SearchIcon />
							</IconButton>
							<IconButton size="small">
								<GroupIcon />
							</IconButton>
							<NotificationsButton />
							<IconButton size="small">
								<SettingsIcon />
							</IconButton>
							<UserMiniProfile />
						</Box>
					</Box>
				</Toolbar>
			</AppBar>

			<Box
				component="nav"
				sx={{
					width: { lg: drawerWidth },
					flexShrink: { md: 0 },
				}}
				aria-label="mailbox folders"
			>
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					PaperProps={{
						sx: {
							bgcolor: "sideMenu.background",
						},
					}}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block", lg: "none" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
					}}
				>
					<DrawerContent items={routes.navElements} />
				</Drawer>
				<Drawer
					variant="permanent"
					PaperProps={{
						sx: {
							bgcolor: "sideMenu.background",
						},
					}}
					sx={{
						display: { xs: "none", lg: "block" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
					}}
					open
				>
					<DrawerContent items={routes.navElements} />
				</Drawer>
			</Box>
			<Box component="main" sx={{ flexGrow: 1, p: 3, width: { lg: `calc(100% - ${drawerWidth}px)` } }}>
				<Toolbar sx={{ minHeight: { md: "52px" } }} />
				{children}
			</Box>
		</Box>
	);
};

DashboardLayout.propTypes = {
	children: PropTypes.node.isRequired,
	window: PropTypes.func,
};

DashboardLayout.defaultProps = {
	window: undefined,
};

export default DashboardLayout;
