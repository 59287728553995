import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import AcroTransferAdminContainer from "containers/acrotransfer/admin";
import AcroTransferManagerContainer from "containers/acrotransfer/manager";

const AcroTransferDashboardPage = () => (
	<RoleSwitcher>
		<RoleSwitcher.Role value={["acromove_admin:owner","acromove_admin","admin","admin:view_only","admin:owner"]}>
			<AcroTransferAdminContainer />
		</RoleSwitcher.Role>
		<RoleSwitcher.Role value={["user:view_only","user"]}>
			<AcroTransferManagerContainer />
		</RoleSwitcher.Role>
	</RoleSwitcher>
);

export default AcroTransferDashboardPage;
