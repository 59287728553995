import React from "react";

/* MUI */
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Radio from "@acromove/components/form/radio";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

/* Assets */

const SupportDeviceInfo = () => {
	const { t } = useTranslation("acrotransfer_jobs");

	const schema = yup.object({});

	const { control } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {},
	});

	return (
		<Box
			component="form"
			display="flex"
            alignItems="flex-start"
			flexDirection="column"
			sx={{
				gap: 4,
				width: "100%",
                ml: 1
			}}
		>
                <Typography fontWeight={600}>
                    {t("job_information.connection_setup.label")}
                </Typography>
                <Typography fontWeight={500} color="text.secondary">
                    {t("job_information.connection_setup.desc")}
                </Typography>
                <Radio
					sx={{ width: "100%", display: "flex", flexDirection: "column" }}
					sxRadioGroup={{ display: "flex", flexDirection: "column", flexWrap: "wrap", width: "100%", my: -4 }}
					control={control}
					name="type"
					options={[
						{ label: t("job_information.connection_setup.yes"), value: 1 },
						{ label: t("job_information.connection_setup.no"), value: 2 },
					]}
				/>
                <Button startIcon={<ContactSupportIcon />} variant="contained" size="medium">
                    {t("job_information.connection_setup.support")}
                </Button>
		</Box>
	);
};

export default SupportDeviceInfo;
