import React, {useEffect} from "react";

/* MUI */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

/* Components */
import Wizard, { useStep } from "@acromove/components/wizard";
import DatePicker from "@acromove/components/form/datepick";
import LeftContent from "components/content/acrotransfer/wizard-steps/shared/left-icon";

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import serverImg from "assets/harddrive.png";

const TimeFrame = () => {
	const wizard = useStep();
	const { t } = useTranslation("acrotransfer_create_job", { keyPrefix: "steps.time_frame" });

	const defValues = wizard.state.stepsData?.time_frame || {
		date: new Date(),
	};

	const { control, handleSubmit, reset } = useForm({
		defaultValues: defValues,
	});

	const onSubmit = (data) => {
		wizard.actions.setStepData("time_frame", data);
	};

	useEffect(() => {
		reset(wizard.state.stepsData?.time_frame)
	}, [wizard.state.stepsData?.time_frame])

	const handleNext = () => {
		handleSubmit(onSubmit)();
		return true;
	};

	return (
		<Wizard.Step slug="time_frame" label={t("label")} pos={3}>
			<Grid container>
				<Grid item md={4}>
					<LeftContent image={serverImg} width="60%" />
				</Grid>
				<Grid item md={4}>
					<Wizard.Bar>
						<Box
							component="form"
							onSubmit={handleSubmit(onSubmit)}
							display="flex"
							flexDirection="column"
							sx={{
								gap: 4,
								width: "100%",
							}}
						>
							<Typography fontWeight={600} fontSize={20}>
								{t("when")}
							</Typography>
							<Typography fontWeight={500} fontSize={18} color="text.secondary" sx={{ my: -1 }}>
								{t("when_ext")}
							</Typography>

							<Grid item md={6}>
								<DatePicker control={control} name="date" label="" sx={{ width: "100%" }} />
							</Grid>
						</Box>
						<Wizard.Tools beforeNext={handleNext} />
					</Wizard.Bar>
				</Grid>
			</Grid>
		</Wizard.Step>
	);
};

export default TimeFrame;
