/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";

/* MUI */
import { Button, Box } from "@mui/material";
import { useTheme } from "@mui/system";

/* Content Components */
import OverviewForm from "components/content/acrotransfer/overview";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";
import Form from "@acromove/components/form";
import Section from "components/layouts/dashboard/ui/section";
import ResourcesMap from "components/content/acrotransfer/resources-map";

/* Utils */
import { UNSAFE_NavigationContext } from "react-router-dom"; //eslint-disable-line
import helpers from "@acromove/utils/helpers";
import yup from "@acromove/utils/validators";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import main from "./schemas/main";

/* Redux */
import { useCreateJobMutation, useCreateTemplateMutation } from "redux/jobs/jobs.api";

/* Assets */
import pcDevice from "assets/as-js-string/pc_device";


const JobsOverViewContainer = ({ stepsData }) => {
	const { navigator } = useContext(UNSAFE_NavigationContext);
	const [createJob] = useCreateJobMutation();
	const [createTemplate] = useCreateTemplateMutation();
	const { t } = useTranslation("acrotransfer_job_overview");
	const theme = useTheme();

	const schema = yup.object(main(t));


	const defValues = {
		name: stepsData?.what_kind_of_job?.name || "",
		description: stepsData?.what_kind_of_job?.description || "",
		data_kind: stepsData?.data_volume?.type || "",
		data_size: stepsData?.data_volume?.data_size || "",
		data_size_format: stepsData?.data_volume?.amount || "",
		start_date: stepsData?.time_frame?.date || "",
		source_info: {
			shipping_info: {
				location: [stepsData?.source_shipping?.address?.lat, stepsData?.source_shipping?.address?.lon],
				address: stepsData?.source_shipping?.address || "",
				address_notes: stepsData?.source_shipping?.notes || "",
				attention_to: stepsData?.source_shipping?.attention_to || "",
				phone_number: stepsData?.source_shipping?.phone || "",
				mobile_number: stepsData?.source_shipping?.mobile || "",
				email: stepsData?.source_shipping?.email || "",
			},
			operator_info: {
				full_name: stepsData?.source_transfer?.fullname || "",
				phone_number: stepsData?.source_transfer?.phone || "",
				mobile_number: stepsData?.source_transfer?.mobile || "",
				email: stepsData?.source_transfer?.email || "",
			},
			connection_info: {
				connection_type: stepsData?.source_connection?.connection || "",
				network_port: stepsData?.source_connection?.ports || "",
				cabling: stepsData?.source_cabling?.cabling || "",
				network_type: stepsData?.source_connection?.network || "",
				network_equipment: stepsData?.source_connection?.connect_cables || [],
				transceivers: stepsData?.source_cabling?.transceivers_cables || [],
				optic_fibre: stepsData?.source_cabling?.fiber_cables || [],
			},
		},
		destination_info: {
			shipping_info: {
				location: [stepsData?.destination_shipping?.address?.lat, stepsData?.destination_shipping?.address?.lon],
				address: stepsData?.destination_shipping?.address || "",
				address_notes: stepsData?.destination_shipping?.notes || "",
				attention_to: stepsData?.destination_shipping?.attention_to || "",
				phone_number: stepsData?.destination_shipping?.phone || "",
				mobile_number: stepsData?.destination_shipping?.mobile || "",
				email: stepsData?.destination_shipping?.email || "",
			},
			operator_info: {
				full_name: stepsData?.destination_transfer?.fullname || "",
				phone_number: stepsData?.destination_transfer?.phone || "",
				mobile_number: stepsData?.destination_transfer?.mobile || "",
				email: stepsData?.destination_transfer?.email || "",
			},
			connection_info: {
				connection_type: stepsData?.destination_connection?.connection || "",
				network_port: stepsData?.destination_connection?.ports || "",
				cabling: stepsData?.destination_cabling?.cabling || "",
				network_type: stepsData?.destination_connection?.network || "",
				network_equipment: stepsData?.destination_connection?.connect_cables || [],
				transceivers: stepsData?.destination_cabling?.transceivers_cables || [],
				optic_fibre: stepsData?.destination_cabling?.fiber_cables || [],
			},
		},
	};

	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: defValues,
	});

	const handleTemplateSave = async () => {
		const result = await form.trigger("name")
		if (!result) return
		onTemplateCreate(form.getValues())
	};

	const onJobCreate = (values) => {
		const cloned = _.cloneDeep(values);
		_.set(cloned, "source_info.shipping_info.address", _.get(cloned, "source_info.shipping_info.address.label"));
		_.set(
			cloned,
			"destination_info.shipping_info.address",
			_.get(cloned, "destination_info.shipping_info.address.label")
		);
		createJob(cloned);
	};

	const onTemplateCreate = (values) => {
		const cloned = _.cloneDeep(values);
		_.set(cloned, "source_info.shipping_info.address", _.get(cloned, "source_info.shipping_info.address.label"));
		_.set(
			cloned,
			"destination_info.shipping_info.address",
			_.get(cloned, "destination_info.shipping_info.address.label")
		);
		createTemplate(cloned);
	};

	useEffect(() => {
		const unblock = navigator.block((tx) => {
			if (tx.location.pathname !== "/jobs/create") {
				// dispatch(clearDraft());
				console.log("back");
			}
			unblock();
			tx.retry();
		});

		return unblock;
	}, [navigator]);

	const watchSourceLocation = form.watch("source_info.shipping_info.address");
	const watchDestinationLocation = form.watch("destination_info.shipping_info.address");

	const mapGroups = {
		slug: "devices",
		label: "Devices",
		icon: pcDevice(theme.palette.text.active),
		points: [
			[watchSourceLocation?.lat || 0, watchSourceLocation?.lon || 0],
			[watchDestinationLocation?.lat || 0, watchDestinationLocation?.lon || 0],
		],
	};

	const [center, setCenter] = useState(helpers.findAverageLocation(mapGroups.points));

	useEffect(() => {
		setCenter(helpers.findAverageLocation(mapGroups.points));
		form.setValue("source_info.shipping_info.location", [watchSourceLocation?.lat || 0, watchSourceLocation?.lon || 0])
		form.setValue("destination_info.shipping_info.location", [watchDestinationLocation?.lat || 0, watchDestinationLocation?.lon || 0])
	}, [watchSourceLocation, watchDestinationLocation]);

	return (
		<Container skeleton={["section:7"]}>
			<Form
				name="acrotransfer_jobs"
				i18n="acrotransfer_job_overview"
				mode="add"
				form={form}
				onSubmit={form.handleSubmit(onJobCreate)}
				sx={{ width: "100%" }}
			>
				<Section title={t("acrotransfer_jobs.map")} collapsible>
					<ResourcesMap groups={[mapGroups]} center={center} polyline zoom={3} />
				</Section>
				<OverviewForm form={form} mode="add" />
				<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", mt: 1 }}>
					<Button
						variant="contained"
						sx={{ mr: 1 }}
						onClick={handleTemplateSave}
					>
						{t("acrotransfer_jobs.save_template")}
					</Button>
					<Button variant="contained" type="submit">
						{t("acrotransfer_jobs.start_job")}
					</Button>
				</Box>
			</Form>
		</Container>
	);
};

export default JobsOverViewContainer;
