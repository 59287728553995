/**
 * In this list, `message` and `actions.label` just and placeholder
 * real message and labels are stored on the 
 *  locales/notifications ->
 *      messages ->
 *          `${templateName}.msg`  
 *          `${templateName}.action_label`  
 */

export default {
    "wizard.create_server": {
        severity: "info",
        priority: "low",
        actions: {
            type: "link",
            to: "/cloud_services/servers/create",
            label: "Continue"
        },
        events: {
            onCreate: () => {},
            onRemove: () => {},
            onRead: () => {}
        },
        message: "You have unfinished server creation process"
    }
}