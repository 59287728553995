import yup from "@acromove/utils/validators";

const isConnectionTypeC = (con, cab) => {
    if (con === "usb_c") {
        return true;
    }
    if (con !== "usb_c" && cab === "fibre") {
        return false;
    }
    return true;
}

export default (t) => ({
    connection_type: yup
        .string()
        .oneOf(["usb_c", "direct_network", "existing"])
        .required(t("acrotransfer_jobs.source_info.connection_info.connection_type.errors.required")),
    network_port: yup.string().when("connection_type", {
        is: "usb_c",
        then: yup.string().nullable(),
        otherwise: yup
            .string()
            .oneOf(["1GB", "10GB", "10GBSFP", "40GB", "link"])
            .required(t("acrotransfer_jobs.source_info.connection_info.network_port.errors.required")),
    }),
    network_type: yup.string().when("connection_type", {
        is: "usb_c",
        then: yup.string().nullable(),
        otherwise: yup
            .string()
            .oneOf(["dhcp", "static_ip"])
            .required(t("acrotransfer_jobs.source_info.connection_info.network_type.errors.required")),
    }),
    network_equipment: yup.mixed().when("connection_type", {
        is: "usb_c",
        then: yup.array().nullable(),
        otherwise: yup.array().of(
            yup.object().shape({
                brand: yup
                    .string()
                    .required(
                        t(
                            "acrotransfer_jobs.source_info.connection_info.network_equipment.brand.errors.required"
                        )
                    ),
                model: yup
                    .string()
                    .required(
                        t(
                            "acrotransfer_jobs.source_info.connection_info.network_equipment.model.errors.required"
                        )
                    ),
            })
        ),
    }),
    cabling: yup.string().when("connection_type", {
        is: "usb_c",
        then: yup.string().nullable(),
        otherwise: yup
            .string()
            .oneOf(["fibre", "direct_copper"])
            .required(t("acrotransfer_jobs.source_info.connection_info.cabling.errors.required")),
    }),
    transceivers: yup.mixed().when(["connection_type", "cabling"], {
        is: isConnectionTypeC,
        then: yup.array().nullable(),
        otherwise: yup.array().of(
            yup.object().shape({
                brand: yup
                    .string()
                    .required(
                        t("acrotransfer_jobs.source_info.connection_info.transceivers.brand.errors.required")
                    ),
                model: yup
                    .string()
                    .required(
                        t("acrotransfer_jobs.source_info.connection_info.transceivers.model.errors.required")
                    ),
            })
        ),
    }),
    optic_fibre: yup.mixed().when(["connection_type", "cabling"], {
        is: isConnectionTypeC,
        then: yup.array().nullable(),
        otherwise: yup.array().of(
            yup.object().shape({
                brand: yup
                    .string()
                    .required(
                        t("acrotransfer_jobs.source_info.connection_info.optic_fibre.brand.errors.required")
                    ),
                model: yup
                    .string()
                    .required(
                        t("acrotransfer_jobs.source_info.connection_info.optic_fibre.model.errors.required")
                    ),
            })
        ),
    }),
})