import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import ManagerJobsOverViewContainer from "containers/acrotransfer/manager/jobs/overview";

const AcroTransferCreatedOverviewPage = () => (
	<RoleSwitcher>
		<RoleSwitcher.Role value={["user:view_only","user"]}>
			<ManagerJobsOverViewContainer />
		</RoleSwitcher.Role>
	</RoleSwitcher>
);

export default AcroTransferCreatedOverviewPage;
