import React, { useState } from "react";
import PropTypes from "prop-types";

/* MUI */
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from "@mui/icons-material/Cancel";

/* Common Components */
import Select from "@acromove/components/form/select";
import Section from "components/layouts/dashboard/ui/section";
import Input from "@acromove/components/form/input";
import AvatarUploader from "@acromove/components/form/avatar-uploader";
import Form from "@acromove/components/form";
import Switch from "@acromove/components/form/switch";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

/* Redux */
import { useEditUserMutation } from "redux/clients/clients.api";

const UserDetails = (props) => {
	const { data, userKey } = props;
	const { t } = useTranslation("clients_users_info");

	const [editUser, { isLoading }] = useEditUserMutation({ key: userKey });
	const [editMode, setEditMode] = useState(false);

	const downMd = useMediaQuery((theme) => `${theme.breakpoints.down("md")}`);
	const schema = yup.object({
		firstName: yup.string().required(t("users.firstName.required")),
		lastName: yup.string().required(t("users.lastName.required")),
		username: yup.string().required(t("users.username.required")),
		role: yup.string().required(t("users.role.required")),
	});
	const form = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			firstName: data?.firstName,
			lastName: data?.lastName,
			// organization: data?.organization,
			// job_title: data?.job_title,
			// group: data?.group_name,
			notation: data?.notation,
			// device_notification: data?.settings?.device_notification,
			disabled: data?.disabled,
			role: data?.role,
			username: data?.username,
			avatar: data?.avatar,
		},
	});

	const { errors } = form.formState;

	const onSubmit = async (value) => {
		await editUser({ value, userKey });
	};

	const handleCancelEdit = () => {
		if (!editMode) {
			setEditMode(true);
		} else if (editMode) {
			form.reset(data);
			setEditMode(false);
		}
	};

	const handleSaveEdit = () => {
		if (editMode) {
			form.handleSubmit(onSubmit)();
			if (!Object.keys(errors).length) {
				setEditMode(!editMode);
			}
			return;
		}
		setEditMode(!editMode);
	};

	return (
		<Section
			collapsible
			title={t("user_title")}
			action={
				<Button onClick={handleCancelEdit} startIcon={editMode ? <CancelIcon /> : <BorderColorIcon />}>
					{editMode ? t("cancel_btn") : t("edit_btn")}
				</Button>
			}
		>
			<Form
				loading={isLoading}
				name="users"
				i18n="clients_users_info"
				form={form}
				disabled={!editMode}
				sx={{ display: "flex", flexDirection: "row", mt: 6 }}
			>
				<Grid
					container
					sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: downMd ? "wrap" : "nowrap" }}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={8}
						gap={4}
						sx={{ display: "flex", flexDirection: "column", flexWrap: "nowrap" }}
					>
						<Grid item xs={12} sm={12} md={12}>
							<Grid container gap={3} sx={{ flexWrap: "nowrap" }}>
								<Form.Field name="firstName">
									<Grid item xs={12} sm={12} md={6}>
										<Input size="medium" sx={{ width: "100%" }} />
									</Grid>
								</Form.Field>

								<Form.Field name="lastName">
									<Grid item xs={12} sm={12} md={6}>
										<Input size="medium" sx={{ width: "100%" }} />
									</Grid>
								</Form.Field>
							</Grid>
						</Grid>

						{/* <Form.Field name="organization">
								<Grid item xs={12} sm={12} md={12}>
									<Input
										size="medium"
										disabled={!editMode}
										sx={{ width: "100%" }}
									/>
								</Grid>
							</Form.Field>

							<Form.Field name="job_title">
								<Grid item xs={12} sm={12} md={12}>
									<Input
										size="medium"
										disabled={!editMode}
										sx={{ width: "100%" }}
									/>
								</Grid>
							</Form.Field> */}

						<Form.Field name="notation">
							<Grid item xs={12} sm={12} md={12}>
								<Select
									size="medium"
									sx={{ width: "100%" }}
									options={[
										{ label: "Metric", value: "metric" },
										{ label: "Imperial", value: "imperial" },
									]}
								/>
							</Grid>
						</Form.Field>

						<Form.Field name="disabled">
							<Grid item xs={12} sm={12} md={12}>
								<Switch />
							</Grid>
						</Form.Field>

						{/* <Form.Field name="device_notification">
								<Grid item xs={12} sm={12} md={12}>
									<Input size="medium" disabled={!editMode} sx={{ width: "100%" }} />
								</Grid>
							</Form.Field> */}

						<Grid item xs={12} sm={12} md={12}>
							<Grid container gap={3} sx={{ flexWrap: "nowrap" }}>
								<Form.Field name="group">
									<Grid item xs={12} sm={12} md={6}>
										<Input size="medium" sx={{ width: "100%" }} />
									</Grid>
								</Form.Field>

								<Form.Field name="role">
									<Grid item xs={12} sm={12} md={6}>
										<Select
											size="medium"
											sx={{ width: "100%" }}
											options={[
												{ label: "Super Admin", value: "super_admin" },
												{ label: "Admin", value: "admin" },
												{ label: "Accountant", value: "accountant" },
												{ label: "User", value: "user" },
											]}
										/>
									</Grid>
								</Form.Field>
							</Grid>
						</Grid>
						<Form.Field name="username">
							<Grid item xs={12} sm={12} md={12}>
								<Input size="medium" disabled sx={{ width: "100%" }} type="email" />
							</Grid>
						</Form.Field>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={4}
						sx={{ width: "100%", display: "flex", justifyContent: "center", pt: downMd && 2 }}
					>
						<Grid container>
							<Form.Field name="avatar">
								<Grid item sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
									<AvatarUploader
										direction="column"
										editText={t("users.avatar.edit_txt")}
										startIcon={<BorderColorIcon />}
										size={218}
										fontSize={24}
										alt=""
									/>
								</Grid>
							</Form.Field>
						</Grid>
					</Grid>
				</Grid>
			</Form>
			{editMode && (
				<Button
					startIcon={<SaveAsIcon />}
					onClick={handleSaveEdit}
					variant="contained"
					sx={{ marginLeft: "auto", mt: 1, display: "flex", alignSelf: "flex-end" }}
				>
					{t("save_btn")}
				</Button>
			)}
		</Section>
	);
};

UserDetails.propTypes = {
	data: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		// organization: PropTypes.string,
		// job_title: PropTypes.string,
		// group_name: PropTypes.string,
		role: PropTypes.string,
		username: PropTypes.string,
		avatar: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.shape({}), PropTypes.oneOf([null])]),
		notation: PropTypes.string,
		// settings: PropTypes.shape({
		// 	device_notification: PropTypes.string,
		// }),
		disabled: PropTypes.bool,
	}),
	userKey: PropTypes.string.isRequired,
	// editMode: PropTypes.bool.isRequired,
	// saveButton: PropTypes.bool,
};

UserDetails.defaultProps = {
	data: {
		firstName: "",
		lastName: "",
		organization: "",
		job_title: "",
		group_name: "",
		role: "",
		username: "",
		avatar: "",
		notation: "",
		// settings: {},
		// device_notif: "",
		disabled: false,
	},
	// saveButton: false,
};

export default UserDetails;
