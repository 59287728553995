import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useGetInfoQuery } from "@acromove/redux/assets/assets.api";
import { Blurhash, BlurhashCanvas } from "react-blurhash";
import { Box } from "@mui/material";
import NoImage from "./defaultAssets/no-img.jpg";
import FrontImage from "./front-image";

const S3_ENDPOINT = process.env.REACT_APP_S3_ENDPOINT
	? `${process.env.REACT_APP_S3_ENDPOINT}/`
	: "http://localhost:8887/bucket/acrocloud/";

const SIZES_FALLBACK = {
	thumbnail: 4,
	small: 3,
	medium: 2,
	large: 1,
	original: 0,
};

const ImageAsset = (props) => {
	const { asset, size = "medium", sx, ...rest } = props;
	const [currentImg, setCurrentImg] = useState(null);
	const [noImg, setNoImg] = useState(false);
	const { filename = null, blurhush = null } = asset || {};
	const { data, isLoading, isFetching } = useGetInfoQuery({ filename });
	const loading = useMemo(
		() => isLoading || isFetching,
		[isLoading, isFetching]
	);

	const getSizedImage = (needleSize) => {
		if (needleSize === "original") {
			return {
				filename: data.filename,
				height: data.formats.height,
				size: data.size,
				url: data.url,
				width: data.formats.width,
				imageSize: needleSize,
			};
		}
		return {
			...data.formats[needleSize],
			imageSize: needleSize,
		};
	};

	const handleError = () => {
		const nextSize = Object.entries(SIZES_FALLBACK)
			.filter((s) => s.at(1) > SIZES_FALLBACK[currentImg.imageSize])
			.sort((a, b) => a.at(1) - b.at(1))
			.at(0)
			.at(0);
		if (!nextSize) {
			return setNoImg(true);
		}
		return setCurrentImg(getSizedImage(nextSize));
	};

	useEffect(() => {
		if (data && size) {
			setCurrentImg(getSizedImage(size));
		}
	}, [data, size]);

	return (
		<Box sx={{ ...sx, overflow: "hidden" }}>
			{asset instanceof File && <FrontImage asset={asset} {...rest} />}
			{blurhush && loading && (
				<Blurhash
					hash={blurhush}
					width="100%"
					height={sx.height}
					resolutionX={32}
					punch={1}
				/>
			)}
			{currentImg && !loading && (
				<Box
					sx={{
						width: "100%",
						height: sx.height,
						position: "relative",
						"& canvas": { width: "100%", height: "100%" },
					}}
				>
					{blurhush && (
						<BlurhashCanvas hash={blurhush} width={400} punch={1} />
					)}
					<img
						key={currentImg.filename}
						onError={handleError}
						src={
							asset instanceof File
								? URL.createObjectURL(asset)
								: `${S3_ENDPOINT}${currentImg.filename}`
						}
						alt={data.original_name}
						style={{
							width: "100%",
							height: "100%",
							zIndex: 10,
							objectFit: "contain",
							objectPosition: "center",
							position: "absolute",
							top: 0,
							left: 0,
						}}
						{...rest}
					/>
				</Box>
			)}
			{(!currentImg && !(asset instanceof File)) ||
				(noImg && (
					<img
						src={NoImage}
						width={sx.width}
						height={sx.height}
						alt="not-found"
					/>
				))}
		</Box>
	);
};

ImageAsset.propTypes = {
	asset: PropTypes.shape({
		filename: PropTypes.string,
		blurhush: PropTypes.string,
	}).isRequired,
	size: PropTypes.string,
	sx: PropTypes.shape({
		width: PropTypes.string,
		height: PropTypes.string,
	}),
};

ImageAsset.defaultProps = {
	size: "medium",
	sx: {},
};

export default ImageAsset;
