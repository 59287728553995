import React, { useState, useEffect } from "react";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Common Components */
import Table from "@acromove/components/table";
import { inventoryList } from "components/table-columns/admin/inventory";
import Search from "@acromove/components/form/search";

/* Utils */
import useItemsTranslation from "@acromove/hooks/use-items-translation";
import { useTranslation } from "react-i18next";

/* Mock */
import mock from "mock/acrotransfer-jobs";


const loadData = (limit, offset) =>
	new Promise((resolve) => {
		setTimeout(() => {
			resolve(mock.inventory.slice(offset, offset + limit));
		}, 1000);
	});


const InventoryContainer = () => {
	const [loading, setLoading] = useState(true);
	const [limit, setLimit] = useState(5);
	const [offset, setOffset] = useState(0);
	const [data, setData] = useState([]);

	const {t} = useTranslation("acrotransfer_admin")
	const itemsTranslator = useItemsTranslation("acrotransfer_jobs");

	const fetchData = async () => {
		setLoading(true);
		const loadedData = await loadData(limit, offset);
		setData(loadedData);
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, [offset, limit]);

	const handlePageChange = (_, page) => {
		setOffset(page * limit);
	};

	const handlePageSizeChange = ({ target: { value: size } }) => setLimit(size);

	return (
		<Container>
			<Table
				collapsible
				title={t("inventory")}
				columns={itemsTranslator(inventoryList, "inventory_table.columns", "accessor", "Header")}
				data={data}
				pageSize={limit}
				total={mock.inventory.length}
				page={parseInt(offset / limit, 10) + 1}
				onPageSizeChange={handlePageSizeChange}
				sortDer="desc"
				loading={loading}
				onPageChange={handlePageChange}
                action={
					<Search />
				}
			/>
		</Container>
	);
};

export default InventoryContainer;
