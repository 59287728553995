import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";

/* Components */
import ItemWithSub from "./nav-item-with-sub"
import { Scrollbars } from 'react-custom-scrollbars';

/* Utils */
import { Link, useLocation } from "react-router-dom";
import {useTranslation} from "react-i18next"
import useAuth from "@acromove/authentication/hooks/use-auth";

/* Assets */
import acromove from "assets/images/acromove.png";

const DrawerContent = ({ items = [] }) => {
	const location = useLocation();
	const {t} = useTranslation("router");
	const {isAllowed} = useAuth()
	const theme = useTheme()

	const clearItems = (links) => {
		const formatted = links.filter(link => isAllowed(`pages:${link.accessSlug}`))
		for (const item of formatted) {
			item.sub = item.sub.filter(link => isAllowed(`pages:${link.accessSlug}`))
		}
		return formatted;
	}



	return (
		<div
			style={{
				overflowY: "hidden",
				height: "100%",
				position: "relative"
			}}
		>
			<Toolbar sx={{ minHeight: {md: "52px"}, position: "sticky" }}>
				<img style={{margin: "0 auto"}} height="36" src={theme.assets.logo} alt="logo" />
			</Toolbar>

			<Divider sx={{ borderColor: "sideMenu.divider" }} />

			<List sx={{
				marginLeft: "13px",
				height: "calc(100% - 100px)",
				".MuiListItemButton-root": {
					color: "background.paper"
				}
            }}>
				<Scrollbars>
				{clearItems(items)
					.filter(item => !item?.ignoreInNav)
					.map((item) => item?.sub?.length ? <ItemWithSub key={item.path} item={item}/> : (
					<ListItemButton
						key={item.path}
						selected={location.pathname === item.path}
						component={Link}
						to={item.path}
					>
						{item.icon && (
							<ListItemIcon>
								<item.icon fontSize="medium" />
							</ListItemIcon>
						)}
						<ListItemText sx={{
                            fontSize: "medium",
							color: location.pathname.startsWith(item.path) ? "text.active" : "sideMenu.text",
                        }} primary={t(item.path)} />
					</ListItemButton>
				))}
			</Scrollbars>
			</List>

			<Box 
				display="flex" 
				flexDirection="row" 
				justifyContent="center" 
				alignItems="center"
				sx={{
					position: "absolute",
					minHeight: "50px",
					bottom: "0",
					width: "100%",
					backgroundColor: "background.white",
					gap: 1,
					zIndex: 10,
				}}
				>
				<Typography fontSize="14" color="text.secondary" fontWeight="500">Powered By</Typography>
				<a href="https://acromove.com" target="_blank" rel="noreferrer" style={{display: "flex", justifyContent:"center", alignItems: "center"}}>
					<img width="137" height="25" src={acromove} alt="acromove logo" />
				</a>
			</Box>

		</div>
	);
};

DrawerContent.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		})
	),
};

DrawerContent.defaultProps = {
	items: [],
};

export default DrawerContent;
