import React from "react";

/* MUI */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, Typography } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';

/* Components */
import Input from "@acromove/components/form/input";

/* Utils */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

/* Assets */

const ReceiveDeviceInfo = () => {
	const { t } = useTranslation("acrotransfer_jobs");

	const schema = yup.object({});

	const { control } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {},
	});

	return (
		<Box
			component="form"
			display="flex"
			flexDirection="column"
			sx={{
				gap: 4,
				width: "100%",
			}}
		>
			<Grid container gap={2} sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
				<Grid item md={12} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
					<Typography width="11%" fontWeight={600}>{t("job_information.recieve_device.shipping_company.label")}</Typography>
					<Input
						sx={{ width: "30%", ml: 4}}
						size="medium"
						control={control}
						name="job_name"
						label={t("job_information.recieve_device.shipping_company.label")}
						placeholder={t("job_information.recieve_device.shipping_company.placeholder")}
					/>
				</Grid>
				<Grid item md={12} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Typography fontWeight={600}>{t("job_information.recieve_device.shipping_docs")}</Typography>
                    <Button endIcon={<DownloadIcon />} sx={{ ml: 4}} variant="contained">{t("job_information.download")}</Button>
                </Grid>
				<Grid item md={12} sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography  width="11%" fontWeight={600}>{t("job_information.recieve_device.tracking.label")}</Typography>
					<Input
						sx={{ width: "100%", ml: 4 }}
						size="medium"
						control={control}
						name="job_name"
						label={t("job_information.recieve_device.tracking.label")}
						placeholder={t("job_information.recieve_device.tracking.placeholder")}
					/>
                </Grid>
			</Grid>
		</Box>
	);
};

export default ReceiveDeviceInfo;
