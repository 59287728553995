import * as React from "react";

/* MUI */
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";

/* Utils */
import moment from "moment";
import { useTranslation } from "react-i18next";
import {useStep} from "@acromove/components/wizard"

const StaticDateRangePickerDemo = () => {
	const { t } = useTranslation("acrotransfer_create_job");
	const wizard = useStep();

	React.useEffect(() => {
		document.querySelector(".MuiPickerStaticWrapper-content > div").style.display = "flex";
		document.querySelector(".MuiPickerStaticWrapper-content > div").style.justifyContent = "center";
	}, []);

	const getDays = () => {
		let dataSize = wizard.state.stepsData?.data_volume?.data_size || 100;
		const { amount = "tb" } = wizard.state.stepsData?.data_volume || {};
		let days = 2;
		if (amount === "pb") {
			dataSize *= 1024;
		}

		if (dataSize <= 200) days = 2;
		else days = Math.ceil(dataSize / 100);
		return days;
	};

	return (
		<Box sx={{ flexDirection: "column", display: "flex", width: "calc(100% - 16px)", mr: 2 }}>
			<Typography fontWeight={600} fontSize={20}>
				{t("main_desc")}
			</Typography>
			<Typography fontWeight={500} fontSize={18} sx={{ my: 2 }} color="text.secondary">
				{t("main_desc_ext")}
			</Typography>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<StaticDateRangePicker
					disabled
					calendars={1}
					displayStaticWrapperAs="desktop"
					readOnly
					showToolbar={false}
					value={[
						wizard.state.stepsData?.time_frame?.date || new Date(),
						moment(wizard.state.stepsData?.time_frame?.date || new Date())
							.add(getDays(), "days")
							.toDate(),
					]}
					renderInput={(startProps, endProps) => (
						<>
							<TextField {...startProps} />
							<Box sx={{ mx: 2 }}> to </Box>
							<TextField {...endProps} />
						</>
					)}
				/>
			</LocalizationProvider>
		</Box>
	);
};
export default StaticDateRangePickerDemo;
