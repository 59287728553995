import React, { useState } from "react";

/* MUI */
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';

/* Utils */
import {Link} from "react-router-dom";
import helpers from "general/helpers";
import useAuth from "@acromove/authentication/hooks/use-auth";
import useImage from "@acromove/hooks/use-image";

const UserMiniProfile = () => {
    const auth = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
	const {url: avatarUrl} = useImage(auth?.user?.avatar)

    const handleClickProfile = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseProfile = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton onClick={handleClickProfile}>
				<Avatar
					sx={{ width: 24, height: 24 }}
					alt={`${auth.user.firstname} ${auth.user.lastname}`}
					src={avatarUrl}
				>
					{auth.user.firstname && auth.user.lastname
						? helpers.getInitials(auth.user.firstname, auth.user.lastname)
						: ""}
				</Avatar>
			</IconButton>
			<Popover
				id={anchorEl ? "simple-popover" : undefined}
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleCloseProfile}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<List>
					<ListItem button component={Link} to="/user/profile">
						<ListItemIcon>
							<PermIdentityIcon />
						</ListItemIcon>
						<ListItemText primary="Profile" />
					</ListItem>
					<Divider />
					<ListItem button onClick={auth.logout}>
						<ListItemIcon>
							<LogoutIcon />
						</ListItemIcon>
						<ListItemText primary="Logout" />
					</ListItem>
				</List>
			</Popover>
		</>
	);
};

export default UserMiniProfile;
