import React from "react";
import PropTypes from "prop-types";

/* MUI */
import { Typography, Box, Grid, alpha } from "@mui/material";
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "../styles"

const TransferItem = (props) => {
	const { text, img, title, order } = props;
	const isDownMd = useMediaQuery((theme) => `${theme.breakpoints.down("md")}`);
	const theme = useTheme()

	return (
		<Box sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection:isDownMd ? "column" : "row", mb: 4, height: isDownMd ? "auto" : "400px" }}>
			<Grid container gap={isDownMd ? 3 : 0}>
				{isDownMd && <Typography sx={[styles.subTitles, {margin: "0 auto"}]}>{title}</Typography>}
				<Grid
					item
					md={4}
					sm={12}
					sx={{ backgroundColor: alpha(theme.palette.primary.light , 0.3), minHeight: "300px", overflow: "hidden" , display: "flex", alignItems: "flex-end", justifyContent: "center", order: isDownMd ? 1 : order ==="reverse" ? 2 : 1 }}
				>
					<img src={img} width={400} alt={title} />
				</Grid>
				<Grid item md={8} sm={12} sx={{px: isDownMd ? "" : "75px", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", order: isDownMd ? 2 : order ==="reverse" ? 1 : 2}}>
					{!isDownMd && <Typography sx={styles.subTitles}>{title}</Typography>}
					{text}
				</Grid>
			</Grid>
		</Box>
	);
};

TransferItem.propTypes = {
	text: PropTypes.shape({}).isRequired,
	title: PropTypes.string.isRequired,
	img: PropTypes.node.isRequired,
	order: PropTypes.string
};

TransferItem.defaultProps = {
	order: "normal"
}

export default TransferItem;
