import React, { useEffect } from "react";

/* MUI */
import Grid from "@mui/material/Grid";

/* Components */
import Wizard, { useStep } from "@acromove/components/wizard";
import LeftContent from "components/content/acrotransfer/wizard-steps/shared/left-cabling";
import CablingForm from "components/content/acrotransfer/wizard-steps/generic-forms/cabling";

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const DestinationCabling = () => {
	const wizard = useStep();
	const { t } = useTranslation("acrotransfer_create_job", { keyPrefix: "steps.destination_cabling" });

	const defValues = wizard.state.stepsData?.destination_cabling || {
		transceivers_cables: [{ model: "", brand: "" }],
		fiber_cables: [{ model: "", brand: "" }],
		cabling: "direct_copper",
	};

	const { control, handleSubmit, watch, getValues, reset } = useForm({
		defaultValues: defValues,
	});

	const watchCabling = watch("cabling");

	useEffect(() => {
		wizard.actions.setStepData("destination_cabling", getValues());
	}, [watchCabling]);

	useEffect(() => {
		reset(wizard.state.stepsData?.destination_cabling)
	}, [wizard.state.stepsData?.destination_cabling])

	const onSubmit = (data) => {
		wizard.actions.setStepData("destination_cabling", data);
	};

	const handleNext = () => {
		handleSubmit(onSubmit)();
		return true;
	};

	return (
		<Wizard.Step slug="destination_cabling" label={t("label")} pos={11}>
			<Grid container>
				<Grid item md={4}>
					<LeftContent selected={watchCabling} />
				</Grid>
				<Grid item md={4}>
					<Wizard.Bar>
						<CablingForm onSubmit={handleSubmit(onSubmit)} keyPrefix ="steps.destination_cabling" control={control} watchCabling={watchCabling}/>
						<Wizard.Tools beforeNext={handleNext} />
					</Wizard.Bar>
				</Grid>
			</Grid>
		</Wizard.Step>
	);
};

export default DestinationCabling;
