import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

/**
 * Step Icon.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Step Icon component.
 */
const StepIcon = ({ active, icon }) => (
	<Box
		sx={{
			width: "44px",
			height: "44px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center", 
			borderRadius: 1,
			bgcolor: "background.gray",
			color: active ? "text.active" : "text.grayLight",
			ml: -1,
		}}
	>
		{icon}
	</Box>
);

/**
 * Wizard bar Properties.
 *
 * @typedef {object} Props
 * @property {true | false} [active = "false"] - Property to define if current step is active or not.
 * @property {React.ReactNode} icon - Icon.
 */

StepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool.isRequired,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node.isRequired,
};

export default StepIcon;
