import React from "react";
import PropTypes from "prop-types";

/* MUI */
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

/* Utils */
import _ from "lodash";

/**
 * Table Header Cell component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Table Header Cell.
 */
const HeaderCell = (props) => {
    const {column, isActive, deriction} = props;

    if(column.Header === "__empty"){
        return <TableCell {...column.getHeaderProps()} sx={{ m: 0, p: 0, ..._.pick(column, ['minWidth', 'width', 'maxWidth']) }} />
    }

    return(
        <TableCell {...column.getHeaderProps()} sx={{p: 0, textAlign: "left", ..._.pick(column, ['minWidth', 'width', 'maxWidth'])}}>
            
                <Button
                    disabled={!column?.sortable}
                    sx={{
                        color: isActive ? "text.active" : "text.secondary", 
                        textAlign: "left",
                        "&:disabled": {
                            color: "text.secondary", 
                        }
                    }}
                    endIcon={
                        isActive &&
                        (deriction === "desc" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />)
                    }
                >   
                    {column.render("Header")}
                </Button>
            
            
        </TableCell>
    );
}

/**
 * Header Cell Properties.
 *
 * @typedef {object} Props
 * @property {object} column.
 * @property {string} column.Header.
 * @property {function} column.getHeaderProps.
 * @property {true | false} column.sortable.
 * @property {function} column.render.
 * @property {true | false} isActive.
 * @property {"desc", "asc"} deriction.
 */

HeaderCell.propTypes = {
    column: PropTypes.shape({
        Header: PropTypes.string,
        getHeaderProps: PropTypes.func,
        sortable: PropTypes.bool,
        render: PropTypes.func
    }).isRequired,
    isActive: PropTypes.bool.isRequired,
    deriction: PropTypes.oneOf(["desc", "asc"]).isRequired,
}


export default HeaderCell;