/* eslint-disable */
import React from 'react'
import TableIconLink from "@acromove/components/table/cells/table-icon-link";
import DataGridIconLink from "@acromove/components/data-grid/cells/datagrid-icon-link";
import DataGridProgress from "@acromove/components/data-grid/cells/datagrid-progress";
import DataGridTooltip from "@acromove/components/data-grid/cells/datagrid-tooltip";
import ArrowRightIcon from "components/icons/arrow-right";



export const jobList = [
	{
		headerName: "name",
		field: "name",
		sortable: true,
		minWidth: 200,
		renderCell: (props) => {
			const {row} = props
			return(
				<DataGridTooltip value={row?.name} tooltipValue={row?.description} />
			)
		}
	},
	{
		headerName: "Progress",
		field: "active_step",
		minWidth: 200,
		renderCell: DataGridProgress,
	},
	{
		headerName: "source",
		field: "source",
		minWidth: 250,
		sortable: false
	},
	{
		headerName: "destination",
		field: "destination",
		minWidth: 250,
		sortable: false
	},
	{
		headerName: "start_date",
		field: "start_date",
		minWidth: 150,
		sortable: false
	},
	{
		headerName: "url",
		field: "url",
		sortable: false,
		flex: 0.5,
		align: "right",
		renderCell: DataGridIconLink,
	},
];

export const deviceList = [
	{
		Header: "serial",
		accessor: "serial",
	},
	{
		Header: "state",
		accessor: "state",
	},
	{
		Header: "region",
		accessor: "region",
	},
	{
		Header: "availability",
		accessor: "availability",
	},
	{
		Header: "capacity",
		accessor: "capacity",
	},
	{
		Header: "__empty",
		accessor: "id",
		width: "40%",
		Cell: (
			props // eslint-disable-line
		) => (
			<TableIconLink
				sx={{ alignSelf: "flex-end" }}
				icon={<ArrowRightIcon color="primary" />}
				baseUrl="/devices/view"
				value={`${props.value}-${props.row.original.name.replace(/ /g, "_")}`} // eslint-disable-line
			/>
		),
	},
];

export const jobHistoryList = [
	{
		headerName: "Name",
		field: "name",
		sortable: true,
		minWidth: 200,
		renderCell: (props) => {
			const {row} = props
			return(
				<DataGridTooltip value={row?.name} tooltipValue={row?.description} />
			)
		}
	},
	{
		headerName: "data_kind",
		field: "data_kind",
		minWidth: 100,
		sortable: false
	},
	{
		headerName: "data_size",
		field: "data_size",
		minWidth: 100,
		sortable: false
	},
	{
		headerName: "start_date",
		field: "start_date",
		minWidth: 100,
		sortable: false
	},
	{
		headerName: "end_date",
		field: "end_date",
		minWidth: 100,
		sortable: false
	},
	{
		headerName: "source",
		field: "source",
		minWidth: 250,
		sortable: false
	},
	{
		headerName: "destination",
		field: "destination",
		minWidth: 250,
		sortable: false
	},
	{
		headerName: "url",
		field: "url",
		sortable: false,
		flex: 0.5,
		align: "right",
		renderCell: DataGridIconLink,
	},
];


export default {
    jobList,
    deviceList,
    jobHistoryList
}