import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from '@mui/material/Tooltip'

/**
 * Table Tooltip Cell component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Table Tooltip Cell Component.
 */
const DataGridTooltip = (props) => {
	const { value, tooltipValue } = props;

	return (
		<Box>
            <Tooltip title={tooltipValue} arrow>
                <Typography fontSize="0.875rem">
                    {value}
                </Typography>
            </Tooltip>    
        </Box>
	);
};

/**
 * Table Tooltip Cell Properties.
 *
 * @typedef {object} Props
 * @property {string} tooltipValue - Value contained by Tooltip.
 * @property {string} value - Value.
 */

DataGridTooltip.propTypes = {
	tooltipValue: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};


export default DataGridTooltip;
