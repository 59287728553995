import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import ClientUsersInfoContainer from "containers/acrotransfer/admin/clients/user-info";

const  ClientsUserViewPage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={["admin","admin:owner", "acromove_admin", "acromove_admin:owner"]}>
            <ClientUsersInfoContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default ClientsUserViewPage
