import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";

/* Components */
import Select from "@acromove/components/form/select";
import Form from "@acromove/components/form";

/* Utils */
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import CableBrandModelField from "components/content/acrotransfer/wizard-steps/shared/cable-brand-model-field";

const EquipmentInfo = (props) => {
	const { connection, cabling, prefix, form, mode } = props;
	const { t } = useTranslation("acrotransfer_job_overview", { keyPrefix: "acrotransfer_jobs" });

	return (
		<Grid
			container
			gap={2}
			sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between" }}
		>
			<Grid item md={5}>
				<Grid container gap={2} sx={{ display: "flex", flexDirection: "column" }}>
					<Grid item md={12}>
						<Typography fontWeight={500} fontSize={20} sx={{ mb: 2 }}>
							{t(`${prefix}.connection_info.connection_type.label`)}
						</Typography>
						<Form.Field name={`${prefix}.connection_info.connection_type`}>
							<Select
								sx={{ width: "100%" }}
								options={[
									{
										label: t(`${prefix}.connection_info.connection_type.options.existing`),
										value: "existing",
									},
									{
										label: t(`${prefix}.connection_info.connection_type.options.direct_network`),
										value: "direct_network",
									},
									{
										label: t(`${prefix}.connection_info.connection_type.options.usb-c`),
										value: "usb_c",
									},
								]}
							/>
						</Form.Field>
					</Grid>

					{connection !== "usb_c" && (
						<>
							<Grid item md={12}>
								<Typography fontWeight={500} fontSize={20} sx={{ mb: 2 }}>
									{t(`${prefix}.connection_info.network_port.label`)}
								</Typography>
								<Form.Field name={`${prefix}.connection_info.network_port`}>
									<Select
										sx={{ width: "100%" }}
										options={[
											{
												label: t(`${prefix}.connection_info.network_port.options.10GBSFP`),
												value: "10GBSFP",
											},
											{
												label: t(`${prefix}.connection_info.network_port.options.40GB`),
												value: "40GB",
											},
											{
												label: t(`${prefix}.connection_info.network_port.options.1GB`),
												value: "1GB",
											},
											{
												label: t(`${prefix}.connection_info.network_port.options.10GB`),
												value: "10GB",
											},
											{
												label: t(`${prefix}.connection_info.network_port.options.link`),
												value: "link",
											},
										]}
									/>
								</Form.Field>
							</Grid>
							<Grid item md={12}>
								<CableBrandModelField
									localePath="acrotransfer_jobs"
									control={form}
									name={`${prefix}.connection_info.network_equipment`}
									locale="acrotransfer_job_overview"
									mode={mode}
								/>
							</Grid>
							<Grid item md={12}>
								<Form.Field name={`${prefix}.connection_info.network_type`}>
									<Select
										sx={{ width: "100%" }}
										options={[
											{
												label: t(`${prefix}.connection_info.network_type.options.dhcp`),
												value: "dhcp",
											},
											{
												label: t(`${prefix}.connection_info.network_type.options.static_ip`),
												value: "static_ip",
											},
										]}
									/>
								</Form.Field>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			{connection !== "usb_c" && (
				<Grid item md={5}>
					<Grid container gap={2} sx={{ display: "flex", flexDirection: "column" }}>
						<Grid item md={12}>
							<Typography fontWeight={500} fontSize={20} sx={{ mb: 2 }}>
								{t(`${prefix}.connection_info.cabling.label`)}
							</Typography>
							<Form.Field name={`${prefix}.connection_info.cabling`}>
								<Select
									sx={{ width: "100%" }}
									options={[
										{
											label: t(`${prefix}.connection_info.cabling.options.fibre`),
											value: "fibre",
										},
										{
											label: t(`${prefix}.connection_info.cabling.options.direct_copper`),
											value: "direct_copper",
										},
									]}
								/>
							</Form.Field>
						</Grid>
						{cabling === "fibre" && (
							<>
								<Grid item md={12}>
									<CableBrandModelField
										localePath="acrotransfer_jobs"
										control={form}
										name={`${prefix}.connection_info.transceivers`}
										locale="acrotransfer_job_overview"
										mode={mode}
									/>
								</Grid>
								<Grid item md={12}>
									<CableBrandModelField
										localePath="acrotransfer_jobs"
										control={form}
										name={`${prefix}.connection_info.optic_fibre`}
										locale="acrotransfer_job_overview"
										mode={mode}
									/>
								</Grid>
							</>
						)}
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

EquipmentInfo.propTypes = {
	connection: PropTypes.string,
	cabling: PropTypes.string,
	prefix: PropTypes.string.isRequired,
	form: PropTypes.shape({}).isRequired,
	mode: PropTypes.string.isRequired
};

EquipmentInfo.defaultProps = {
	connection: "",
	cabling: ""
}

export default EquipmentInfo;
