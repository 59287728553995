import SmartRouter from "@acromove/utils/smart-router";

/* Layouts */
import DashboardLayout from "components/layouts/dashboard";

/* Pages */
import AcroTransferDashboardPage from "pages/acrotransfer/dashboard";
import AcroTransferJobsPage from "pages/acrotransfer/jobs";
import AcroTransferCreatePage from "pages/acrotransfer/jobs/create";
import AcroTransferCreatedOverviewPage from "pages/acrotransfer/jobs/create/overview";
import AcroTransferJobsViewPage from "pages/acrotransfer/jobs_history/view";
// import AcroTransferDevicesViewPage from "pages/acrotransfer/devices/view";
// import AcroTransferSecurityPage from "pages/acrotransfer/security";
// import AcroTransferSecurityViewPage from "pages/acrotransfer/security/view";
import AcroTransferInventoryPage from "pages/acrotransfer/inventory";
// import AcroTransferSitesPage from "pages/acrotransfer/sites";
// import AcroTransferSitesViewPage from "pages/acrotransfer/sites/view";
// import AcroTransferOperatorsPage from "pages/acrotransfer/transfer_operators";
// import AcroTransferOperatorsViewPage from "pages/acrotransfer/transfer_operators/view";
// import AcroTransferHandlersPage from "pages/acrotransfer/shipping_handlers";
// import AcroTransferHandlersViewPage from "pages/acrotransfer/shipping_handlers/view";
import AcroTransferJobHealthCheckPage from "pages/acrotransfer/jobs/view/health_check";
import AcroTransferTemplatePage from "pages/acrotransfer/jobs_templates";
import AcroTransferTemplateViewPage from "pages/acrotransfer/jobs_templates/view";
import ClientsGroupsPage from "pages/acrotransfer/clients";
import ClientsCreateGroupPage from "pages/acrotransfer/clients/create";
import ClientsViewGroupPage from "pages/acrotransfer/clients/view-group";
import ClientsViewUserPage from "pages/acrotransfer/clients/view-user";

/* Icons */
import PcDevice from "components/icons/pc-device";

const router = new SmartRouter();

router.addRoute("/acrotransfer", {
    title: "acrotransfer",
    Container: AcroTransferDashboardPage, 
    Layout: DashboardLayout, 
    accessSlug: "transfer-dashboard", 
})

router.addRoute("/jobs_history", {
    title: "Jobs",
    Container: AcroTransferJobsPage,
    Layout: DashboardLayout, 
    accessSlug: "jobs-history", 
    icon: PcDevice,
})

router.addRoute("/jobs/create", {
    title: "Jobs",
    Container: AcroTransferCreatePage,
    Layout: DashboardLayout, 
    accessSlug: "job_create", 
    icon: PcDevice,
    ignoreOnNav: true,
})

router.addRoute("/jobs/create/overview", {
    title: "Job Overview",
    Container: AcroTransferCreatedOverviewPage,
    Layout: DashboardLayout, 
    accessSlug: "job_overview", 
    ignoreOnNav: true,
})




router.addRedirect("/jobs_history/view/", "/jobs_history")
router.addRedirect("/jobs/view/", "/acrotransfer")
router.addRedirect("/jobs", "/acrotransfer")



router.addRoute("/jobs_history/view/:jobId", {
    title: "Jobs",
    Container: AcroTransferJobsViewPage,
    Layout: DashboardLayout, 
    accessSlug: "job-history_view", 
    icon: PcDevice,
    ignoreOnNav: true,
})

router.addRoute("/jobs/view/:jobId", {
    title: "Jobs",
    Container: AcroTransferJobsViewPage,
    Layout: DashboardLayout, 
    accessSlug: "job_view", 
    icon: PcDevice,
    ignoreOnNav: true,
})

router.addRoute("/jobs/view/:jobId/health_check", {
    title: "Jobs",
    Container: AcroTransferJobHealthCheckPage,
    Layout: DashboardLayout, 
    accessSlug: "job_view_health-check", 
    icon: PcDevice,
    ignoreOnNav: true,
})

router.addRoute("/jobs_templates", {
    title: "Jobs Templates",
    Container: AcroTransferTemplatePage,
    Layout: DashboardLayout, 
    accessSlug: "job-templates", 
    icon: PcDevice,
})

router.addRoute("/jobs_templates/view/:templateId", {
    title: "Job Template",
    Container: AcroTransferTemplateViewPage,
    Layout: DashboardLayout, 
    accessSlug: "job-templates_view", 
    icon: PcDevice,
    ignoreOnNav: true,
})


// router.addRedirect("/devices/view/", "/jobs_history")

// router.addRedirect("/devices", "/jobs_history")

// router.addRoute("/devices/view/:deviceId", {
//     title: "Device",
//     Container: AcroTransferDevicesViewPage,
//     Layout: DashboardLayout, 
//     accessSlug: "acrotransfer_device_view", 
//     icon: PcDevice,
//     ignoreOnNav: true,
// })

// router.addRoute("/security", {
//     title: "Security",
//     Container: AcroTransferSecurityPage,
//     Layout: DashboardLayout, 
//     accessSlug: "security", 
//     icon: PcDevice, 
// })

// router.addRedirect("/security/view", "/security")

// router.addRoute("/security/view/:jobId", {
//     title: "Security View",
//     Container: AcroTransferSecurityViewPage,
//     Layout: DashboardLayout, 
//     accessSlug: "security_view", 
//     childOf: "/acrotransfer",
//     icon: PcDevice, 
//     ignoreOnNav: true,
// })

router.addRoute("/inventory", {
    title: "Inventory",
    Container: AcroTransferInventoryPage,
    Layout: DashboardLayout, 
    accessSlug: "inventory", 
    icon: PcDevice,
})

// router.addRoute("/sites", {
//     title: "Sites",
//     Container: AcroTransferSitesPage,
//     Layout: DashboardLayout, 
//     accessSlug: "acrotransfer_sites", 
// })

// router.addRoute("/sites/view/:siteId", {
//     title: "Site View",
//     Container: AcroTransferSitesViewPage,
//     Layout: DashboardLayout, 
//     accessSlug: "acrotransfer_sites_view", 
//     icon: PcDevice,
//     ignoreOnNav: true,
//     role: ["super_admin", "admin",]
// })

// router.addRoute("/sites/transfer_operators", {
//     title: "Transfer Operators",
//     Container: AcroTransferOperatorsPage,
//     Layout: DashboardLayout, 
//     accessSlug: "transfer-operators", 
//     childOf: "/sites",
//     icon: PcDevice,
// })


// router.addRoute("/sites/transfer_operators/view/:operatorId", {
//     title: "Transfer Operator View",
//     Container: AcroTransferOperatorsViewPage,
//     Layout: DashboardLayout, 
//     accessSlug: "transfer-operators_view", 
//     childOf: "/sites",
//     icon: PcDevice,
//     ignoreOnNav: true,
// })

// router.addRoute("/sites/shipping_handlers", {
//     title: "Shipping Handlers",
//     Container: AcroTransferHandlersPage,
//     Layout: DashboardLayout, 
//     accessSlug: "shipping-handlers", 
//     childOf: "/sites",
//     icon: PcDevice,
// })


// router.addRoute("/sites/shipping_handlers/view/:handlerId", {
//     title: "Shipping Handler",
//     Container: AcroTransferHandlersViewPage,
//     Layout: DashboardLayout, 
//     accessSlug: "shipping-handlers_view", 
//     childOf: "/sites",
//     icon: PcDevice,
//     ignoreOnNav: true,
// })

router.addRoute("/clients", {
    title: "Clients",
    Layout: DashboardLayout, 
    accessSlug: "clients",
    Container: ClientsGroupsPage,
    icon: PcDevice,
})

router.addRedirect("/clients/users/view", "/clients")

router.addRoute("/clients/users/view/:userId", {
    title: "View User",
    Container: ClientsViewUserPage,
    Layout: DashboardLayout, 
    accessSlug: "clients_users_view", 
    childOf: "/clients",
    ignoreOnNav: true,
})

router.addRoute("/clients/groups", {
    title: "Groups",
    Container: ClientsGroupsPage, 
    Layout: DashboardLayout, 
    accessSlug: "clients_groups",
    childOf: "/clients", 
    ignoreOnNav: true,
})

router.addRoute("/clients/groups/create", {
    title: "Create Group",
    Container: ClientsCreateGroupPage, 
    Layout: DashboardLayout, 
    accessSlug: "clients_groups_create",
    childOf: "/clients",
    ignoreOnNav: true, 
})

router.addRedirect("/clients/view", "/clients")

router.addRoute("/clients/view/:groupId", {
    title: "View Group",
    Container: ClientsViewGroupPage,
    Layout: DashboardLayout, 
    accessSlug: "clients_groups_view", 
    childOf: "/clients/groups",
    ignoreOnNav: true,
})


export default router;