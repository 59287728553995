import React, {useEffect, useState} from "react";
import { Routes, Route } from 'react-router-dom';
import PropTypes from "prop-types";
import useAuth from "@acromove/authentication/hooks/use-auth";
import routesSource from "./router";

const AppRouter = ({children}) => {
    const auth = useAuth();
	const [checked, setChecked] = useState(false);
	const [privateRotes, setPrivateRoutes] = useState([]);
	const [publicRotes, setPublicRoutes] = useState([]);

    useEffect(() => {
		if(!publicRotes.length){
            setPublicRoutes(routesSource.routerElements.filter(route => !route.protected))
        }
	}, []);

    useEffect(() => {
		if (!auth.isAuthenticated) {
            setChecked(true);
		} else {
            setChecked(true);
        }
	}, [auth.isInitialized]);

    useEffect(() => {
		if (auth.isAuthenticated && auth.user?.username && !privateRotes.length) {
			setPrivateRoutes(routesSource.routerElements.filter(route => route.protected))
		}
	}, [auth.isInitialized, auth.user]);

    if (!checked) {
		return null;
	}

    return (
        <Routes>
            <Route path="/" element={children}>
                {
                    publicRotes.map( route => (
                        (route?.index || false) ? 
                            <Route key={route.path} index element={<route.component />} />
                            :
                            <React.Fragment key={`${route.path}_group`}>
                                {
                                  route?.sub && route.sub.map( sub => (
                                    <Route key={sub.path} exact path={sub.path} element={<sub.component />} />
                                  ))  
                                }
                                <Route key={route.path} exact path={route.path} element={<route.component />} />
                            </React.Fragment>
                    ))
                }
                {
                    privateRotes.map( route => (
                        (route?.index || false) ? 
                            <Route key={route.path} index element={<route.component />} />
                            :
                            <React.Fragment key={`${route.path}_group`}>
                                {
                                  route?.sub && route.sub.map( sub => (
                                    <Route key={sub.path} exact path={sub.path} element={<sub.component />} />
                                  ))  
                                }
                                <Route key={route.path} exact path={route.path} element={<route.component />} />
                            </React.Fragment>
                    ))
                }
            </Route>
        </Routes>
    )
};

AppRouter.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AppRouter;