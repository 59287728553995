// import _ from "lodash";

const initialState = {
    name: "",
    stepsData: {},
    activeStepIndex: 0,
    activeStepSlug: "",
    ordering: {},
    last: "",
    history: [],
    labels: {},
    showOverview: false,
}

function reducer(state, action) {
    switch (action.type) {
        case 'SHOW_OVERVIEW': 
            return {
                ...state,
                showOverview: action.payload
            }
        case 'ADD_LABEL': 
            return {
                ...state,
                labels: {
                    ...state.labels,
                    [action.payload.slug]: action.payload.label
                }
            }
        case 'LOAD_WIZARD': 
            return {
                ...state,
                ...action.payload
            }
        case 'SET_NAME': 
            return {
                ...state,
                name: action.payload
            }
        case 'SET_STEPS_DATA':
            return {
                ...state,
                stepsData: {
                    ...state.stepsData,
                    ...action.payload
                }
            }
        case 'SET_STEP_DATA':
            return {
                ...state,
                stepsData: {
                    ...state.stepsData,
                    [action.payload.step]: action.payload.data
                }
            };
        case 'ADD_TO_ORDERING':
            return {
                ...state,
                ordering: {
                    ...state.ordering,
                    [action.payload.slug]: action.payload.pos
                }
            };
        case 'SET_LAST':
            return {
                ...state,
                last: action.payload
            }
        case "GO_TO":
            /* eslint-disable  no-case-declarations */
            let slug = action.payload;
            if(slug === "$prev") {
                slug = state.history[state.history.length - 2]
                return {
                    ...state,
                    activeStepIndex: state.ordering[slug],
                    activeStepSlug: slug,
                    history: [...new Set([...state.history.slice(0, state.history.length - 1)])]
                }
            } 
            if(slug === "$next") {
                for (const key in state.ordering) {
                    if (state.ordering[key] === (state.activeStepIndex + 1)) {
                        slug = key
                    }
                }
            }
            return {
                ...state,
                activeStepIndex: state.ordering[slug],
                activeStepSlug: slug,
                history: [...new Set([...state.history, slug])]
            }
            /* eslint-disable  no-case-declarations */
      default:
        return state;
    }
}





export default {
    reducer,
    initialState,
    actions: {
        setStepsData: (payload) => ({
            type: 'SET_STEPS_DATA',
            payload
        }),
        setStepData: (step, data) => ({
            type: "SET_STEP_DATA",
            payload: {step, data}
        }),
        addToOrdering: (slug, pos) => ({
            type: "ADD_TO_ORDERING",
            payload: {slug, pos}
        }),
        setLast: (slug) => ({
            type: "SET_LAST",
            payload: slug
        }),
        goTo: (slug) => ({
            type: "GO_TO",
            payload: slug
        }),
        setName: (name) => ({
            type: "SET_NAME",
            payload: name
        }),
        loadWizard: (state) => ({
            type: "LOAD_WIZARD",
            payload: state
        }),
        addLabel: (slug, label) => ({
            type: "ADD_LABEL",
            payload: {slug, label}
        }),
        toggleOverview: (status) => ({
            type: "SHOW_OVERVIEW",
            payload: status
        })
    }
}