import React from "react";

/* MUI */
import { Typography, Grid, Box } from "@mui/material";
import StorageIcon from "@mui/icons-material/Storage";
import PinDropIcon from "@mui/icons-material/PinDrop";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import TimerIcon from "@mui/icons-material/Timer";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

/* Components */
import UxItem from "./generic/ux-item";

/* Utils */
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./styles"

const HomeUx = () => {
	const { t } = useTranslation("home_page", { keyPrefix: "ux" });
	const isDownMd = useMediaQuery((theme) => `${theme.breakpoints.down("md")}`);
	const items = [
		{
			title: t("hardware.title"),
			text: t("hardware.text"),
			icon: <StorageIcon fontSize="large" sx={{ color: "primary.main" }} />,
		},
		{
			title: t("tracing.title"),
			text: t("tracing.text"),
			icon: <PinDropIcon fontSize="large" sx={{ color: "primary.main" }} />,
		},
		{
			title: t("shipping.title"),
			text: t("shipping.text"),
			icon: <LocalShippingIcon fontSize="large" sx={{ color: "primary.main" }} />,
		},
		{
			title: t("speed.title"),
			text: t("speed.text"),
			icon: <CloudUploadIcon fontSize="large" sx={{ color: "primary.main" }} />,
		},
		{
			title: t("transfer_time.title"),
			text: t("transfer_time.text"),
			icon: <TimerIcon fontSize="large" sx={{ color: "primary.main" }} />,
		},
	];
	return (
		<Box
			sx={{
				width: "100%",
				py: "40px",
				px: "80px",
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
			}}
		>
			<Typography
				color="text.main"
				sx={styles.titles}
			>
				{t("title")}
			</Typography>
			<Box sx={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "row", mb: 4 }}>
				<Grid
					container
					gap={2}
					sx={{
						display: "flex",
						flexDirection: isDownMd ? "column" : "row",
						alignItems: "flex-start",
						justifyContent: isDownMd ? "center" : "space-evenly",
					}}
				>
					{items.map((item) => (
						<UxItem text={item.text} icon={item.icon} title={item.title} key={item.title} />
					))}
				</Grid>
			</Box>
		</Box>
	);
};

export default HomeUx;
