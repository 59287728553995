import React from "react";
import PropTypes from "prop-types"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const Left = (props) => {
	const { image, width } = props;
	const { t } = useTranslation("acrotransfer_create_job");

	return (
		<Box sx={{ flexDirection: "column", display: "flex", width: "calc(100% - 16px)", mr: 2 }}>
			<Typography fontWeight={600} fontSize={20}>
				{t("main_desc")}
			</Typography>
			<Typography fontWeight={500} fontSize={18} sx={{ my: 2 }} color="text.secondary">
				{t("main_desc_ext")}
			</Typography>
			<img src={image} width={width} alt="server" style={{ alignSelf: "center" }} />
		</Box>
	);
};

Left.propTypes = {
    image: PropTypes.string.isRequired,
	width: PropTypes.string
}

Left.defaultProps ={
	width: "100%"
}

export default Left;
