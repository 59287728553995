import React from "react";

/* MUI */
import Grid from "@mui/material/Grid";

/* Components */
import DatePicker from "@acromove/components/form/datepick";
import Input from "@acromove/components/form/input";
import Form from "@acromove/components/form";
import Select from "@acromove/components/form/select";

/* Utils */
import { useTranslation } from "react-i18next";

/* Assets */

const JobInfo = () => {
	const { t } = useTranslation("acrotransfer_job_overview");

	return (
		<Grid container gap={2} sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
			<Grid item md={12}>
				<Grid container gap={2} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
					<Form.Field name="name">
						<Grid item md={4}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Grid>
					</Form.Field>
					<Form.Field name="data_size">
						<Grid item md={3}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Grid>
					</Form.Field>
					<Form.Field name="data_size_format">
						<Grid item md={1}>
							<Select
								sx={{ width: "100%" }}
								options={[
									{ label: "GB", value: "gb" },
									{ label: "TB", value: "tb" },
									{ label: "PB", value: "pb" },
								]}
							/>
						</Grid>
					</Form.Field>
					<Form.Field name="data_kind">
						<Grid item md={4}>
							<Select
								sx={{ width: "100%" }}
								options={[
									{ label: t("acrotransfer_jobs.data_kind.options.db"), value: "db" },
									{ label: t("acrotransfer_jobs.data_kind.options.vm_disk"), value: "vm_disk" },
									{ label: t("acrotransfer_jobs.data_kind.options.raw"), value: "raw" },
									{ label: t("acrotransfer_jobs.data_kind.options.mix"), value: "mix" },
								]}
							/>
						</Grid>
					</Form.Field>
					<Form.Field name="start_date">
						<Grid item md={4}>
							<DatePicker sx={{ width: "100%" }} />
						</Grid>
					</Form.Field>
				</Grid>
			</Grid>
			<Form.Field name="description">
				<Grid item md={12}>
					<Input type="textarea" size="medium" sx={{ width: "100%" }} />
				</Grid>
			</Form.Field>
		</Grid>
	);
};

export default JobInfo;
