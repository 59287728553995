import {useContext, useEffect} from "react";
import Context from "./context";

import PropTypes from "prop-types";

/**
 * Wizard Step.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Wizard Step.
 */
const WizardStep = (props) => {
    const {children, slug, pos, label} = props;
    const wizard = useContext(Context.ctx);

    useEffect(() => {
        wizard.privateActions.addToOrdering(slug, pos)
        wizard.privateActions.addToLabels(slug, label)
    }, [])

    return wizard.state.activeStepSlug === slug ? children : null;
}


WizardStep.wizardRole = "step"

/**
 * Wizard Step Properties.
 *
 * @typedef {object} Props
 * @property {string} slug
 * @property {React.ReactNode} children - Steps Children.
 * @property {number} pos - Steps Position/Index in list.
 * @property {string} label - Step Label.
 */

WizardStep.propTypes = {
    children: PropTypes.node.isRequired,
    slug: PropTypes.string.isRequired,
    pos: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired
}

export default WizardStep;