import React, { useContext } from "react";
import PropTypes from "prop-types"

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Context from "./context"
import { useMediaQuery } from "@mui/material";

/**
 * Wizard Tools.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Wizard Tools components.
 */
const WizardTools = (props) => {
	const { beforeNext, beforePrev } = props;
	const wizard = useContext(Context.ctx)
	const { ordering, last, activeStepIndex, stepsData } = wizard.state;
	const isUpSm = useMediaQuery((theme) => `${theme.breakpoints.up("sm")}`);

	const handelNext = () => {
		const goNext = beforeNext();
		if (!goNext) return;
		if (activeStepIndex === ordering[last]) {
			wizard.events.onFinish(stepsData)
		} else {
			wizard.actions.goTo("$next")
		}

	}

	const handleBack = () => {
		const goPrev = beforePrev();
		if (!goPrev) return;
		wizard.actions.goTo("$prev")
	}

	const handleSave = () => {
		wizard.events.onSaveAsDraft(wizard.state)
	};

	return (
		isUpSm ?
		<Box sx={[{
			mt: 2,
			mb: 2,
			ml: 1,
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center"
		}]}>
			<Box>
				<Button size="small" disabled={activeStepIndex === 0} onClick={handleSave} sx={{ mt: 1, mr: 1 }}>
					Save as draft and exit
				</Button>
			</Box>
			<Box>
				<Button
					size="small"
					startIcon={<ArrowRightAltIcon sx={{ transform: "rotate(180deg)" }} />}
					disabled={activeStepIndex === 0}
					onClick={handleBack}
					variant="contained"
					sx={{ mt: 1, mr: 1 }}
				>
					Previous
				</Button>
				<Button
					autoFocus
					size="small"
					endIcon={!(activeStepIndex === ordering[last]) && <ArrowRightAltIcon />}
					variant="contained"
					onClick={handelNext}
					sx={{ mt: 1, mr: 1 }}
				>
					{activeStepIndex === ordering[last] ? "Finish" : "Continue"}
				</Button>
			</Box>
		</Box>
		:
		<Box sx={{width: "100%"}}>
			<Button size="small" variant="contained" disabled={activeStepIndex === 0} onClick={handleSave} sx={{ mt: 1, mr: 1, width: "100%" }}>
				Save as draft and exit
			</Button>
		</Box>
	);
};

/**
 * Wizard Tools Properties.
 *
 * @typedef {object} Props
 * @property {function} [beforeNext = () => true] - Function executed when proceeding to the next step.
 * @property {function} [beforePrev = () => true] - Function executed when returning to previous step.
 */

WizardTools.propTypes = {
	beforeNext: PropTypes.func,
	beforePrev: PropTypes.func,
}

WizardTools.defaultProps = {
	beforeNext: () => true,
	beforePrev: () => true,
}

export default WizardTools;
