
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DataGridTooltip from "@acromove/components/data-grid/cells/datagrid-tooltip";
import DataGridMenu  from "@acromove/components/data-grid/cells/datagrid-menu_button";
 
export const jobsTemplates = (props) => {
	const { setOpen, setId } = props;
	const { t } = useTranslation("common");
	const navigate = useNavigate();

	return [
		{
			headerName: "name",
			field: "name",
			sortable: true,
			minWidth: 250,
			renderCell: (nameProps) => {
				const { row } = nameProps;
				return <DataGridTooltip value={row?.name} tooltipValue={row?.description} />;
			},
		},
		{
			headerName: "data_kind",
			field: "data_kind",
			sortable: false,
			minWidth: 100,
		},
		{
			headerName: "data_size",
			field: "data_size",
			sortable: false,
			minWidth: 100,
		},
		{
			headerName: "source",
			field: "source",
			minWidth: 250,
			sortable: false,
		},
		{
			headerName: "url",
			field: "url",
			sortable: false,
			flex: 0.5,
			align: "right",
			renderCell: (cellProps) => {
				const { row } = cellProps;

				const menu = [
					{protected: false, onClick: () => navigate(row.url), text: t("view_edit")},
					{protected: false, onClick: () => {setOpen(true); setId(row.id)}, text: t("remove"), color: "error.main"}
				]
				return <DataGridMenu menu={menu} />
					
			},
		},
	];
};

export default { jobsTemplates };
