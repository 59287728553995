import React from "react";
import PropTypes from "prop-types";

/* MUI */
import {Grid, Box, Typography, Button} from "@mui/material";

/* Components */
import RecieveDeviceInfo from "components/content/acrotransfer/overview/info-forms/recieve-device";
import ShipDeviceInfo from "components/content/acrotransfer/overview/info-forms/ship-device";
import SupportDeviceInfo from "components/content/acrotransfer/overview/info-forms/support-device";
import Section from "components/layouts/dashboard/ui/section";
import ArrowRightIcon from "components/icons/arrow-right";

/* Utils */
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const JobGuide = (props) => {
    const {jobId} = props
    const { t } = useTranslation("acrotransfer_jobs");
    const navigate = useNavigate();

	return (
		<Grid container sx={{ py: 3, width: "100%" }}>
			<Section title={t("job_information.job_guide.title")} line="success" status="success" statusText="Done" />

			<Section
				title={t("job_information.recieve_device.title")}
				line="success"
				status="success"
				statusText="Done"
				collapsible
			>
				<RecieveDeviceInfo />
			</Section>

			<Section
				title={t("job_information.check_damage.title")}
				line="success"
				status="success"
				statusText="Done"
				collapsible
			>
				<Box sx={{ ml: 1, display: "flex", flexDirection: "row", alignItems: "center" }}>
					<Typography fontWeight={600}>{t("job_information.check_damage.label")}</Typography>
					<Button
						onClick={() => navigate(`/jobs/view/${jobId}/health_check`)}
						sx={{ ml: 1 }}
						variant="contained"
						endIcon={<ArrowRightIcon />}
                        
					>
						{t("job_information.check_damage.button")}
					</Button>
				</Box>
			</Section>

			<Section
				title={t("job_information.connection_setup.title")}
				line="success"
				status="success"
				statusText="Done"
				collapsible
			>
				<SupportDeviceInfo />
			</Section>

			<Section
				title={t("job_information.transfer_start.title")}
				line="success"
				status="success"
				statusText="Done"
				collapsible
			>
				<Box sx={{ ml: 1 }}>
					<Typography fontWeight={600}>{t("job_information.transfer_start.label")}</Typography>
				</Box>
			</Section>

			<Section
				title={t("job_information.ship_back.title")}
				line="success"
				status="success"
				statusText="Done"
				collapsible
			>
				<ShipDeviceInfo />
			</Section>
		</Grid>
	);
};

JobGuide.propTypes = {
    jobId: PropTypes.string.isRequired
}

export default JobGuide;
