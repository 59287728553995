import React from "react";
import PropTypes from "prop-types";

/* MUI */
import MUIRadio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

/* Utils */
import useField from "./hooks/use-filed";

/**
 * Radio Group component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Form Controlled Radio Group Component.
 */
const Radio = (props) => {
	const {
		label,
		control,
		options,
		name: fieldName,
		customRadio,
		sxLabel,
		sxFormControl,
		sxRadioGroup,
		helperText,
		disabled,
	} = props;

    const field = useField({
        fromProps: {
            label,
            name: fieldName,
            disabled,
			helperText,
			control,
        }
    })

	const { onChange, onBlur, name, value, ref } = field.controller.field;
	const { error } = field.controller.fieldState;

	return (
		<FormControl disabled={field.disabled} sx={sxFormControl}>
			<FormLabel
				sx={[
					{
						color: "text.primary",
						fontSize: 16,
						fontWeight: 500,
						pb: 1,
					},
					sxLabel,
				]}
				id="demo-row-radio-buttons-group-label"
			>
				{field.label}
				{field.helperText && (
					<IconButton
						sx={{
							ml: 1,
						}}
						size="medium"
						color="primary"
						onClick={() => field.setShowHelper(!field.showHelper)}
					>
						<InfoOutlinedIcon />
					</IconButton>
				)}
			</FormLabel>
			<RadioGroup
				sx={sxRadioGroup}
				ref={ref}
				row
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				aria-labelledby="demo-row-radio-buttons-group-label"
				name={name}
			>
				{options.map((op) =>
					customRadio ? (
						customRadio({
							name,
							onChange,
							currentValue: value,
							key: op.value,
							value: op.value,
							label: op.label,
							extra: op?.extra || null,
						})
					) : (
						<FormControlLabel
							sx={{ p: 1 }}
							value={op.value}
							key={op.value}
							control={<MUIRadio />}
							label={op.label}
						/>
					)
				)}
			</RadioGroup>
			{(error || field.helperText) && <FormHelperText>{field?.helperTextMsg}</FormHelperText>}
		</FormControl>
	);
};

/**
 * Radio Properties.
 *
 * @typedef {object} Props
 * @property {string | true | false} [helperText = false] - Helper Text of the Radio.
 * @property {object} control - Form control options.
 * @property {string} name - Form Radio name.
 * @property {React.ReactNode | false} [customRadio = false] - Props to add Custom Radio Component to Radio.
 * @property {string} label  - Label on top of Radio.
 * @property {object} [sxFormControl = {}] - Property to control FormControl(Whole Radio Container) CSS.
 * @property {object} [sxRadioGroup = {}] - Property to control RadioGroup CSS.
 * @property {object} [sxLabel = {}] - Property to control Label CSS.
 * @property {object} options - Radio Group Options.
 * @property {number | string} options.value - Value.
 * @property {string} options.label - Label
 */

Radio.propTypes = {
	helperText: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	label: PropTypes.string,
	control: PropTypes.shape({}).isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	name: PropTypes.string.isRequired,
	customRadio: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([false])]),
	sxFormControl: PropTypes.shape({}),
	sxRadioGroup: PropTypes.shape({}),
	sxLabel: PropTypes.shape({}),
    disabled: PropTypes.bool,

};

Radio.defaultProps = {
	helperText: false,
	sxFormControl: {},
	sxLabel: {},
	sxRadioGroup: {},
	customRadio: false,
	label: "",
    disabled: false,

};


export default Radio;
