import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import ClientGroupsContainer from "containers/acrotransfer/admin/clients";

const  ClientsGroupsPage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={["admin","admin:owner", "acromove_admin", "acromove_admin:owner"]}>
            <ClientGroupsContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default  ClientsGroupsPage;