import React, { useContext } from "react";
import PropTypes from "prop-types";
import Context from "./context";

/* MUI */
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { Divider, useMediaQuery, MobileStepper, Box } from "@mui/material";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

/* Components */
import StepIcon from "./step-icon";

/**
 * Wizard Bar component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Wizard Bar Component.
 */
const WizardBar = (props) => {
	const { children, beforeNext, beforePrev } = props;
	const wizard = useContext(Context.ctx);
	const { ordering, last, activeStepIndex, stepsData } = wizard.state;
	const steps = Object.keys(wizard.state.ordering).sort(
		(a, b) => wizard.state.ordering[a] - wizard.state.ordering[b]
	);
	
	const isDownMd = useMediaQuery((theme) => `${theme.breakpoints.down("md")}`);
	const isUpSm = useMediaQuery((theme) => `${theme.breakpoints.up("sm")}`);

	const handelNext = () => {
		const goNext = beforeNext();
		if (!goNext) return;
		if (activeStepIndex === ordering[last]) {
			wizard.events.onFinish(stepsData);
		} else {
			wizard.actions.goTo("$next");
		}
	};

	const handleBack = () => {
		const goPrev = beforePrev();
		if (!goPrev) return;
		wizard.actions.goTo("$prev");
	};

	return isUpSm ? (
		<>
			{isDownMd && <Divider sx={{ my: 2 }} />}
			<Stepper
				sx={{
					".MuiStepConnector-root.Mui-disabled": {
						display: "none",
					},
					mt: -1,
				}}
				activeStep={wizard.state.activeStepIndex}
				orientation="vertical"
			>
				{steps.map((step, index) => (
					<Step
						sx={{
							"& + .MuiStepConnector-root.Mui-disabled": {
								display: () => {
									if (
										wizard.state.activeStepIndex + 1 ===
										index
									) {
										return "flex";
									}
									return "none";
								},
							},
							"&.Mui-completed": {
								"& + .MuiStepConnector-root": {
									display: "none",
								},
							},
						}}
						key={step}
						hidden={
							wizard.state.activeStepIndex + 2 < index ||
							wizard.state.activeStepIndex > index
						}
					>
						<StepLabel
							StepIconComponent={StepIcon}
							optional={
								index === steps.length - 1 ? (
									<Typography variant="caption">
										Last step
									</Typography>
								) : null
							}
						>
							{wizard.state.labels[step].toUpperCase()}
						</StepLabel>
						{children && <StepContent>{children}</StepContent>}
					</Step>
				))}
			</Stepper>
		</>
	) : (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
			}}
		>
			<Divider sx={{ my: 2 }} />
			{steps.map((step, index) => (
				<Box
					sx={{
						width: "100%",
						position: "relative",
						justifyContent: "center",
						order: 1,
						zIndex: 10000,
						display: () => {
							if (wizard.state.activeStepIndex === index) {
								return "flex";
							}
							return "none";
						},
					}}
					hidden={
						wizard.state.activeStepIndex + 2 < index ||
						wizard.state.activeStepIndex > index
					}
				>
					<Typography
						sx={{
							fontWeight: 500,
							fontSize: "small",
						}}
					>
						{`STEP ${
							wizard.state.activeStepIndex + 1
						} : ${wizard.state.labels[step].toUpperCase()}`}
					</Typography>
				</Box>
			))}
			<MobileStepper
				variant="progress"
				steps={steps.length}
				sx={{
					".MuiStepConnector-root.Mui-disabled": {
						display: "none",
					},
					order: 2,
					position: "relative",
					mt: -1,
				}}
				activeStep={wizard.state.activeStepIndex}
				nextButton={
					<Button
						size="small"
						onClick={handelNext}
					>
						{activeStepIndex === ordering[last] ? <Typography sx={{fontWeight: 500}}>Finish</Typography> : <KeyboardArrowRight />}
					</Button>
				}
				backButton={
					<Button
						size="small"
						onClick={handleBack}
						disabled={activeStepIndex === 0}
					>
						<KeyboardArrowLeft />
					</Button>
				}
			/>
			<Box sx={{ order: 3 }}>{children}</Box>
		</Box>
	);
};

/**
 * Wizard bar Properties.
 *
 * @typedef {object} Props
 * @property {React.ReactNode[] | null} [children = "null"] - Step Content on the right of Wizard Bar.
 * @property {function} [beforeNext = () => true] - Function executed when proceeding to the next step.
 * @property {function} [beforePrev = () => true] - Function executed when returning to previous step.
 */

WizardBar.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.oneOf([null]),
	]),
	beforeNext: PropTypes.func,
	beforePrev: PropTypes.func,
};

WizardBar.defaultProps = {
	children: null,
	beforeNext: () => true,
	beforePrev: () => true,
};

export default WizardBar;
