/*eslint-disable*/
import React, { useEffect } from "react";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";

/* Common Components */
import Wizard, {useWizard} from "@acromove/components/wizard";

/* Content Components */
import TemplateSelect from "components/content/acrotransfer/wizard-steps/template-select";
import WhatKindOfJob from "components/content/acrotransfer/wizard-steps/what-kind-of-job";
import DataVolume from "components/content/acrotransfer/wizard-steps/data-volume";
import TimeFrame from "components/content/acrotransfer/wizard-steps/time-frame";
import SourceShipping from "components/content/acrotransfer/wizard-steps/source-shipping";
import SourceTransfer from "components/content/acrotransfer/wizard-steps/source-transfer";
import SourceConnection from "components/content/acrotransfer/wizard-steps/source-connection";
import SourceCabling from "components/content/acrotransfer/wizard-steps/source-cabling";
import DestinationShipping from "components/content/acrotransfer/wizard-steps/destination-shipping";
import DestinationTransfer from "components/content/acrotransfer/wizard-steps/destination-transfer";
import DestinationConnection from "components/content/acrotransfer/wizard-steps/destination-connection";
import DestinationCabling from "components/content/acrotransfer/wizard-steps/destination-cabling";
import CheckAvailability from "components/content/acrotransfer/wizard-steps/check-availability";

import Overview from "containers/acrotransfer/manager/jobs/overview"

/* Utils */
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CreateJobContainer = () => {
	const navigate = useNavigate();
	const {t} = useTranslation("common")

	const wizard = useWizard({
		name: "createJobWizard",
		onSaveAsDraft: (data) => {
			localStorage.setItem(data.name, JSON.stringify(data))
			navigate(-1)
		},
		last: "check_availability",
		initial: "template_select",
	})

	useEffect(() => {
		const draft = localStorage.getItem("createJobWizard");
		if(draft) {
			wizard.actions.loadWizard(JSON.parse(draft))
			localStorage.removeItem("createJobWizard")
		}
	}, [])

	return (
		<Container>
			<Container.Header back={-1} title={t("back")} />
			<Wizard wizard={wizard} overview={Overview}>
				<TemplateSelect />
				<WhatKindOfJob />
				<DataVolume />
				<TimeFrame />
				<SourceShipping />
				<SourceTransfer />
				<SourceConnection />
				<SourceCabling />
				<DestinationShipping />
				<DestinationTransfer />
				<DestinationConnection />
				<DestinationCabling />
				<CheckAvailability />
			</Wizard>	
		</Container>
	);
};

export default CreateJobContainer;
