import React from "react";
import PropTypes from "prop-types"
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";


const HealthCheckLeftContent = (props) => {
	const { image } = props;
    const { t } = useTranslation("acrotransfer_health_check");

	return (
		<Box sx={{flexDirection: "column", display: "flex", width: "calc(100% - 8px)"}}>
            <Typography fontWeight={600} fontSize={20}>
                {t("left_main")}
            </Typography>
            <Typography fontWeight={500} fontSize={18} color="text.secondary" sx={{mb: 2}}>
                {t("left_ext")}
            </Typography>
			<img src={image} alt="stickers" />;
		</Box>
	);
};

HealthCheckLeftContent.propTypes = {
    image: PropTypes.string.isRequired
}

export default HealthCheckLeftContent;
