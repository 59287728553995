import React, { useContext } from "react";
import PropTypes from "prop-types";

/* MUI */
import CardHeader from "@mui/material/CardHeader";
import MUITable from "@mui/material/Table";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";

/* Components */
import Header from "./header";
import EmptyComponent from "./empty";
import Scrollbar from "../scrollbars";
import StatusStat from "../statistics/StatusStat";
import Search from "../form/search";

/* Utils */
import { tableStatusMap } from "../statistics/status-map";
import { useTable } from "react-table";
import { dataContext } from "./table-data-provider";
import useLocalUserSettings from "@acromove/hooks/use-local-user-settings"

/**
 * Table component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Table.
 */
const Table = (props) => {
	const fromDataContext = useContext(dataContext);
	const {
		search,
		sx,
		columns,
		data,
		pageSize,
		page,
		pageSizeOptions,
		onPageSizeChange,
		onPageChange,
		onSearch,
		total,
		action,
		sortItem,
		sortDer,
		loading,
		title,
		emptyText,
		emptyIcon,
		line,
		status,
		statusText,
		noCardShadow,
		collapsible
	} = fromDataContext ? {...props, ...fromDataContext} : props

	const { getTableProps, headerGroups, rows, prepareRow } = useTable({
		columns,
		data,
	});

	const [open, setOpen] = useLocalUserSettings(title, true);

	return (
		<Grid
			container
			sx={{ mb: 1, ...sx, position: "relative", width: "100%" }}
		>
			{line && (
				<Box
					sx={{
						position: "absolute",
						left: 0,
						top: "50%",
						transform: "translateY(-50%)",
						borderRadius: "0px 2px 2px 0px",
						width: "5px",
						height: "80%",
						backgroundColor:
							status === "review"
								? tableStatusMap[status].backgroundColor
								: tableStatusMap[status].color,
					}}
				/>
			)}
			<Card sx={{ width: "100%", boxShadow: noCardShadow && "0 0 0 0" }}>
				{(title || action) && (
					<CardHeader
						action={
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: 1,
								}}
							>
								{search && <Search onSearch={onSearch} />}
								{action}
								{status && (
									<StatusStat
										type="table"
										status={status}
										statusText={statusText}
									/>
								)}
								{collapsible ? (
									<IconButton
										size="small"
										onClick={() => setOpen(!open)}
									>
										{open ? (
											<ExpandLessIcon />
										) : (
											<ExpandMoreIcon />
										)}
									</IconButton>
								) : null}
							</Box>
						}
						title={title}
						sx={{
							bgcolor: "#F4F6F9",
							mt: 0,
							mb: 1,
							ml: 1,
							mr: 1,
							marginTop: "16px",
							padding: "8px 16px",
							borderRadius: "6px",
						}}
					/>
				)}
				<Collapse in={open}>
					<Scrollbar>
						<Grid
							container
							sx={{ pt: 1, pl: 1, pr: 1, width: "100%" }}
						>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<MUITable
									sx={{ minWidth: 500 }}
									{...getTableProps()}
								>
									{!loading && !data.length ? (
										<EmptyComponent
											emptyText={emptyText}
											emptyIcon={emptyIcon}
										/>
									) : (
										<>
											<Header
												headerGroups={headerGroups}
												sortItem={sortItem}
												sortDer={sortDer}
											/>
											<TableBody>
												{loading
													? [...new Array(pageSize)]
															.map((_, i) => i)
															.map((key) => (
																<TableRow
																	key={key}
																>
																	{columns.map(
																		(
																			cell
																		) => (
																			<TableCell
																				key={`l_${cell.Header}_${cell.accessor}`}
																				sx={
																					cell.Header ===
																					"__empty"
																						? {
																								m: 0,
																								p: 0,
																								position:
																									"relative",
																						  }
																						: {}
																				}
																			>
																				<Skeleton animation="wave" />
																			</TableCell>
																		)
																	)}
																</TableRow>
															))
													: rows.map((row) => {
															prepareRow(row);
															return (
																<TableRow
																	{...row.getRowProps()}
																>
																	{row.cells.map(
																		(
																			cell
																		) => (
																			<TableCell
																				sx={
																					cell
																						.column
																						.Header ===
																					"__empty"
																						? {
																								m: 0,
																								p: 0,
																								position:
																									"relative",
																								textAlign:
																									"left",
																						  }
																						: {
																								textAlign:
																									"left",
																						  }
																				}
																				{...cell.getCellProps()}
																			>
																				{cell.render(
																					"Cell"
																				)}
																			</TableCell>
																		)
																	)}
																</TableRow>
															);
													  })}
											</TableBody>
										</>
									)}
								</MUITable>
							</Grid>
						</Grid>
					</Scrollbar>
					<TablePagination
						hidden={total < pageSize}
						component="div"
						count={total}
						onPageChange={onPageChange}
						onRowsPerPageChange={onPageSizeChange}
						page={page - 1}
						rowsPerPage={pageSize}
						rowsPerPageOptions={pageSizeOptions}
					/>
				</Collapse>
			</Card>
		</Grid>
	);
};

/**
 * Table Properties.
 *
 * @typedef {object} Props
 * @property {object[]} columns - Array of Collumns
 * @property {object[]} data - Table data.
 * @property {number} [pageSize = "5"] - Ammount of elements displayed in one Table Page.
 * @property {number} [page = "1"] - Index of Table Page.
 * @property {string} [sortItem = "id"] - Item that the table can be sorted by.
 * @property {"desc" | "asc"} [sortDer = "desc"] - Direction of sorting.
 * @property {React.ReactNode} [action = ""] - Element added to the Table Header.
 * @property {number[]} [pageSizeOptions = [5, 10, 15]] - Page Sizing Options.
 * @property {function} [onPageSizeChange = () => {}] - Event triggered on Page size change.
 * @property {function} [onPageChange = () => {}] - Event triggered on Page change.
 * @property {number} total - Total items in Table.
 * @property {true | false} [loading = "false"] - Loading status.
 * @property {string} [emptyText = "No Data"] - Text Displayed when Table is Empty,
 * @property {React.ReactNode} [emptyIcon = DescriptionIcon<Object<{sx:{"text.secondary"}}>>] - Icon for Empty Table.
 * @property {object} [sx = {}] - Table container CSS properties.
 * @property {string} [status = ""] - Table Item Status.
 * @property {true | false} [line = "false"] - Table Status displaying line.
 * @property {string} [statusText = ""] - Text describing status of current Table.
 * @property {true | false} [noCardShadow = "false"] - Property for displaying/hiding Card Shadow.
 * @property {true | false} [collapsible = "false"] - Property to define if data grid section is collapsible.
 */

Table.propTypes = {
	title: PropTypes.string,
	columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	pageSize: PropTypes.number,
	page: PropTypes.number,
	sortItem: PropTypes.string,
	sortDer: PropTypes.oneOf(["desc", "asc"]),
	action: PropTypes.node,
	pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
	onPageSizeChange: PropTypes.func,
	onPageChange: PropTypes.func,
	onSearch: PropTypes.func,
	total: PropTypes.number.isRequired,
	loading: PropTypes.bool,
	emptyText: PropTypes.string,
	emptyIcon: PropTypes.node,
	sx: PropTypes.shape({}),
	status: PropTypes.string,
	line: PropTypes.bool,
	statusText: PropTypes.string,
	noCardShadow: PropTypes.bool,
	collapsible: PropTypes.bool
};

Table.defaultProps = {
	title: "",
	pageSize: 5,
	page: 1,
	action: "",
	pageSizeOptions: [5, 10, 15],
	onPageSizeChange: () => {},
	onPageChange: () => {},
	onSearch: () => {},
	sortItem: "id",
	sortDer: "desc",
	loading: false,
	emptyText: "No Data",
	emptyIcon: <DescriptionIcon sx={{ color: "text.secondary" }} />,
	sx: {},
	status: "",
	line: false,
	statusText: "",
	noCardShadow: false,
	collapsible: false
};

export default Table;
