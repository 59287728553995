import React, { useState } from "react";

/* MUI */
import { Typography, Grid, Box, Button, Dialog, IconButton } from "@mui/material";
import PlayCircleRoundedIcon from '@mui/icons-material/PlayCircleRounded';
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

/* Assets */
import assetdots from "assets/home-asset-dots.png";

/* Utils */
import { useTranslation } from "react-i18next";
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from "./styles"

const HomeBanner = () => {
	const [open, setOpen] = useState(false);
	const { t } = useTranslation("home_page", { keyPrefix: "banner" });

	const isDownMd = useMediaQuery((theme) => `${theme.breakpoints.down('lg')}`);

	return (
		<>
			<Dialog
				maxWidth="md"
				sx={[
					{
						"&.MuiDialog-root": {
							".MuiPaper-root": {
								backgroundColor: "transparent",
								boxShadow: "none",
							},
						},
					},
				]}
				open={open}
				onClose={() => setOpen(false)}
				hideButtons
			>
				<video width="100%" controls autoPlay>
					<source src="https://acromove.com/wp-content/uploads/2021/11/AcroTransfer-by-Acromove-The-fastest-safest-lowest-cost-service-to-transfer-vast-amounts-of-data.mp4" type="video/mp4" />
					<track kind="captions" srcLang="en" label="english_captions" />
				</video>
			</Dialog>
			<Box
				sx={{
					display: "flex",
					backgroundColor: "sideMenu.background",
					width: "100%",
					mt: "52px",
					py: "40px",
					px: "80px",
					height: "auto"
				}}
			>
				<Grid container gap={isDownMd ? 10 : 5} sx={styles.bannerWrap}>
					<Grid item gap={2} lg={5} md={12} sm={12} xs={12} sx={styles.bannerTextBlock}>
						<Typography sx={{ color: "primary.main", fontSize: 24, fontWeight: 500 }}>
							{t("acrotransfer")}
						</Typography>
						<Typography sx={styles.bannerMainText}>{t("main_text")}</Typography>
						<Typography sx={{ color: "text.secondary", fontSize: 18 }}>{t("sub_text")}</Typography>
						<Box gap={2}>
							<Button variant="contained">{t("why")}</Button>
							<Button sx={{ ml: 2 }} startIcon={<HeadsetMicIcon />} variant="text">
								{t("sales")}
							</Button>
						</Box>
					</Grid>
					<Grid item lg={7} md={12} sm={12} xs={12} sx={styles.bannerVideoBlock}>
						<Box sx={{position: "relative", zIndex: 15}}>
							<Box sx={{ position: "absolute", zIndex: -1, top: -36, left: -36 }}>
								<img style={{ margin: "0 auto" }} height={isDownMd ? "70" : "130"} src={assetdots} alt="logo" />
							</Box>
							<Box sx={styles.bannerLeftAssetBlock} />
							<Box sx={styles.bannerVideoContainer} onClick={() => setOpen(true)}>
								<IconButton sx={styles.bannerVideoButton}>
									<PlayCircleRoundedIcon  sx={{fontSize: isDownMd ? 70 : 100, color: "primary.main", borderRadius: "50%", backgroundColor: "background.paper"}} />
								</IconButton>
							</Box>
							<Box sx={styles.bannerRightAssetBlock} />
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default HomeBanner;
