import React from "react";

/* Layout Components */
import SignUpForm from "components/content/login/sign_up";

/* Utils */
import { useNavigate } from "react-router-dom";

/* Redux */
import { useRegisterMutation } from "@acromove/redux/auth/api"

const SignUpContainer = () => {
    const navigate = useNavigate();
    const [register] = useRegisterMutation()

    const onRegisterSubmit = async (values) => {
		const result = await register({ ...values});
        if(result.data){
			navigate("/login")
        }
	};

	return (
        <SignUpForm submit={onRegisterSubmit}/>
	);
};

export default SignUpContainer;
