import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import TemplateViewContainer from "containers/acrotransfer/manager/templates/view";

const AcroTransferTemplateViewPage = () => (
	<RoleSwitcher>
		<RoleSwitcher.Role value={["user", "user:view_only"]}>
			<TemplateViewContainer />
		</RoleSwitcher.Role>
	</RoleSwitcher>
);

export default AcroTransferTemplateViewPage;
