import React from "react";

/* MUI */
import { Typography, Box } from "@mui/material";

/* Utils */
import { useTranslation } from "react-i18next";
import styles from "./styles"

const HomeInfo = () => {
	const { t } = useTranslation("home_page", {keyPrefix: "info"});

	return (
		<Box sx={{ width: "100%", py: "80px", px: "80px", display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: "background.gray" }}>
			<Typography color="text.main" sx={styles.titles}>
				{t("title")}
			</Typography>
			<Typography color="text.grayDark" sx={[styles.regularText, {margin: "10px auto", textAlign: "center"}]}>
				{t("text")}
			</Typography>
		</Box>
	);
};

export default HomeInfo;
