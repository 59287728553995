import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "@acromove/utils/helpers";

const userNotificationsAPI = createApi({
	reducerPath: "notifications/api",
	baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("user/notifications")),
	keepUnusedDataFor: 30,
	tagTypes: ["NotificationFind"],
	endpoints: build => ({
		findNotifications: build.query({
			query: (data) => ({
				url: "find",
				params: data
			}),
			transformResponse: (response) => response.payload,
			providesTags: ["NotificationFind"]
		}),
		createNotification: build.mutation({
			query: (data) => ({
				url: "create",
				method: "POST",
				body: data
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["NotificationFind"]
		}),
		markNotificationAsRead: build.mutation({
			query: ({ id }) => ({
				url: `read/${id}`,
				method: "PATCH"
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["NotificationFind"]
		}),
		deleteNotification: build.mutation({
			query: ({ id }) => ({
				url: `remove/${id}`,
				method: "DELETE"
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["NotificationFind"]
		}),
		markMultiplyNotificationsAsRead: build.mutation({
			query: (data) => ({
				url: "read",
				method: "PATCH",
				body: data
			}),
			transformResponse: (response) => response.payload,
			invalidatesTags: ["NotificationFind"]

		})
	})
})



export const {
	useCreateNotificationMutation,
	useFindNotificationsQuery,
	useDeleteNotificationMutation,
	useLazyFindNotificationsQuery,
	useMarkMultiplyNotificationsAsReadMutation,
	useMarkNotificationAsReadMutation
} = userNotificationsAPI

export default userNotificationsAPI;