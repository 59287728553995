import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import ClientGroupsInfoContainer from "containers/acrotransfer/admin/clients/group-info";

const  ClientsGroupViewPage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={["admin","admin:owner", "acromove_admin", "acromove_admin:owner"]}>
            <ClientGroupsInfoContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default ClientsGroupViewPage
