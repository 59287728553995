import React, {useEffect} from "react";

/* MUI */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

/* Components */
import Wizard, { useStep } from "@acromove/components/wizard";
import Select from "@acromove/components/form/select";
import Input from "@acromove/components/form/input";
import Radio from "@acromove/components/form/radio";
import LeftContent from "components/content/acrotransfer/wizard-steps/shared/left-icon";

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import serverImg from "assets/harddrive.png";

const DataVolume = () => {
	const wizard = useStep();
	const { t } = useTranslation("acrotransfer_create_job", { keyPrefix: "steps.data_volume" });

	const defValues = wizard.state.stepsData?.data_volume || {
		data_size: 100,
		amount: "gb",
		type: "db",
	};

	const { control, handleSubmit, reset } = useForm({
		defaultValues: defValues,
	});

	const onSubmit = (data) => {
		wizard.actions.setStepData("data_volume", data);
	};

	useEffect(() => {
		reset(wizard.state.stepsData?.data_volume)
	}, [wizard.state.stepsData?.data_volume])

	const handleNext = () => {
		handleSubmit(onSubmit)();
		return true;
	};

	return (
		<Wizard.Step slug="data_volume" label={t("label")} pos={2}>
			<Grid container>
				<Grid item md={4}>
					<LeftContent image={serverImg} width="60%" />
				</Grid>
				<Grid item md={4}>
					<Wizard.Bar>
						<Box
							component="form"
							onSubmit={handleSubmit(onSubmit)}
							display="flex"
							flexDirection="column"
							sx={{
								gap: 4,
								width: "100%",
							}}
						>
							<Typography fontWeight={600} fontSize={20}>
								{t("how_much_data")}
							</Typography>
							<Grid container gap={1} sx={{ display: "flex", flexWrap: "nowrap", flexDirection: "row" }}>
								<Grid item md={3}>
									<Input
										sx={{ width: "100%" }}
										type="number"
										size="medium"
										control={control}
										name="data_size"
										label={t("form.data_size.label")}
									/>
								</Grid>
								<Grid item md={9}>
									<Select
										sx={{ width: "70%" }}
										control={control}
										name="amount"
										options={[
											{ label: "GB", value: "gb" },
											{ label: "TB", value: "tb" },
											{ label: "PB", value: "pb" },
										]}
										helperText={t("form.data_size.description")}
									/>
								</Grid>
							</Grid>
							<Typography fontWeight={600} fontSize={20}>
								{t("data_kind")}
							</Typography>
							<Radio
								sx={{ width: "100%", display: "flex", flexDirection: "column" }}
								sxRadioGroup={{ display: "flex", flexWrap: "wrap", width: "50%" }}
								control={control}
								name="type"
								options={[
									{ label: t("form.type.type_1"), value: "db" },
									{ label: t("form.type.type_2"), value: "vm_disk" },
									{ label: t("form.type.type_3"), value: "raw" },
									{ label: t("form.type.type_4"), value: "mix" },
								]}
							/>
						</Box>
						<Wizard.Tools beforeNext={handleNext} />
					</Wizard.Bar>
				</Grid>
			</Grid>
		</Wizard.Step>
	);
};

export default DataVolume;
