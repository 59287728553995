import constants from "general/constants";

const getInitials = (firstname="", lastname="") => {
    const firstName = firstname.slice(0, 1).toUpperCase();
    const lastName = lastname.slice(0, 1).toUpperCase();
    return `${firstName}${lastName}`
}

const getApiURL = (uri) => `${constants.API_BASE_URL}${uri}`

const sleep = (ms) => new Promise((resolve) => {
	setTimeout(() => resolve(true), ms)
}) 

const getUrlForSvgString = (svgString) => {
    const blob = new Blob([svgString], {type:"image/svg+xml;charset=utf-8"});
    return URL.createObjectURL(blob)
}

const sliceIndexes = (s) => {
	const bracketIndx = s.search(/\[\d/);
	if (bracketIndx === -1) {
		return s;
	}
	const formatted = s.slice(0, bracketIndx + 1) + s.slice(bracketIndx + 2);
	return sliceIndexes(formatted);
};


export default {
    getInitials,
    getApiURL,
    sleep,
    getUrlForSvgString,
    sliceIndexes
}