import React, { useEffect } from "react";

/* MUI */
import Grid from "@mui/material/Grid";

/* Components */
import Wizard, { useStep } from "@acromove/components/wizard";
import LeftContent from "components/content/acrotransfer/wizard-steps/shared/left-connection";
import ConnectionForm from "components/content/acrotransfer/wizard-steps/generic-forms/connection";

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const DestinationConnection = () => {
	const wizard = useStep();
	const { t } = useTranslation("acrotransfer_create_job", { keyPrefix: "steps.destination_connection" });

	const defValues = wizard.state.stepsData?.destination_connection || {
		connection: "existing",
		network: "dhcp",
		ports: "",
		connect_cables: [{ model: "", brand: "" }],
	};

	const { control, handleSubmit, watch, getValues, reset } = useForm({
		defaultValues: defValues,
	});

	const watchConnection = watch("connection");

	useEffect(() => {
		wizard.actions.setStepData("destination_connection", getValues());
	}, [watchConnection]);

	useEffect(() => {
		reset(wizard.state.stepsData?.destination_connection)
	}, [wizard.state.stepsData?.destination_connection])

	const onSubmit = (data) => {
		wizard.actions.setStepData("destination_connection", data);
		if (watchConnection === "usb_c") {
			wizard.actions.goTo("check_availability")
		} else {
			wizard.actions.goTo("destination_cabling")
		}
	};

	const handleNext = () => {
		handleSubmit(onSubmit)();
	};

	return (
		<Wizard.Step slug="destination_connection" label={t("label")} pos={10}>
			<Grid container>
				<Grid item md={4}>
					<LeftContent selected={watchConnection}/>
				</Grid>
				<Grid item md={4}>
					<Wizard.Bar>
						<ConnectionForm onSubmit={handleSubmit(onSubmit)} keyPrefix ="steps.destination_connection" control={control} watchConnection={watchConnection} />
						<Wizard.Tools beforeNext={handleNext} />
					</Wizard.Bar>
				</Grid>
			</Grid>
		</Wizard.Step>
	);
};

export default DestinationConnection;
