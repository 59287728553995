import SmartRouter from "@acromove/utils/smart-router";

import HomeRoutes from "router/routes/home";
import AuthUserRoutes from "router/routes/auth-user";

import AcroTransferRoutes from "router/routes/acrotransfer";

const router = new SmartRouter();

router.use(HomeRoutes)
router.use(AuthUserRoutes)
router.use(AcroTransferRoutes)


export default {
    navElements: router.getNavElements(),
    routerElements: router.getRouterElements()
}