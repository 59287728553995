import React from "react";

/* Layout Components */
import Container from "components/layouts/dashboard/ui/container";
import HomeHeader from "components/content/home/home-header";
import HomeBanner from "components/content/home/home-banner";
import HomeInfo from "components/content/home/home-info";
import HomeAcroTransfer from "components/content/home/home-acrotransfer";
import HomeUx from "components/content/home/home-ux";

const HomeContainer = () => (
	<Container>
		<HomeHeader />
		<HomeBanner />
        <HomeInfo />
        <HomeAcroTransfer />
        <HomeUx />
	</Container>
);

export default HomeContainer;
