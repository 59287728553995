import imgplc from "assets/video-placeholder.png";

const container = {
    width: "100vw",
    height: "100vh",
    flexDirection: "row",
    flexWrap: {
        ml: "nowrap",
        lg: "wrap",
        md: "wrap",
    },
    justifyContent: {
        lg: "center",
        md: "flex-start",
    },
    alignItems: {
        lg: "center",
        md: "flex-start",
    },
}

const bannerWrap = {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: {
        ml: "row",
        lg: "row",
        md: "row",
        sm: "column",
        xs: "column"
    },
}

const bannerTextBlock = {
    display: "flex",
    flexDirection: "column",
    flexWrap: {
        ml: "nowrap",
        lg: "nowrap",
        md: "wrap"
    },
    textAlign: {
        ml: "left",
        lg: "left",
        md: "left",
        sm: "center",
        xs: "center"
    }
}

const bannerMainText = {
    color: "sideMenu.text",
    fontSize: {
        ml: 68,
        lg: 68,
        md: 36,
        sm: 22,
        xs: 22
    }
}

const bannerVideoBlock = {
    display: "flex",
    justifyContent: "center",
    width: "100%"
}

const bannerLeftAssetBlock = {
    position: "absolute",
    backgroundColor: "background.darkGray",
    zIndex: "-1",
    width: {
        lg: "110px",
        md: "42px",
        sm: "42px",
        xs: "42px",
    },
    height: {
        lg: "434px",
        md: "165px",
        sm: "165px",
        xs: "165px",
    },
    top: "60px",
    left: {
        lg: "-68px",
        md: "-40px",
        sm: "-40px",
        xs: "-40px",
    }
}

const bannerRightAssetBlock = {
    position: "absolute",
    backgroundColor: "background.darkGray",
    zIndex: "-1",
    width: {
        lg: "110px",
        md: "42px",
        sm: "42px",
        xs: "42px",
    },
    height: {
        lg: "350px",
        md: "133px",
        sm: "133px",
        xs: "133px",
    },
    top: "110px",
    right: {
        ml: "-48px",
        lg: "-48px",
        md: "-30px",
        sm: "-30px",
        xs: "-30px"
    }
}

const bannerVideoContainer = {
    zIndex: 15,
    position: "relative",
    cursor: "pointer",
    backgroundImage: `url(${imgplc})`,
    backgroundRepeat: "no-repeat",
    borderRadius: "16px",
    objectFit: "contain",
    width: {
        ml: "750px",
        lg: "500px",
        md: "500px",
        sm: "500px",
        xs: "290px"
    },
    height: {
        ml: "536px",
        lg: "536px",
        md: "290px",
        sm: "290px",
        xs: "290px"
    }
}

const bannerVideoButton = {
    borderRadius: "50%",
    position: "absolute",
    top: "50%", 
    left: "50%", 
    transform: "translate(-50%, -50%)"
}

const titles = {
    fontSize: {
        ml: 48,
        lg: 48,
        md: 24,
        sm: 18,
        xs: 16,
    },
    fontWeight: 500,
    textAlign: "center",
    width: "60%",
    margin: "10px auto"
}

const subTitles = {
    fontSize: {
        ml: 40,
        lg: 40,
        md: 20,
        sm: 16,
        xs: 14,
    },
    fontWeight: 500,
}

const regularText = {
    fontSize: {
        ml: 24,
        lg: 24,
        md: 18,
        sm: 12,
        xs: 12
    }
}


export default { 
    container, 
    bannerWrap, 
    bannerTextBlock, 
    bannerVideoBlock, 
    bannerMainText,
    bannerLeftAssetBlock,
    bannerRightAssetBlock,
    bannerVideoContainer,
    bannerVideoButton,
    titles,
    regularText,
    subTitles 
}