import React, {useEffect, useState} from "react";

/* MUI */
import Grid from "@mui/material/Grid";

/* Components */
import Wizard, { useStep } from "@acromove/components/wizard";
import LeftContent from "components/content/acrotransfer/wizard-steps/shared/left-map";
import ShippingForm from "components/content/acrotransfer/wizard-steps/generic-forms/shipping";

/* Utils */
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const SourceShipping = () => {
	const wizard = useStep();
	const [location, setLocation] = useState([42, -71])
	const { t } = useTranslation("acrotransfer_create_job", { keyPrefix: "steps.source_shipping" });

	const defValues = wizard.state.stepsData?.source_shipping || {
		address: "",
		attention_to: "",
		phone: "",
		email: "",
	};

	const { control, handleSubmit, reset, watch } = useForm({
		defaultValues: defValues,
	});
	
	const watchAddress = watch("address")

	const onSubmit = (data) => {
		wizard.actions.setStepData("source_shipping", data);
	};

	useEffect(() => {
		if(!!watchAddress && watchAddress !== "") {
			setLocation([watchAddress?.lat, watchAddress?.lon])
		}
	},[watchAddress])

	useEffect(() => {
		reset(wizard.state.stepsData?.source_shipping)
	}, [wizard.state.stepsData?.source_shipping])

	const handleNext = () => {
		handleSubmit(onSubmit)();
		return true;
	};

	return (
		<Wizard.Step slug="source_shipping" label={t("label")} pos={4}>
			<Grid container>
				<Grid item md={4}>
					<LeftContent  location={location}/>
				</Grid>
				<Grid item md={4}>
					<Wizard.Bar>
						<ShippingForm onSubmit={handleSubmit(onSubmit)} keyPrefix ="steps.source_shipping" control={control}/>
						<Wizard.Tools beforeNext={handleNext} />
					</Wizard.Bar>
				</Grid>
			</Grid>
		</Wizard.Step>
	);
};

export default SourceShipping;
