import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import ClientGroupsCreateContainer from "containers/acrotransfer/admin/clients/create";

const  ClientsGroupCreatePage = () => (
    <RoleSwitcher>
        <RoleSwitcher.Role value={["admin","admin:owner", "acromove_admin", "acromove_admin:owner"]}>
            <ClientGroupsCreateContainer />
        </RoleSwitcher.Role>
    </RoleSwitcher>
)

export default ClientsGroupCreatePage
