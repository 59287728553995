import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        whitelist: ['en'],
        nonExplicitWhitelist: true,
        load: 'languageOnly',
        fallbackLng: 'en',
        // have a common namespace used around the full app
        ns: [
            'login_page',
            "router",
            "api_responses",
            "notifications",
            "user_profile",
            "common",
            "acrotransfer_create_job",
            "acrotransfer_jobs",
            "acrotransfer_health_check",
            "acrotransfer_admin",
            "acrotransfer_devices_info",
            "acrotransfer_job_overview",
            "clients_groups_info",
            "clients_groups",
            "clients_users_info"
        ],
        defaultNS: 'main',
        debug: true,
        interpolation: {},

        react: {
            wait: true,
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        }
    });

export default i18n;