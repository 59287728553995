import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import HealthCheckContainer from "containers/acrotransfer/manager/jobs/health-check";

const AcroTransferJobHealthCheckPage = () => (
	<RoleSwitcher>
		<RoleSwitcher.Role value={["user:view_only","user"]}>
			<HealthCheckContainer />
		</RoleSwitcher.Role>
	</RoleSwitcher>
);

export default AcroTransferJobHealthCheckPage;
