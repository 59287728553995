import React from "react";
import PropTypes from "prop-types";

/* MUI */
import Grid from "@mui/material/Grid";

/* Components */
import Input from "@acromove/components/form/input";
import Form from "@acromove/components/form";
import AsyncAutocomplete from "@acromove/components/form/autocomplete";
import PhoneInput from "@acromove/components/form/phone-input"

/* Utils */
import { useLazyFindLocationAddressQuery } from "redux/geolocation/geolocation.api";

/* Assets */

const ShippingInfo = (props) => {
	const { prefix } = props;

	return (
		<Grid container gap={2} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
			<Grid item md={8}>
				<Grid container gap={2}>
					<Grid item md={12}>
						<Form.Field name={`${prefix}.shipping_info.address`}>
							<AsyncAutocomplete
								useLazyLoad={useLazyFindLocationAddressQuery}
								sx={{ width: "100%" }}
								size="medium"
							/>
						</Form.Field>
					</Grid>
					<Grid item md={12}>
						<Form.Field name={`${prefix}.shipping_info.address_notes`}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Form.Field>
					</Grid>
					<Grid item md={12}>
						<Form.Field name={`${prefix}.shipping_info.attention_to`}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Form.Field>
					</Grid>
					<Grid item md={12}>
						<Grid container gap={2} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
							<Grid item md={6}>
								<Form.Field name={`${prefix}.shipping_info.phone_number`}>
									<PhoneInput size="medium" type="tel" sx={{ width: "100%" }} />
								</Form.Field>
							</Grid>
							<Grid item md={6}>
								<Form.Field name={`${prefix}.shipping_info.mobile_number`}>
									<PhoneInput size="medium" type="tel" sx={{ width: "100%" }} />
								</Form.Field>
							</Grid>
						</Grid>
					</Grid>
					<Grid item md={12}>
						<Form.Field name={`${prefix}.shipping_info.email`}>
							<Input size="medium" sx={{ width: "100%" }} />
						</Form.Field>
					</Grid>
				</Grid>
			</Grid>

		</Grid>
	);
};

ShippingInfo.propTypes = {
	prefix: PropTypes.string.isRequired,
};

export default ShippingInfo;
