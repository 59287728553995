import React from "react";
import PropTypes from "prop-types"

/* MUI */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/system";

/* Utils */
import sh from "@acromove/utils/styles-helpers";
import { useTranslation } from "react-i18next";

/* Assets */
import wasabiDirect from "assets/as-js-string/direct_connection";
import wasabiUsbC from "assets/as-js-string/usb_c";
import wasabiExistingInfrastructure from "assets/as-js-string/existing_infrastructure";

const LeftConnection = (props) => {
	const {selected} = props
	const { t } = useTranslation("acrotransfer_create_job");
	const theme = useTheme()

	return (
		<Box sx={{ flexDirection: "column", display: "flex", width: "calc(100% - 16px)", mr: 2 }}>
			<Typography fontWeight={600} fontSize={20}>
				{t("main_desc")}
			</Typography>
			<Typography fontWeight={500} fontSize={18} sx={{ my: 2 }} color="text.secondary">
				{t("main_desc_ext")}
			</Typography>
			<Grid container sx={[sh.flex("column", "center", "center")]}>
				<Grid
					item
					sx={{
						filter: selected === "existing" ? "unset" : "grayscale(1)",
						opacity: selected === "existing" ? "1" : ".5",
					}}
				>
					<Box sx={{ backgroundColor: "background.paper", padding: 2 }}>
						<img width="100%" src={wasabiExistingInfrastructure(theme.palette.primary.main)} alt="wasabi_existing_infrastructure" />
					</Box>
				</Grid>
				<Grid
					item
					sx={{
						filter: selected === "direct_network" ? "unset" : "grayscale(1)",
						opacity: selected === "direct_network" ? "1" : ".5",
					}}
				>
					<Box sx={{ backgroundColor: "background.paper", padding: 2 }}>
						<img width="100%" src={wasabiDirect(theme.palette.primary.main)} alt="wasabi_direct" />
					</Box>
				</Grid>
				<Grid
					item
					sx={{
						filter: selected === "usb_c" ? "unset" : "grayscale(1)",
						opacity: selected === "usb_c" ? "1" : ".5",
					}}
				>
					<Box sx={{ backgroundColor: "background.paper", padding: 2 }}>
						<img width="100%" src={wasabiUsbC(theme.palette.primary.main)} alt="wasabi_usbc" />
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

LeftConnection.propTypes = {
    selected: PropTypes.oneOf(["existing", "direct_network", "usb_c"]).isRequired
}

export default LeftConnection;
