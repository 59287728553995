import React from "react";
import PropTypes from "prop-types";

/* MUI */
import { Typography, Grid, Box } from "@mui/material";

/* Components */
import Input from "@acromove/components/form/input";
import AsyncAutocomplete from "@acromove/components/form/autocomplete"
import PhoneInput from "@acromove/components/form/phone-input"

/* Utils */
import { useTranslation } from "react-i18next";
import { useLazyFindLocationAddressQuery } from "redux/geolocation/geolocation.api";

export const ShippingForm = (props) => {

    const {onSubmit, keyPrefix, control} = props

    const { t } = useTranslation("acrotransfer_create_job", { keyPrefix });

	return (
		<Box
			component="form"
			onSubmit={onSubmit}
			display="flex"
			flexDirection="column"
			sx={{
				gap: 4,
				width: "100%",
			}}
		>
			<Typography fontWeight={600} fontSize={20}>
				{t("location")}
			</Typography>

			<AsyncAutocomplete
				useLazyLoad={useLazyFindLocationAddressQuery}
				sx={{ width: "100%" }}
				size="medium"
				control={control}
				name="address"
				label={t("form.address.label")}
				placeholder={t("form.address.placeholder")}
			/>
			<Input
				sx={{ width: "100%" }}
				size="medium"
				control={control}
				name="notes"
				label={t("form.address_notes.label")}
				placeholder={t("form.address_notes.placeholder")}
			/>
			<Typography fontWeight={600} fontSize={20}>
				{t("reciever")}
			</Typography>

			<Typography color="text.secondary" fontWeight={500} fontSize={18}>
				{t("desc_ext")}
			</Typography>
			<Input
				sx={{ width: "100%" }}
				size="medium"
				control={control}
				name="attention_to"
				label={t("form.attention_to.label")}
			/>
			<Grid container gap={2} sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
				<Grid item md={6}>
					<PhoneInput
						sx={{ width: "100%" }}
						size="medium"
						type="tel"
						control={control}
						name="phone"
						placeholder={t("form.phone.placeholder")}
						label={t("form.phone.label")}
					/>
				</Grid>
				<Grid item md={6}>
					<PhoneInput
						sx={{ width: "100%" }}
						size="medium"
						type="tel"
						control={control}
						name="mobile"
						placeholder={t("form.mobile.placeholder")}
						label={t("form.mobile.label")}
					/>
				</Grid>
			</Grid>
			<Input
				sx={{ width: "100%" }}
				control={control}
				size="medium"
				name="email"
				type="email"
				label={t("form.email.label")}
				placeholder={t("form.email.placeholder")}
			/>
		</Box>
	);
};

ShippingForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    keyPrefix: PropTypes.string.isRequired,
    control: PropTypes.shape({}).isRequired
}

export default ShippingForm