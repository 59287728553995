import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import JobsAdminContainer from "containers/acrotransfer/admin/jobs";
import JobsManagerContainer from "containers/acrotransfer/manager/jobs";

const AcroTransferJobsPage = () => (
	<RoleSwitcher>
		<RoleSwitcher.Role value={["acromove_admin:owner","acromove_admin","admin","admin:view_only","admin:owner"]}>
			<JobsAdminContainer />
		</RoleSwitcher.Role>
		<RoleSwitcher.Role value={["user:view_only","user"]}>
			<JobsManagerContainer />
		</RoleSwitcher.Role>
	</RoleSwitcher>
);

export default AcroTransferJobsPage;
