import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "@acromove/utils/helpers";

const statisticsApi = createApi({
    reducerPath: "acrotransfer/statistics",
    baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("statistics")),
    keepUnusedDataFor: 30,
    endpoints: build => ({
        findPartnerStatistics: build.query({
            query: () => ({
                url: "partner",
            }),
            transformResponse: (response) => response.payload,
        }),

        findOrganizationStatistics: build.query({
            query: () => ({
                url: "organization",
            }),
            transformResponse: (response) => response.payload,
        }),
    })
})


export const {
   useFindOrganizationStatisticsQuery,
   useLazyFindOrganizationStatisticsQuery,
   useFindPartnerStatisticsQuery,
   useLazyFindPartnerStatisticsQuery
} = statisticsApi

export default statisticsApi