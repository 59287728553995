import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import helpers from "@acromove/utils/helpers";

const geolocationAPI = createApi({
    reducerPath: "geolocation/api",
    baseQuery: fetchBaseQuery(helpers.getBaseQueryWithAuthorization("services/geocoding")),
    keepUnusedDataFor: 30,
    endpoints: build => ({
        findLocationAddress: build.query({
            query: ({search}) => ({
                url: "forward",
                params: {
                    address: search
                }
            }),
            transformResponse: (response) => ({
                items: response.payload.map(item => ({
                    ...item,
                    lat: item.latitude,
                    lon: item.longitude,
                    name: item.label,
                    id: item.uid
                }))
            }),
            providesTags: ["FindLocation"]
        }),
    }),
})

export const {
   useFindLocationAddressQuery,
   useLazyFindLocationAddressQuery,
} = geolocationAPI

export default geolocationAPI