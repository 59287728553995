/* Assets */
import virtualResIcon from "assets/icons/virtual_res.svg";
import physicalResIcon from "assets/icons/physical_res.svg";

const allData = [
    {
        id: 1,
        job_name: "Job Name_1",
        description: "Take a couple of DB stacks and move them to point B.",
        data_type: "RAW Media",
        data_size: "500tb",
        client: "Client Name",
        rental_from: "12/30/2022",
        rental_return: "01/01/2023",
        source: "Mesogeion 231, 15451, Athens, Greece",
        destination: "Mesogeion 231, 15451, Athens, Greece",
        status: "success",
        jobInfo: {
            job_name: "Job Name",
            total_data: "500 TB",
            type: "RAW Media Files",
            date: new Date('January 1, 2023 03:24:00'),
            description: "Take a couple of DB stacks and move them to point B.",
            devices: 5,
        },
        sourceShipping: {
            address: "Mesogeion 231, 15451, Athens, Greece",
            address_notes: "Building C, Second floor, Reception",
            attention_to: "Name Lastname",
            phone: "+30 21 0674 6758",
            mobile: "+30 21 0674 6758",
            email: "mail@acromove.com"
        },
        destinationShipping: {
            address: "Mesogeion 231, 15451, Athens, Greece",
            address_notes: "Building C, Second floor, Reception",
            attention_to: "Name Lastname",
            phone: "+30 21 0674 6758",
            mobile: "+30 21 0674 6758",
            email: "mail@acromove.com"
        },
        sourceTransfer: {
            fullname: "Name Lastname",
            phone: "+30 21 0674 6758",
            mobile: "+30 21 0674 6758",
            email: "mail@acromove.com"
        },
        destinationTransfer: {
            fullname: "Name Lastname",
            phone: "+30 21 0674 6758",
            mobile: "+30 21 0674 6758",
            email: "mail@acromove.com"
        },
        sourceEquipment: {
            connection: "usbc",
            connect_brand: "CISCO",
            connect_model: "Meraki 3000",
            transceivers_brand: "CISCO",
            transceivers_model: "Meraki 3000",
            fiber_brand: "CISCO",
            fiber_model: "Meraki 3000",
            cabling: "Optical",
        },
        destinationEquipment: {
            connection: "Existing infrastructure",
            connect_brand: "CISCO",
            connect_model: "Meraki 3000",
            transceivers_brand: "CISCO",
            transceivers_model: "Meraki 3000",
            fiber_brand: "CISCO",
            fiber_model: "Meraki 3000",
            cabling: "Optical",
        },
        sourceSecurity: {
            attention_to: "Name Lastname",
            phone: "+30 21 0674 6758",
            mobile: "+30 21 0674 6758",
            email: "mail@acromove.com",
            perimeter: "500 m (meters)",
            operator_pin: "500 m (meters)",
            time_frame: "500 m (meters)",
            polygon: "[38.05-49.58, 39.00-50.00, 45.00-51.00]"
        },
        destinationSecurity: {
            attention_to: "Name Lastname",
            phone: "+30 21 0674 6758",
            mobile: "+30 21 0674 6758",
            email: "mail@acromove.com",
            perimeter: "500 m (meters)",
            operator_pin: "500 m (meters)",
            time_frame: "500 m (meters)",
            polygon: "[38.05-49.58, 39.00-50.00, 45.00-51.00]"
        },
        sourceConnection: {
            "connection": "usbc",
            "ports": "1gb",
            "network": "dhcp",
            "connect_cables": [
                {
                    brand: "CISCO",
                    model: "Catalyst 9200",
                }
            ],
            "transceivers_cables": [
                {
                    brand: "FSR", 
                    model: "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module"
                }
            ],
            "fiber_cables": [
                {
                    brand: "FSR", 
                    model: "1m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable"
                } 
            ],
            "cabling": "optical",
        },
        destinationConnection: {
            "connection": "usbc",
            "ports": "1gb",
            "network": "dhcp",
            "connect_cables": [
                {
                    brand: "CISCO",
                    model: "Catalyst 9200",
                }
            ],
            "transceivers_cables": [
                {
                    brand: "FSR", 
                    model: "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module"
                }
            ],
            "fiber_cables": [
                {
                    brand: "FSR", 
                    model: "1m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable"
                } 
            ],
            "cabling": "optical",
        },
    },
];

const devices = [
    {
        id: 1,
        name: "Device_1",
        serial: "12345678912",
        state: "Operational",
        region: "Zone",
        availability: "Available",
        capacity: "100TB"
    }
]

const jobs = [
    {
        job_name: "Job Name_1",
        progress: {
            status: "Waiting for Device",
            value: 20
        },
        devices: 5,
        client: "Client Name",
        source: "Leoforos Mesogeion 231, 15361, Athens, Greece",
        destination: "Leoforos Mesogeion 231, 15361, Athens, Greece",
        id: 1,
        status: "success",
        rental_from: "12/30/2022",

    },
    {
        job_name: "Job Name_2",
        progress: {
            status: "Device Ready to be Dispatched",
            value: 40
        },
        devices: 5,
        client: "Client Name",
        source: "Leoforos Mesogeion 231, 15361, Athens, Greece",
        destination: "Leoforos Mesogeion 231, 15361, Athens, Greece",
        id: 2,
        status: "success",
        rental_from: "12/30/2022",
    },
    {
        job_name: "Job Name_3",
        progress: {
            status: "In transit to job",
            value: 60
        },
        devices: 5,
        client: "Client Name",
        source: "Leoforos Mesogeion 231, 15361, Athens, Greece",
        destination: "Leoforos Mesogeion 231, 15361, Athens, Greece",
        id: 3,
        status: "success",
        rental_from: "12/30/2022",
    },
    {
        job_name: "Job Name_4",
        progress: {
            status: "Returning from job",
            value: 80
        },
        devices: 5,
        client: "Client Name",
        source: "Leoforos Mesogeion 231, 15361, Athens, Greece",
        destination: "Leoforos Mesogeion 231, 15361, Athens, Greece",
        id: 4,
        status: "success",
        rental_from: "12/30/2022",
    },
]

const templates = [
    {
        id: 1,
        name: "Normandie footage",
        description: "Move 200TB of RAW media footage from scene in Normandie to HQ studio in LA to edit",
        data_type: "RAW Media",
        data_size: "200 TB",
        source: "17 Bd Eugène Cornuche, 14800 Deauville, France",
        destination: "112 Av. Kléber, 75016 Paris, France",
        data: {
            "template_select": {
                "type": "template",
                "template": 1
            },
            "what_kind_of_job": {
                "connection": "datatransfer",
                "description": "Move 200TB of RAW media footage from scene in Normandie to HQ studio in Paris to edit",
                "job_name": "Normandie footage",
                "job_description": "Move 200TB of RAW media footage from scene in Normandie to HQ studio in LA to edit"
            },
            "data_volume": {
                "data_size": 200,
                "amount": "tb",
                "type": 3
            },
            "time_frame": {
                "date": new Date('January 1, 2023 03:24:00')
            },
            "source_shipping": {
                "address": "17 Bd Eugène Cornuche, 14800 Deauville, France",
                "attention_to": "Dubois Pierre",
                "phone": "+33231898550",
                "notes": "Ask for Dubois Pierre, room 211, +33231898550",
                "mobile": "+33231898550",
                "email": "support@acromove.com"
            },
            "source_transfer": {
                "fullname": "Lawrenne Charlot",
                "phone": "+33231898550",
                "email": "support@acromove.com",
                "mobile": "+33231898550"
            },
            "source_connection": {
                "connection": "usbc",
                "ports": "1gb",
                "network": "dhcp",
                "connect_cables": [
                    {
                        brand: "CISCO",
                        model: "Catalyst 9200",
                    }
                ]
            },
            "source_cabling": {
                "transceivers_cables": [
                    {
                        brand: "FSR", 
                        model: "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module"
                    }
                ],
                "fiber_cables": [
                    {
                        brand: "FSR", 
                        model: "1m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable"
                    } 
                ],
                "cabling": "optical",
            },
            "destination_shipping": {
                "address": "17 Bd Eugène Cornuche, 14800 Deauville, France",
                "attention_to": "Dubois Pierre",
                "phone": "+33231898550",
                "notes": "Ask for Dubois Pierre, room 211, +33231898550",
                "mobile": "+33231898550",
                "email": "support@acromove.com"
            },
            "destination_transfer": {
                "fullname": "Lawrenne Charlot",
                "phone": "+33231898550",
                "email": "support@acromove.com",
                "mobile": "+33231898550"
            },
            "destination_connection": {
                "connection": "usbc",
                "ports": "1gb",
                "network": "dhcp",
                "connect_cables": [
                    {
                        brand: "CISCO",
                        model: "Catalyst 9200",
                    }
                ]
            },
            "destination_cabling": {
                "transceivers_cables": [
                    {
                        brand: "FSR", 
                        model: "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module"
                    }
                ],
                "fiber_cables": [
                    {
                        brand: "FSR", 
                        model: "1m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable"
                    } 
                ],
                "cabling": "optical",
            },
            "check_availability": {
                "number": "7"
            }
        }
    },
    {
        id: 2,
        name: "Santorini footage",
        description: "Video Editing station",
        data_type: "RAW Media",
        data_size: "100 TB",
        source: "Daydream Luxury Suites, Thira 847 00",
        destination: "112 Av. Kléber, 75016 Paris, France",
        data: {
            "template_select": {
                "type": "template",
                "template": 2
            },
            "what_kind_of_job": {
                "connection": "other",
                "description": "Video Editing station",
                "job_name": "Santorini footage",
                "job_description": "Edit Video footage on the field"
            },
            "data_volume": {
                "data_size": 100,
                "amount": "tb",
                "type": 3
            },
            "time_frame": {
                "date": new Date('January 1, 2023 03:24:00')
            },
            "source_shipping": {
                "address": "Daydream Luxury Suites, Thira 847 00",
                "attention_to": "Nick Papadopoulos",
                "phone": "6980953205",
                "notes": "Room 7, 6980953205",
                "mobile": "6980953205",
                "email": "support@acromove.com"
            },
            "source_transfer": {
                "fullname": "Nick Papadopoulos",
                "phone": "6980953205",
                "email": "support@acromove.com",
                "mobile": "6980953205"
            },
            "source_equipment": {
                "connection": "direct",
                "connect_brand": "",
                "connect_model": "",
                "transceivers_brand": "",
                "transceivers_model": "",
                "fiber_brand": "",
                "fiber_model": "",
                "cabling": "copper"
            },
            "destination_shipping": {
                "address": "112 Av. Kléber, 75016 Paris, France ",
                "attention_to": "Dubois Pierre",
                "phone": "+302106746758",
                "notes": "",
                "mobile": "+302106746758",
                "email": "support@acromove.com"
            },
            "destination_transfer": {
                "fullname": "Petros Papadopoulos",
                "phone": "+302106746758",
                "email": "support@acromove.com",
                "mobile": "+302106746758"
            },
            "destination_connection": {
                "connection": "usbc",
                "ports": "1gb",
                "network": "dhcp",
                "connect_cables": [
                    {
                        brand: "CISCO",
                        model: "Catalyst 9200",
                    }
                ]
            },
            "destination_cabling": {
                "transceivers_cables": [
                    {
                        brand: "FSR", 
                        model: "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module"
                    }
                ],
                "fiber_cables": [
                    {
                        brand: "FSR", 
                        model: "1m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable"
                    } 
                ],
                "cabling": "optical",
            },
            "source_connection": {
                "connection": "existing",
                "ports": "1gb",
                "network": "dhcp",
                "connect_cables": [
                    {
                        brand: "CISCO",
                        model: "Catalyst 9200",
                    }
                ]
            },
            "source_cabling": {
                "transceivers_cables": [
                    {
                        brand: "FSR", 
                        model: "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module"
                    }
                ],
                "fiber_cables": [
                    {
                        brand: "FSR", 
                        model: "1m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable"
                    } 
                ],
                "cabling": "optical",
            },
            "check_availability": {
                "number": "7"
            }
        }
    },
    {
        id: 3,
        name: "Move DB stack from Pallini HQ to local store in Athens",
        description: "Decentralize DB stack from Pallini HQ to local store in Athens",
        data_type: "RAW Media",
        data_size: "500 TB",
        source: "Pilou 1, Pallini 15351, Greece",
        destination: "Ag. Anargiron 28, 13561, Agioi Anargiroi-Kamatero, Greece",
        data: {
            "template_select": {
                "type": "template",
                "template": 3
            },
            "what_kind_of_job": {
                "connection": "datatransfer",
                "description": "",
                "job_name": "Move DB stack from Pallini HQ to local store in Athens",
                "job_description": "Decentralize DB stack from Pallini HQ to local store in Athens"
            },
            "data_volume": {
                "data_size": 500,
                "amount": "tb",
                "type": 1
            },
            "time_frame": {
                "date": new Date('January 1, 2023 03:24:00')
            },
            "source_shipping": {
                "address": "Pilou 1, Pallini 15351, Greece",
                "attention_to": "Nick Papadopoulos",
                "phone": "6980953205",
                "notes": "",
                "mobile": "6980953205",
                "email": "support@acromove.com"
            },
            "source_transfer": {
                "fullname": "John Papadopoulos",
                "phone": "6980953205",
                "email": "support@acromove.com",
                "mobile": "6980953205"
            },
            "source_equipment": {
                "connection": "existing",
                "connect_brand": "CISCO",
                "connect_model": "Catalyst 9200",
                "transceivers_brand": "FSR",
                "transceivers_model": "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module",
                "fiber_brand": "FSR",
                "fiber_model": "8m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable",
                "cabling": "optical"
            },
            "destination_shipping": {
                "address": "Ag. Anargiron 28, 13561, Agioi Anargiroi-Kamatero, Greece",
                "attention_to": "Alexander Papadopoulos",
                "phone": "6980953205",
                "notes": "",
                "mobile": "6980953205",
                "email": "support@acromove.com"
            },
            "destination_transfer": {
                "fullname": "Petros Papadopoulos",
                "phone": "6980953205",
                "email": "support@acromove.com",
                "mobile": "6980953205"
            },
            "destination_connection": {
                "connection": "usbc",
                "ports": "1gb",
                "network": "dhcp",
                "connect_cables": [
                    {
                        brand: "CISCO",
                        model: "Catalyst 9200",
                    }
                ]
            },
            "destination_cabling": {
                "transceivers_cables": [
                    {
                        brand: "FSR", 
                        model: "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module"
                    }
                ],
                "fiber_cables": [
                    {
                        brand: "FSR", 
                        model: "1m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable"
                    } 
                ],
                "cabling": "optical",
            },
            "source_connection": {
                "connection": "existing",
                "ports": "1gb",
                "network": "dhcp",
                "connect_cables": [
                    {
                        brand: "CISCO",
                        model: "Catalyst 9200",
                    }
                ]
            },
            "source_cabling": {
                "transceivers_cables": [
                    {
                        brand: "FSR", 
                        model: "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module"
                    }
                ],
                "fiber_cables": [
                    {
                        brand: "FSR", 
                        model: "1m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable"
                    } 
                ],
                "cabling": "optical",
            },
            "check_availability": {
                "number": "7"
            }
        }
    },
    {
        id: 4,
        name: "Migrate Acromove Wasabi cloud",
        description: "Migrate client to DC EU-West-2",
        data_type: "RAW Media",
        data_size: "100 TB",
        source: "231 Mesogeion Ave. 15451 Neo Psychiko, Athens, Greece",
        destination: "Ag. Anargiron 28, 13561, Agioi Anargiroi-Kamatero, Greece",
        data: {
            "template_select": {
                "type": "template",
                "template": 4
            },
            "what_kind_of_job": {
                "connection": "datatransfer",
                "description": "",
                "job_name": "Migrate Acromove Wasabi cloud",
                "job_description": "Migrate client to DC EU-West-2"
            },
            "data_volume": {
                "data_size": 100,
                "amount": "tb",
                "type": 1
            },
            "time_frame": {
                "date": new Date('January 1, 2023 03:24:00')
            },
            "source_shipping": {
                "address": "231 Mesogeion Ave. 15451 Neo Psychiko, Athens, Greece",
                "attention_to": "Evangelos Vasilogamvros",
                "phone": "+302106746758",
                "notes": "",
                "mobile": "+302106746758",
                "email": "vg@acromove.com"
            },
            "source_transfer": {
                "fullname": "Nikitas Makris",
                "phone": "+302106746758",
                "email": "nm@acromove.com",
                "mobile": "+302106746758"
            },
            "source_equipment": {
                "connection": "direct",
                "connect_brand": "",
                "connect_model": "",
                "transceivers_brand": "",
                "transceivers_model": "",
                "fiber_brand": "",
                "fiber_model": "",
                "cabling": "copper"
            },
            "destination_shipping": {
                "address": "Ag. Anargiron 28, 13561, Agioi Anargiroi-Kamatero, Greece",
                "attention_to": "Dubois Pierre",
                "phone": "+302106746758",
                "notes": "",
                "mobile": "+302106746758",
                "email": "support@acromove.com"
            },
            "destination_transfer": {
                "fullname": "Petros Papadopoulos",
                "phone": "+302106746758",
                "email": "support@acromove.com",
                "mobile": "+302106746758"
            },
            "source_connection": {
                "connection": "existing",
                "ports": "1gb",
                "network": "dhcp",
                "connect_cables": [
                    {
                        brand: "CISCO",
                        model: "Catalyst 9200",
                    }
                ]
            },
            "source_cabling": {
                "transceivers_cables": [
                    {
                        brand: "FSR", 
                        model: "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module"
                    }
                ],
                "fiber_cables": [
                    {
                        brand: "FSR", 
                        model: "1m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable"
                    } 
                ],
                "cabling": "optical",
            },
            "destination_connection": {
                "connection": "usbc",
                "ports": "1gb",
                "network": "dhcp",
                "connect_cables": [
                    {
                        brand: "CISCO",
                        model: "Catalyst 9200",
                    }
                ]
            },
            "destination_cabling": {
                "transceivers_cables": [
                    {
                        brand: "FSR", 
                        model: "Cisco SFP-10G-SR Compatible 10GBASE-SR SFP+ 850nm 300m DOM Duplex LC MMF Transceiver Module"
                    }
                ],
                "fiber_cables": [
                    {
                        brand: "FSR", 
                        model: "1m (3ft) LC UPC to LC UPC Duplex OM4 Multimode PVC (OFNR) 2.0mm Fiber Optic Patch Cable"
                    } 
                ],
                "cabling": "optical",
            },
            "check_availability": {
                "number": "7"
            }
        }
    },
]

const inventory = [
    {
        id: 1,
        sn: "12345678912",
        sku: "SP3-X41S-64-D-X",
        state: "Under Repair",
        location: "EU-West-2",
        site: "EU-West-2",
        last_check: "12/30/2022",
        status: "success",
    },
    {
        id: 2,
        sn: "12345678912",
        sku: "SP3-X41S-64-D-X",
        state: "Returning from Job",
        location: "EU-West-2",
        site: "EU-West-2",
        last_check: "12/30/2022",
        status: "warning",
    },
    {
        id: 3,
        sn: "12345678912",
        sku: "SP3-X41S-64-D-X",
        state: "Going to Job",
        location: "EU-West-2",
        site: "EU-Central-1",
        last_check: "12/30/2022",
        status: "success",
    },
    {
        id: 4,
        sn: "12345678912",
        sku: "SP3-X41S-64-D-X",
        state: "At Job",
        location: "EU-West-2",
        site: "EU-Central-2",
        last_check: "12/30/2022",
        status: "error",
    }
]

const sites = [
    {
        id: 1,
        location: "Location 1",
        site: "EU West 2",
        status: "success",
    },
    {
        id: 2,
        location: "Location 2",
        site: "EU West 2",
        status: "warning",
    },
    {
        id: 3,
        location: "Location 3",
        site: "EU Central 1",
        status: "success",
    },
    {
        id: 4,
        location: "Location 4",
        site: "EU Central 2",
        status: "error",
    }
]

const operators = [
    {
        id: 1,
        site: "EU-West-2",
        firstname: "First Name 1",
        lastname: "Last Name 1",
        phone: "+30 21 0674 6758",
        mobile: "+30 21 0674 6758",
        email: "support@acromove.com"
    },
    {
        id: 2,
        site: "EU-West-2",
        firstname: "First Name 2",
        lastname: "Last Name 2",
        phone: "+30 21 0674 6758",
        mobile: "+30 21 0674 6758",
        email: "support@acromove.com"
    },
    {
        id: 3,
        site: "EU-Cental-2",
        firstname: "First Name 3",
        lastname: "Last Name 3",
        phone: "+30 21 0674 6758",
        mobile: "+30 21 0674 6758",
        email: "support@acromove.com"
    },
]

const handlers = [
    {
        id: 1,
        firstname: "First Name 1",
        lastname: "Last Name 1",
        phone: "+30 21 0674 6758",
        mobile: "+30 21 0674 6758",
        email: "support@acromove.com"
    },
    {
        id: 2,
        firstname: "First Name 2",
        lastname: "Last Name 2",
        phone: "+30 21 0674 6758",
        mobile: "+30 21 0674 6758",
        email: "support@acromove.com"
    },
    {
        id: 3,
        firstname: "First Name 3",
        lastname: "Last Name 3",
        phone: "+30 21 0674 6758",
        mobile: "+30 21 0674 6758",
        email: "support@acromove.com"
    },
]

const messages = [
    {
        id: 1,
        time_date: "18/12/2020 10:24:17.241",
        message: "Ambient temperature within safe limits of operation",
        type: "Info",
        status: "info",
    },
    {
        id: 2,
        time_date: "18/12/2020 10:24:17.241",
        message: "Server experiencing shocks",
        type: "Warning",
        status: "warning",
    },
]


for (let index = 0; index < 1000; index += 1) {
    const lastEl = allData[allData.length - 1];
    allData.push({
        ...lastEl,
        id: lastEl.id + 1,
        job_name: `${lastEl.job_name.split("_")[0]}_${parseInt(lastEl.job_name.split("_")[1], 10) + 1}`,
    })
}

for (let index = 0; index < 1000; index += 1) {
    const lastEl = devices[devices.length - 1];
    devices.push({
        ...lastEl,
        id: lastEl.id + 1,
        name: `${lastEl.name.split("_")[0]}_${parseInt(lastEl.name.split("_")[1], 10) + 1}`,
    })
}

const mapGroups = [
    {
        slug: "virtual",
        label: "Virtual Resources",
        icon: virtualResIcon,
        points: [
            [40.41117080513673, 49.88534478991991],
            [40.42117080513673, 49.79534478991991],
        ],
    },
    {
        slug: "physical",
        label: "Physical Resources",
        icon: physicalResIcon,
        points: [
            [40.43117080513673, 49.87534478991991],
            [40.44117080513673, 49.81534478991991],
        ],
    },
];

export default {
    allData,
    mapGroups,
    devices,
    jobs,
    templates,
    inventory,
    sites,
    operators,
    handlers,
    messages
}