import React from "react";
import RoleSwitcher from "@acromove/authentication/components/role-switcher";

import JobsAdminViewContainer from "containers/acrotransfer/admin/jobs/view";
import JobsManagerViewContainer from "containers/acrotransfer/manager/jobs/view";

const AcroTransferJobsViewPage = () => (
	<RoleSwitcher>
		<RoleSwitcher.Role value={["acromove_admin:owner","acromove_admin","admin","admin:view_only","admin:owner"]}>
			<JobsAdminViewContainer />
		</RoleSwitcher.Role>
		<RoleSwitcher.Role value={["user:view_only","user"]}>
			<JobsManagerViewContainer />
		</RoleSwitcher.Role>
	</RoleSwitcher>
);

export default AcroTransferJobsViewPage;
