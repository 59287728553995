import Wizard from "./context";
import WizardBar from "./bar";
import WizardStep from "./step";
import WizardTools from "./tools";
import useWizard from "./use-wizard"
import useStep from "./use-step"

Wizard.WizardProvider.Bar = WizardBar;
Wizard.WizardProvider.Step = WizardStep;
Wizard.WizardProvider.Tools = WizardTools;

export { useWizard, useStep };

export default Wizard.WizardProvider;