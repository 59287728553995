import React from 'react'
import TableIconLink from "@acromove/components/table/cells/table-icon-link";
import TableStatusCell from "@acromove/components/table/cells/table-status-cell";
import ArrowRightIcon from "components/icons/arrow-right";

export const inventoryList = [
	{
		Header: "__empty",
		accessor: "status",
		maxWidth: "5px",
		width: "5px",
		Cell: (
			props //eslint-disable-line
		) => (
			<TableStatusCell status={props?.row?.original?.status} /> //eslint-disable-line
		),
	},
	{
		Header: "sn",
		accessor: "sn",
    },
	{
		Header: "sku",
		accessor: "sku",
	},
	{
		Header: "state",
		accessor: "state",
	},
	{
		Header: "location",
		accessor: "location",
	},
	{
		Header: "site",
		accessor: "site",
	},
	{
		Header: "last_check",
		accessor: "last_check",
	},
	{
		Header: "__empty",
		accessor: "id",
		Cell: (
			props // eslint-disable-line
		) => (
			<TableIconLink
				icon={<ArrowRightIcon color="primary" />}
				baseUrl="/jobs_history/view"
				value={`${props.value}-${props.row.original.sn}`} // eslint-disable-line
			/>
		),
	},
];

export default {inventoryList}