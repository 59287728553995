import React, { useState } from "react";
import PropTypes from "prop-types";

/* MUI */
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

/* Utils */
import { Link, useLocation } from "react-router-dom";
import {useTranslation} from "react-i18next";

const NestedList = (props) => {
    const {item} = props;
	const location = useLocation();
    const {t} = useTranslation("router");
	const [open, setOpen] = useState(location.pathname.startsWith(item.path));

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<>
			<ListItem
                to={item.path}
                sx={{pl: 0}} 
                >
                <Button
                    sx={{
                        color: location.pathname.startsWith(item.path) ? "text.active" : "sideMenu.text",
                        width: "100%",
                        textAlign: "left",
                        justifyContent: "flex-start"
                    }}
                    to={item.path} 
                    component={Link}
                    startIcon={item.icon ? <item.icon fontSize="small" /> : null}>
                    {t(item.path)}
                </Button>
                <IconButton onClick={handleClick}>
				    {open ? <ExpandLess sx={{color: "sideMenu.text"}}/> : <ExpandMore sx={{color: "sideMenu.text"}}/>}
                </IconButton>
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List sx={{pl: 2}} component="div" disablePadding>
                    {
                        item.sub.filter(sub => !sub?.ignoreInNav).map(sub => (
                            <ListItemButton
                                sx={{ pl: 2 }}
                                key={sub.path}
                                selected={location.pathname === sub.path}
                                component={Link}
                                to={sub.path}
                            >
                                {sub.icon && (
                                    <ListItemIcon sx={{color: location.pathname === sub.path ? "text.active" : "sideMenu.text"}}>
                                        <sub.icon fontSize="medium" />
                                    </ListItemIcon>
                                )}
                                <ListItemText sx={{
                                    fontSize: "medium",
                                    color: location.pathname === sub.path ? "text.active" : "sideMenu.text"
                                }} primary={t(sub.path)} />
                            </ListItemButton>
                        ))
                    }
				</List>
			</Collapse>
		</>
	);
};

NestedList.propTypes = {
    item: PropTypes.shape({
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        sub: PropTypes.arrayOf(PropTypes.shape({})),
        icon: PropTypes.node
    }).isRequired
}

export default NestedList;
