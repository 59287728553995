import React, {createContext, useState, useEffect} from "react"
import PropTypes from "prop-types";

export const dataContext = createContext({});

/**
 * Table Data Provider Component.
 *
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} Table Data Provider.
 */

const TableDataProvider = (props) => {
    const {useHook, children, hookProps, sx} = props;
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(5);
    const [search, setSearch] = useState("");
    const {data, isLoading, refetch} = useHook({offset, limit, search, ...hookProps})

    const handlePageChange = (_, page) => {
		setOffset(page * limit);
        console.log(page)
	};
    
    const handlePageSizeChange = ({ target: { value: size } }) => setLimit(size);

    const handleSearch = (value) => value && value !== "" ? setSearch(value) : null

    useEffect(() => {
		refetch();
        console.log(limit, offset)
	}, [offset, limit, search, hookProps]);

    return(
        <dataContext.Provider sx={sx} value={{
            data: data?.items || [],
            loading: isLoading,
            total: data?.total || 0,
            page: parseInt(offset / limit, 10) + 1,
            onPageSizeChange: handlePageSizeChange, 
            onPageChange: handlePageChange,
            onSearch: handleSearch
        }}>
            {children}
        </dataContext.Provider>
    );

}

/**
 * Table Data Provider Properties.
 *
 * @typedef {object} Props
 * @property {function} useHook - Hook passed to Provider.
 * @property {React.ReactNode} children - Children Elements.
 * @property {object} [hookProps = {}] - Additional hook props to be passed if needed (f/ex: item id or key defined in parent component).
 * @property {function | object | array} [sx = {}] - Additional CSS options.
 */

TableDataProvider.propTypes = {
    useHook: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    hookProps: PropTypes.shape({}),
    sx: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
		PropTypes.func,
		PropTypes.object,
	]),
}

TableDataProvider.defaultProps = {
    hookProps: {},
    sx: {},
}

export default TableDataProvider