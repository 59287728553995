import React, { forwardRef } from "react";
import "simplebar/dist/simplebar.min.css";
import SimpleBar from "simplebar-react";
import { styled } from "@mui/material/styles";

const ScrollbarRoot = styled(SimpleBar)``;

const Scrollbar = forwardRef((props, ref) => <ScrollbarRoot ref={ref} {...props} />);

export default Scrollbar;
