import React from "react";
import PropTypes from "prop-types";

/* MUI */
import { Typography, Box } from "@mui/material";

/* Components */
import Select from "@acromove/components/form/select";
import Radio from "@acromove/components/form/radio";
import CableBrandModelField from "components/content/acrotransfer/wizard-steps/shared/cable-brand-model-field";

/* Utils */
import { useTranslation } from "react-i18next";

const ConnectionForm = (props) => {
    const {onSubmit, keyPrefix, control, watchConnection} = props

    const { t } = useTranslation("acrotransfer_create_job", { keyPrefix });

    return ( 
        <Box
        component="form"
        onSubmit={onSubmit}
        display="flex"
        flexDirection="column"
        sx={{
            gap: 4,
            width: "100%",
        }}
    >
        <Typography fontWeight={600} fontSize={20}>
            {t("connect")}
        </Typography>

        <Select
            sx={{ width: "100%" }}
            control={control}
            label={t("form.connection.label")}
            name="connection"
            options={[
                { label: t("form.connection.types.existing"), value: "existing" },
                { label: t("form.connection.types.direct"), value: "direct_network" },
                { label: t("form.connection.types.usbc"), value: "usb_c" },
            ]}
        />

        {(watchConnection === "existing" || watchConnection === "direct_network") && (
            <>
                <Typography fontWeight={600} fontSize={20}>
                    {t("ports")}
                </Typography>
                <Select
                    sx={{ width: "100%" }}
                    control={control}
                    label={t("form.ports.label")}
                    helperText={t("form.ports.description")}
                    name="ports"
                    options={[
                        { label: t("form.ports.10GBSFP"), value: "10GBSFP" },
                        { label: t("form.ports.40GB"), value: "40GB" },
                        { label: t("form.ports.1GB"), value: "1GB" },
                        { label: t("form.ports.10GB"), value: "10GB" },
                        { label: t("form.ports.link"), value: "link" },
                    ]}
                />
            </>
        )}

        {watchConnection === "existing" && (
            <CableBrandModelField
                localePath="steps.source_connection.form"
                name="connect_cables"
                control={control}
            />
        )}

        {(watchConnection === "existing" || watchConnection === "direct_network") && (
            <Radio
                sxLabel={{
                    fontWeight: 600,
                    fontSize: 20,
                    mb: 4,
                }}
                label={t("settings")}
                sx={{ width: "100%", display: "flex", flexDirection: "column" }}
                sxRadioGroup={{ display: "flex", flexDirection: "column", mt: -4 }}
                control={control}
                name="network"
                helperText={t("form.network.description")}
                options={[
                    { label: t("form.network.dhcp"), value: "dhcp" },
                    { label: t("form.network.static"), value: "static_ip" },
                ]}
            />
        )}
    </Box>
    )
}

ConnectionForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    keyPrefix: PropTypes.string.isRequired,
    watchConnection: PropTypes.string.isRequired,
    control: PropTypes.shape({}).isRequired
}

export default ConnectionForm